import {useHalState} from '@synako/haligator';
import {PanierFound} from './PanierFound';
import {PanierNotFound} from './PanierNotFound';
import {usePanier} from 'ff-common';
import {Trans, useTranslation} from 'react-i18next';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {Text} from '@chakra-ui/react';

export const PanierView: React.FC = () => {  
  const queryParams = new URLSearchParams(location.search);
  const derniereAction = queryParams.get('derniereAction');
  const logique = queryParams.get('logique');
  const panierNav = usePanier();
  const {state: panier} = useHalState(panierNav);
  const {t} = useTranslation();
  const textToast = useSimpleTextToast();

  // A la validation d'un panier, la redirection vers PanierView empêche l'affichage du toast
  if (logique === 'avertissement') {
    textToast(<Trans t={t}
      components={{strong:<Text style={{fontWeight:700}}></Text>}}>{t('toast.panier_valide_safelive')}</Trans>, {
      id: 'avertissement',
      duration: 4000
    });
  }
  else if (logique === 'simulation') {
    textToast(<Trans t={t}
      components={{strong:<Text style={{fontWeight:700}}></Text>}}>{t('toast.panier_valide_simulation')}</Trans>, {
      id: 'simulation',
      duration: 4000
    });
  }

  if (panier) {
    return <PanierFound panier={panierNav} />;
  }
  return <PanierNotFound derniereAction={derniereAction} />;
};
