import {MouseEvent, useCallback} from 'react';
import {GridItem, HStack} from '@chakra-ui/react';
import {HaliRenderMany} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import {Dispatch} from 'react';
import {LigneDePanier, UniteQuantite} from 'api-types/ffconnect';
import {DateButton} from './DateButton';
import {selectedProps} from '../calendar-utils';

export const CalendarCellPanierArticle: React.FC<{
  date: Date,
  isSelected: boolean
  livraisonAssociee: State<LivraisonDesc>,
  handleDateClick: (livraison: State<LivraisonDesc>, event: MouseEvent<HTMLButtonElement>) => void
  itemPreco: State<ItemCatalogueDesc>,
  setSelectionUnite: (unite:UniteQuantite)=>void,
  setQuantite: Dispatch<React.SetStateAction<number>>
}> = ({
  date,
  isSelected,
  livraisonAssociee,
  handleDateClick,
  itemPreco,
  setSelectionUnite,
  setQuantite
}) => {
  let ligne: LigneDePanier | undefined;

  const applyQuantite = useCallback((ligne: LigneDePanier) => {
    setSelectionUnite(ligne.commande_en ?? 'unitaire');
    setQuantite(ligne.quantite);
  }, [setQuantite, setSelectionUnite]);

  return (
    <HaliRenderMany 
      many={livraisonAssociee.follow('panier_ouvert').followAll('lignes_de_panier')} 
      render={({data: lignes}) => {
        ligne = lignes.find(item => item.code_article === itemPreco.data.tolede.code_article);
        return (
          <GridItem as={HStack}
            alignItems='stretch'
            justify='center'>
            <DateButton
              date={date}
              {...selectedProps(isSelected)}
              panierEnCours={!!lignes}
              onClick={event => {
                handleDateClick(livraisonAssociee, event);
                if (ligne) applyQuantite(ligne);
              }}
            />
          </GridItem>
        );
      }} 
    />
  );
};
