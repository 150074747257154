export default {
  black: {
    pure:'#0000',
    ff:'#191B20',
    text:'#202125',
    transparent: '#00000020'
  },
  white: {
    100:'#FFFFFF',
  },
  yellow: {
    100: '#FCEC4D',
    500: '#C6B403',
    warning: '#FCF3E1',
    warning_text: '#C80'
  },
  red: {
    100: '#BF453C',
    200:'#D54A3B',
    fail:'#E4191B',
    fail_bg:'#E4191B0D',
    warning:'#E10909',
    warning_bg:'#FFE6E6'
  },
  blue: {
    50: '#3249C4',
    100: '#18235E', // default
    200: '#BEDEED',
    bg: '#E6F1F9',
    main_light: '#CCE3F1',
    main: '#0072BA',
    transparency_10: '#0026881A',
    transparency_20: '#00268833',
    main_transparency_5: '#0072BA0D',
    main_transparency_10: '#0072BA1A',
    main_transparency_30: '#0072BA4D',
    dark: '#005a94',
  },
  lblue: {
    50:'#E3F5FF',
    100: '#E6F1F9', 
    200: '#EDF1FB',
    300: '#DDEBF5',
    transparency_300_60: '#DDEBF599',
  },
  gray: {
    50: '#F8F8F8',
    75: '#F9F9FA',
    100: '#F3F3F3',
    200: '#ECECEC',
    400: '#DCDCDC',
    600: '#B1B1B1',
    800: '#6D6C6C',
    900: '#1B1919',
    bg: '#F7F7F7',
    light: '#EBECEE', // default
    dark: '#565D79',
  },
  green: {
    success: '#2AA948',
    success_bg: '#4CAC0014',
  },
  cyan: {
    surgele: '#6DDBDB'
  },
  transparent: {
    0: '#FFF0',
    10: '#FFF2',
    50: '#FFF8'
  },
  orange: {
    warning: '#CC8800',
    fail: '#ff9f00'
  },
  categories:{
    produits_laitiers: '#BEDEED',
    frais: '#F6C7BD',
    surgeles: '#252E88',
    epicerie: '#F6CAA1',
    boissons: '#9D8776',
    maree_fraiche: '#4D9AD9',
    fruits_et_legumes: '#82BA75',
    non_alimentaire: '#F5F1F5'
  }
};
