import {Image, Text, VStack} from '@chakra-ui/react';
import {TFunction} from 'i18next';

export const Left404: React.FC<{t: TFunction}> = ({t}) => (
  <VStack justify={{base:'center', md:'start'}}
    alignItems='center'
    m='9'
    position='relative'>
    <Image 
      src='/ffconnect/panier/panier_vide.webp' 
      alt={t('panier.vide.alt_carton_vide')} 
      w={{base:'320px', md:'360px', lg:'592px'}}
      maxW='100%'
    />
    <Text
      fontSize={{base:'20px', lg:'32px'}}
      fontWeight="700"
      position="absolute"
      top={{base:'30px', md:'50px', lg:'70px'}}
      left="50%"
      transform="translateX(-50%)"
      color="white"
      textAlign="center"
      w="100%"
    >
      {t('panier.vide.votre_panier_est_vide')}
    </Text>
  </VStack>
);
