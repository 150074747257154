import {TrashIcon} from '@/components/svg/TrashIcon';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import {AddIcon} from '@chakra-ui/icons';
import {HStack} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {useTranslation} from 'react-i18next';

export const ProductControlSideHeader: React.FC<{deleteText?: string; deleteAction?:()=>void}>
= ({deleteText = 'Retirer des produits', deleteAction}) => {
  const {t} = useTranslation();

  return <>
    {/* //MOBILE */}
    <HStack flexWrap='wrap'
      w='100%'
      gap='0'
      display={{base:'flex', md:'none'}}>
      <Button minW='min(fit-content, 50%)'
        flex='1'
        w='max-content'
        borderRadius='0'
        p='5'
        borderY='1px solid'
        borderX='0.5px solid'
        borderColor='gray.light'
        variant='ghost'
        leftIcon={<AddIcon />}
        onClick={() => NiceModal.show(CatalogueModale)}
      >
        {t('catalogue.plus_de_produit')}
      </Button>
      {deleteAction!==undefined && <Button minW='min(fit-content, 50%)'
        flex='1'
        w='max-content'
        borderRadius='0'
        p='5'
        borderY='1px solid'
        borderX='0.5px solid'
        borderColor='gray.light'
        variant='ghost'
        leftIcon={<TrashIcon />}
        onClick={deleteAction}>{deleteText}</Button>}
    </HStack>
    {/* //DESKTOP */}
    <HStack ml='auto'
      alignItems='baseline'
      display={{base:'none', md:'flex'}}>
      <Button maxW='100%'
        variant='ghost'
        leftIcon={<AddIcon />}
        onClick={() => NiceModal.show(CatalogueModale)}
      >
        {t('catalogue.plus_de_produit')}
      </Button>
      {deleteAction && <Button variant='ghost'
        leftIcon={<TrashIcon />}
        onClick={deleteAction}>{deleteText}</Button>}
    </HStack>
  </>;
};
