import {useLivraisons} from '@/haligator-factory';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {HaliRenderOne, useHalStates} from '@synako/haligator';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {MonCompte} from '../AccueilView';
import {routes} from 'ff-common';
import NiceModal from '@ebay/nice-modal-react';
import {ChangerDateModale} from '@/components/Modale/CalendarModale/ChangerDateModale';
import {Box, Button, Flex, HStack, Text, VStack} from '@chakra-ui/react';
import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon';
import {CartIcon} from '@/components/svg/CartIcon';
import {dateEnTexte} from 'ff-common';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {IconeTemperatureLivraison} from '@/components/IconeTemperatureLivraison';

interface ListePaniersEnCoursProps {
  afficheInfo: boolean;
  dansModaleCalendrier?: boolean;
  maxPaniersAffiches?: number;
}

export const ListePaniersEnCours: React.FC<ListePaniersEnCoursProps> = ({afficheInfo, dansModaleCalendrier, maxPaniersAffiches}) => {
  const {t} = useTranslation('common');
  const navigate = useNavigate();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  const livraisonsAvecPanier = livraisonsPossibles.states.filter(livraison => livraison.hasOneLink('panier_ouvert'));

  if (livraisonsAvecPanier.length === 0) {
    return afficheInfo ? <MonCompte/> : null;
  }

  // Utilisation de la props maxPaniersAffiches pour limiter le nombre d'affichages de paniers maximum
  const paniersAffiches = !maxPaniersAffiches ? livraisonsAvecPanier : livraisonsAvecPanier.slice(0, maxPaniersAffiches);

  const handleVoirPanier = (dateDeLivraison: string) => {
    navigate(buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison: dateDeLivraison}));
    NiceModal.remove(ChangerDateModale);
  };

  const handleVoirTousLesPaniers = () => {
    navigate(buildAssistanceRoute(routes.client.panier));
    NiceModal.remove(ChangerDateModale);
  };

  return (
    <Box bg="white"
      pt={dansModaleCalendrier ? {base:'20px', md:'0px'} : '30px'}
      pr={{base:dansModaleCalendrier ? '5px' :'19px', md:'34px'}}
      pl={{base:dansModaleCalendrier ? '0px' :'19px', md:'34px'}}
      borderRadius={dansModaleCalendrier ? 'none' : '8px'}
      boxShadow={dansModaleCalendrier ? 'none' : 'md'}
      width='100%'
      flex={0.5}
      height={dansModaleCalendrier ? {base: '250px', md:'470px'} : 'none'}
      overflowY={dansModaleCalendrier ? 'auto' : undefined}>
      <Flex alignItems='center'
        justifyContent='space-between'
        flexWrap="wrap"
        mb="20px"
        w='100%'>
        <Text fontSize={dansModaleCalendrier ? 'sm': 'xl'}
          fontWeight="700"
          display={{base: 'block', lg:dansModaleCalendrier ? 'block' : 'none', xl:'block'}}>
          {t('accueil.mes_paniers_en_cours', {nombre_paniers: livraisonsAvecPanier.length})}
        </Text>
        {!dansModaleCalendrier && <Text fontSize="xl"
          fontWeight="700"
          display={{base: 'none', lg:'block', xl:'none'}}>
          {t('accueil.paniers_en_cours', {nombre_paniers: livraisonsAvecPanier.length})}
        </Text>}
        {!dansModaleCalendrier && <Button onClick={handleVoirTousLesPaniers}
          color="gray.dark"
          fontWeight="600"
          variant="ghost"
          _hover={{backgroundColor: 'transparent'}}
          fontSize={{base: 'xs', md: 'inherit'}}
          p={{base: 0, md: 'inherit'}}
          height='100%'
          mb='0'
          display={{base: 'none', md: 'block'}}>
          <HStack spacing={2}
            alignItems="center">
            <Text>{t('accueil.voir_paniers')}</Text>
            <Box boxSize='20px'
              display="flex"
              alignItems="center"
              justifyContent="center">
              <RightSingleArrow />
            </Box>
          </HStack>
        </Button>}
      </Flex>
      <VStack spacing='8px'>
        {paniersAffiches.map((livraisonState) => {
          const panier = livraisonState.follow('panier_ouvert');
          const dateDeLivraison = livraisonState.data.tolede.date_de_livraison;
          return (
            <Box key={livraisonState.data.id}
              onClick={() => {handleVoirPanier(dateDeLivraison);}}
              style={{display: 'block', width: '100%'}}
              cursor='pointer'>
              <Box key={livraisonState.data.id}
                width="100%">
                <Flex borderWidth="1px"
                  borderRadius="8px"
                  pr="10px"
                  py={dansModaleCalendrier ? '10px' : '2px'}
                  alignItems="center"
                  flexWrap={dansModaleCalendrier ? 'wrap' : 'nowrap'}>
                  <Flex alignItems="center"
                    w={dansModaleCalendrier ? '100%' : 'none'}>
                    {!dansModaleCalendrier && <Box bg='blue.main_transparency_10'
                      borderRadius="8px"
                      p="6px"
                      ml="10px"
                      mt="10px"
                      mb="10px"
                      mr="5px">
                      <CartIcon color='blue.main'
                        width='25px'
                        height='25px'/>
                    </Box>}
                    <HStack justifyContent='space-between'
                      w='100%'>
                      <Text color="black"
                        bg="white"
                        pl={dansModaleCalendrier ? '10px' : '5px'}
                        justifyContent='start'
                        _hover={{bg:'white'}}
                        fontSize={{base:'sm', md:dansModaleCalendrier ? 'sm': 'sm'}}
                        minW='130px'>
                        {dansModaleCalendrier && t('accueil.pour_livraison')}{dateEnTexte(dateDeLivraison)}
                      </Text>
                      {dansModaleCalendrier && <Button bg='transparent'
                        border='1px solid'
                        borderColor='blue.main'
                        color='blue.main'
                        height='35px'
                        width='70px'
                        fontSize='sm'>{t('global.voir')}</Button>}
                    </HStack>

                  </Flex>
                  <HaliRenderOne
                    nav={panier}
                    render={({data}) => (
                      <HStack spacing={2}
                        ml={dansModaleCalendrier ? '10px' : 'auto'} >
                        <HaliRenderOne nav={livraisonState.follow('tournee')} 
                          skeleton={null}
                          render={({data:tournee})=>{
                            return <IconeTemperatureLivraison 
                              temperature_tournee={tournee.tolede.temperature_de_livraison}
                              boxSize={8}
                            />;
                          }}/> 
                        <Box p='1.5'
                          px='1.5'
                          borderRadius='8px'
                          backgroundColor='gray.light'
                          color='gray.dark'
                          fontSize='sm'>
                          {t('global.produit', {count: data.nombre_de_lignes})}
                        </Box>
                        {dansModaleCalendrier &&
                        <>
                          <Box p='1.5'
                            px='1.5'
                            borderRadius='8px'
                            backgroundColor='yellow.warning'
                            color='yellow.warning_text'
                            fontSize='sm'>
                            {t('global.a_valider')}
                          </Box>
                          <Text fontSize='sm'>{t('panier.recap.total_ht')} : <Text as='span'
                            fontWeight='700'>{data.total_estime_ht} €</Text></Text>
                        </>
                        }
                      </HStack>
                    )}
                  />
                </Flex>
              </Box>
            </Box>
          );
        })}
        {!dansModaleCalendrier && <Button as={Link}
          to={buildAssistanceRoute(routes.client.panier)}
          color="gray.dark"
          fontWeight="600"
          variant="ghost"
          _hover={{backgroundColor: 'transparent'}}
          fontSize={{base: 'xs', md: 'inherit'}}
          p={{base: 0, md: 'inherit'}}
          height='100%'
          width='100%'
          mt='20px'
          mb='10px'>
          <Text textAlign='center'
            display={{base: 'block', md: 'none'}}
            textDecoration='underline'>{t('accueil.afficher_paniers')}<ChevronDownIcon /></Text>
        </Button>}
      </VStack>
    </Box>
  );
};
