import React, {useState} from 'react';
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton, Text, VStack} from '@chakra-ui/react';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useTranslation} from 'react-i18next';
import {useMe} from '@/haligator-factory';
import {useCheckSessionWarning} from 'ff-common';

export const SessionWarning: React.FC = () => {
  const [showWarning, setShowWarning] = useState(false);

  const textToast = useSimpleTextToast();
  const {t} = useTranslation();
  const meNav = useMe();

  // logout en fin de session
  const redirectToLogout = () => {
    window.location.href = '/authn/logout';
  };

  const {countdown, isAvailable, isSubmitting, submitExtendSession} = useCheckSessionWarning(meNav, redirectToLogout, showWarning, setShowWarning, textToast);

  if (!isAvailable || !showWarning) return null;

  return (
    <Box position="fixed"
      right="4"
      zIndex="toast"
      maxW="sm"
      width="full"
      bottom={4}>
      <Alert status="warning"
        variant="solid"
        borderRadius="md"
        boxShadow="lg">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>{t('session.session_bientot_expiree')}</AlertTitle>
          <AlertDescription display="block">
            <VStack align="stretch"
              spacing={3}
              mt={2}>
              <Text>
                {t('session.delai_expiration', {minutes: countdown})}
              </Text>
              <Button
                colorScheme='orange'
                size="sm"
                onClick={submitExtendSession}
                isLoading={isSubmitting}
                loadingText={t('session.prolongation')}
              >
                {t('session.prolonger_session')}
              </Button>
            </VStack>
          </AlertDescription>
        </Box>
        <CloseButton
          position="absolute"
          right="2"
          top="2"
          onClick={() => {setShowWarning(false);}}
        />
      </Alert>
    </Box>
  );
};
