import {useLivraisons} from '@/haligator-factory';
import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import {NiceModalHandler} from '@ebay/nice-modal-react';
import {useHalCollection} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc, LigneDePanierDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import {useCallback, useEffect, useRef, useState} from 'react';
import {VStack} from '@chakra-ui/react';
import {CalendarPreco} from '../Calendar/CalendarPreco';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';

interface PrecommandeProps {
  // validateAction: (_v: Resource<LivraisonDesc>, quantite_precommande?:number, selectionColis?:boolean) => void,
  livraisonSelectionnee: State<LivraisonDesc>
  dateDebut?: string,
  itemPreco: State<ItemCatalogueDesc>,
  commandeParColis: boolean,
  selectionQuantitePossible: boolean,
  remplacementAction?: (selectedLivraison: State<LivraisonDesc>) => Promise<void>
}

export const PrecommandeContent: React.FC<PrecommandeProps & {
  modal:NiceModalHandler
}> 
= ({livraisonSelectionnee, commandeParColis, dateDebut, itemPreco, modal, selectionQuantitePossible, remplacementAction})=>{
  const ligneDePanierRef = useRef<State<LigneDePanierDesc> | undefined>();

  const {items:livraisonStates} = useHalCollection(useLivraisons());

  const [selectedLivraison, setSelectedLivraison] = useState<State<LivraisonDesc>>(livraisonSelectionnee);
  const textToast = useSimpleTextToast();
  const {submit: ajouterAuPanierAction} =  useAjouterAuPanier(selectedLivraison.data.id, textToast);

  useEffect(() => {
    const selectLigne = async () => {
      ligneDePanierRef.current = undefined;
      const state = await selectedLivraison.get();
      if (state.hasOneLink('panier_ouvert')) {
        const lignes = await state.follow('panier_ouvert').followAll('lignes_de_panier').getAll();
        ligneDePanierRef.current = lignes.find(ligne => ligne.data.code_article === itemPreco.data.tolede.code_article);
      }
    };

    selectLigne();
  }, [selectedLivraison, itemPreco.data.tolede.code_article]);

  const onClose = useCallback(() => {
    setSelectedLivraison(livraisonSelectionnee);
    modal.remove();
  }, [livraisonSelectionnee, modal]);

  const handleValidate = useCallback(async (quantite_precommande: number, selectionColis: boolean) => {
    if (remplacementAction) {
      remplacementAction(selectedLivraison);
    } else if (ligneDePanierRef.current) {
      const noulleQuantite = quantite_precommande - ligneDePanierRef.current.data.quantite;
      if (noulleQuantite) {
        ligneDePanierRef.current.submitCommand('c:changer-la-quantite', {
          quantite: noulleQuantite,
        });
      }
    } else {
      ajouterAuPanierAction({
        code_article: itemPreco.data.tolede.code_article,
        quantite: quantite_precommande,
        commande_en: selectionColis ? 'colis' : 'unitaire'
  
      });
    }
    onClose();
  }, [
    onClose, 
    itemPreco, 
    ajouterAuPanierAction, 
    remplacementAction, 
    selectedLivraison
  ]);

  const handleCancel = useCallback(() => {
    setSelectedLivraison(livraisonSelectionnee);
    onClose();
  }, [onClose, livraisonSelectionnee]);

  const handleDateSelectWithoutClosing = useCallback(async (livraison: State<LivraisonDesc>) => {
    setSelectedLivraison(livraison);
  }, []);

  return <>
    <VStack alignItems='stretch'
      p={{base: '1', md: 'initial'}}>
      <CalendarPreco dateDebut={dateDebut}
        isOpen={modal.visible} 
        itemPreco={itemPreco}
        commandeParColis={commandeParColis}
        onReturn={handleCancel}
        onDateSelect={handleDateSelectWithoutClosing} 
        onDeliveryDateSelect={handleValidate}
        selectedLivraison={selectedLivraison} 
        livraisonStates={livraisonStates} 
        selectionQuantitePossible={selectionQuantitePossible}
      />
    </VStack>
  </>;
};
