import {ChangerDateModale} from '@/components/Modale/CalendarModale/ChangerDateModale';
import {Button, Text, VStack} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {TFunction} from 'i18next';

export const RightJeViensDeValider: React.FC<{t: TFunction}> = ({t}) => {
  return(
    <VStack borderRadius='10px'
      alignItems='start'
      justify='center'
      p='6'
      gap='3'>
      <Text as={'h2'}
        fontSize='3xl'
        fontWeight='700'>{t('panier.vide.prenez_de_lavance')}</Text>
      <Text mb='8'>{t('panier.vide.preparez_prochaine_livraison')}</Text>
      <Button
        colorScheme='blue'
        variant='outline'
        _hover={{backgroundColor:'gray.light'}}
        w={{base:'100%', md:'initial'}}
        onClick={()=>{
          NiceModal.show(ChangerDateModale, {
            derniereAction: 'valide'
          });
        }}>
        {t('global.choisir_livraison')}
      </Button>
    </VStack>
  );
};
