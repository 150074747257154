import {Box, BoxProps, Button, Input, InputGroup, InputLeftElement} from '@chakra-ui/react';
import {SearchIcon} from '../../svg/SearchIcon';
import {SEARCH_MIN_CHARS, useSearchClient} from 'ff-common';
import React, {useCallback, useContext} from 'react';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import NiceModal from '@ebay/nice-modal-react';
import {SearchModal} from './SearchModal';

export const Searchbar: React.FC<{props?: BoxProps}> = ({props}) => {
  const search = useSearchClient();

  const handleOpen = useCallback(() => {
    NiceModal.show(SearchModal);
  }, []);

  const breakpoint = useContext(BreakPointContext);

  return (
    <Box position="relative"
      width={{base:'100%', md:'30px', lg:'100%'}}
      {...props}
      onClick={handleOpen}>
      {breakpoint?.breakpoint!=='md' ? (
        <InputGroup zIndex={1003}
          bg='white'
          borderTopRadius='8px'
          opacity={search.indexIsBuilt ? 1 : 0.2}>
          <InputLeftElement pointerEvents="auto"
            cursor="pointer">
            <SearchIcon />
          </InputLeftElement>
          <Input
            placeholder={search.indexIsBuilt ? `Chercher un produit : ${SEARCH_MIN_CHARS} lettres minimum` : 'Nous chargeons votre catalogue ...'}
            isDisabled={!search.indexIsBuilt}
            tabIndex={-1}
            readOnly
            cursor='pointer'
          />
        </InputGroup>
      ) : (
        <Button variant='ghost'>
          <SearchIcon />
        </Button>
      )}
    </Box>
  );
};
