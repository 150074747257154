import {sxPrint} from '@/utils/chakraProps';
import {isSafari} from '@/utils/detectNavigator';
import {InfoIcon} from '@chakra-ui/icons';
import {
  Box,
  HStack,
  LinkBox,
  Skeleton,
  SystemProps,
  Table,
  TableCellProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  omitThemingProps,
} from '@chakra-ui/react';
import {
  CellContext,
  HeaderGroup,
  Row,
  RowData,
  TableOptions,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';

export interface DataTableProps<TResourceData>
  extends TableProps,
  Omit<TableOptions<TResourceData>, 'getCoreRowModel'> {
  isLoading?: boolean;
  getCoreRowModel?: TableOptions<TResourceData>['getCoreRowModel'];
  footer?: React.ReactElement;
  empty?: React.ReactNode;
}

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    // skeleton?: (column: ColumnDef<TData, TValue>) => React.ReactElement;
    tdProps?: TableCellProps;
    isNumeric?: boolean;
    getColSpan?: (context: CellContext<TData, TValue>) => number | undefined;
  }
}

const trStyles: SystemProps = {
  display: {base: 'block', lg: 'table-row'},
  borderWidth: {base: '1px', lg: 0},
  borderRadius: 'md',
  my: {base: 'md', lg: 0},
  _first: {
    mt: 0,
  },
  _last: {
    mb: 0,
  },
};

const trStylePrint: SystemProps = {
  display: 'table-row',
};

const tdStyles: (heading?: string, isNumeric?: boolean) => SystemProps = (
  heading,
  isNumeric
) => ({
  textAlign: isNumeric ? 'right' : 'left',
  display: {base: 'flex', lg: 'table-cell'},
  flexDirection: {base: 'column', lg: 'row'},
  alignItems: {base: 'stretch', lg: 'center'},
  gap: {base: '2', lg: '0'},
  width: {base: '100%', lg: 'auto'},
  px: {base: '4', lg: 'xs'},
  py: {base: '4', lg: 'sm'},
  pl: {lg: '0'},
  borderStyle: {
    base: 'none',
    lg: 'solid',
  },
  fontSize: {
    base: 'sm',
    lg: 'xs',
  },
  position: 'relative',
  _before: {
    content: heading ? `"${heading}"` : 'none',
    display: {base: 'block', lg: 'none'},
    fontWeight: 'bold',
    color: 'secondaryGreen.500',
    width: '100%',
    textAlign: 'left',
    marginBottom: '2',
  },
  // separateur entre les lignes en mobile
  _after: {
    content: '""',
    display: {base: 'block', lg: 'none'},
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    height: '1px',
    backgroundColor: 'gray.200',
  },
  // suppression du dernier separateur
  ':last-of-type': {
    _after: {
      display: 'none',
    },
  },
  _odd: {
    bg: {base: 'ivory.100.7', lg: 'transparent'},
  },
});

const tdStylePrint = (isNumeric?: boolean): SystemProps => ({
  display: 'table-cell',
  textAlign: isNumeric ? 'right' : 'left',
  px: 'md',
  py: 'xs',
  _before: {
    content: 'none',
  },
  _odd: {
    bg: 'transparent',
  },
});

interface DataTableHeaderProps<TResourceData>
  extends Omit<TableHeadProps, 'children'> {
  headerGroups: HeaderGroup<TResourceData>[];
}

interface DataTableRowProps<TResourceData>
  extends Omit<TableRowProps, 'children'> {
  row: Row<TResourceData>;
  enableSelection?: boolean;
}

export const HeaderWithTooltip : React.FC<{text: string, tooltip: string}> =
  ({text, tooltip}) =>  (<Tooltip label={tooltip}>
    <HStack spacing={1}
      as="span"
      cursor="help">
      <Text>{text}</Text>
      <InfoIcon boxSize="12px" />
    </HStack>
  </Tooltip>);

function DataTableHeader<TResourceData>({
  headerGroups,
}: DataTableHeaderProps<TResourceData>) {
  // TODO: support multiple headerGroups ?
  const headerGroup = headerGroups[headerGroups.length - 1];

  return (
    <Thead
      display={{base: 'none', lg: 'table-header-group'}}
      sx={sxPrint({display: 'table-header-group'})}
    >
      <Tr key={headerGroup.id}>
        {headerGroup.headers.map(header => (
          <Th
            key={header.id}
            isNumeric={header.column.columnDef.meta?.isNumeric}
            colSpan={header.colSpan}
            p="xs"
            pl={0}
          >
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
}

function DataTableRow<TResourceData>({
  row,
  enableSelection,
  ...otherProps
}: DataTableRowProps<TResourceData>) {
  let colSpanIndex: number | undefined;

  return (
    <LinkBox
      as={Tr}
      className={enableSelection ? 'selectable' : undefined}
      {...trStyles}
      sx={{
        ...sxPrint(trStylePrint),
        marginBottom: {base: '4', lg: '0'},
        borderWidth: {base: '1px', lg: '0'},
        borderRadius: {base: 'md', lg: '0'},
        overflow: 'hidden',
        boxShadow: {base: 'sm', lg: 'none'},
      }}
      {...otherProps}
    >
      {row.getVisibleCells().map((cell, index) => {
        if (undefined !== colSpanIndex && index < colSpanIndex) {
          return null;
        }

        const colSpan = cell.column.columnDef.meta?.getColSpan?.(
          cell.getContext()
        );

        colSpanIndex = colSpan ? index + colSpan : undefined;

        return (
          <Td
            key={cell.id}
            {...tdStyles(
              'string' === typeof cell.column.columnDef.header
                ? cell.column.columnDef.header
                : undefined,
              cell.column.columnDef.meta?.isNumeric
            )}
            sx={{
              ...sxPrint(tdStylePrint(cell.column.columnDef.meta?.isNumeric)),
            }}
            {...cell.column.columnDef.meta?.tdProps}
            colSpan={colSpan}
            pos={isSafari ? 'relative' : undefined}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </LinkBox>
  );
}

export default function DataTable<TResourceData>({
  columns,
  isLoading,
  footer,
  data,
  empty,
  ...props
}: DataTableProps<TResourceData>) {
  const options = omitThemingProps(props);

  const table = useReactTable<TResourceData>({
    columns,
    data,
    ...options,
    getCoreRowModel: options.getCoreRowModel ?? getCoreRowModel(),
  });

  if (isLoading) {
    return (
      <Table {...props}>
        <DataTableHeader headerGroups={table.getHeaderGroups()} />
        <Tbody>
          {Array(5).fill(null).map((_, rowIndex) => (
            <Tr key={`skeleton_row_${rowIndex}`}
              {...trStyles}>
              {columns.map((column, colIndex) => (
                <Td
                  key={`skeleton_cell_${rowIndex}_${colIndex}`}
                  {...tdStyles()}
                  {...column.meta?.tdProps}
                >
                  <Box p="10px">
                    <Skeleton height="20px"
                      width='100%' />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
        {footer}
      </Table>
    );
  }

  if (data.length === 0 && empty) {
    return <>{empty}</>;
  }

  return (
    <Table {...props}>
      <DataTableHeader headerGroups={table.getHeaderGroups()} />

      <Tbody>
        {table.getRowModel().rows.map(row => (
          <DataTableRow
            key={row.id}
            row={row}
            enableSelection={!!options.enableRowSelection}
          />
        ))}
      </Tbody>

      {footer}
    </Table>
  );
}
