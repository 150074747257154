import {ChevronDownIcon} from '@chakra-ui/icons';
import {Box, Button, Text, Tooltip} from '@chakra-ui/react';
import {ShippingCartIcon} from '../../svg/ShippingCartIcon';
import NiceModal from '@ebay/nice-modal-react';
import {jourMoisEnTexte} from 'ff-common';
import {useLivraisonsPossiblesContext} from 'ff-common';
import {ChangerDateModale} from '@/components/Modale/CalendarModale/ChangerDateModale';
import {Trans, useTranslation} from 'react-i18next';
import {HaliRenderOne, useHalStates} from '@synako/haligator';
import {useLivraisons} from '@/haligator-factory';
import {IconeTemperatureLivraison} from '@/components/IconeTemperatureLivraison';

export const MenuLivraison:React.FC = () => {  
  const {date_de_livraison, livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const {t} = useTranslation('common');

  const onOpen = () => {
    NiceModal.show(ChangerDateModale);
  };

  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }
  
  return (
    <Tooltip label={t('menu_livraison.tooltip')}>
      <Button
        onClick={onOpen}
        flex={{base:'1'}}
        variant="ghost"
        _hover={{bg: 'transparent'}}
        background='blue.main_transparency_10'
        borderRight="1px solid rgba(0, 114, 186, 0.30)"
        borderRadius={{base:'0px',md:'50px 0px 0px 50px'}}
        height='100%'
        leftIcon={
          <Box position='relative'>
            {/* Frozen icon */}
            <HaliRenderOne skeleton={()=><ShippingCartIcon />}
              nav={livraisonSelectionnee.follow('tournee')}
              render={({state:tournee}) => {
                return <IconeTemperatureLivraison
                  temperature_tournee={tournee.data.tolede.temperature_de_livraison}
                  fallback={<ShippingCartIcon />}/>;
              }} />
          </Box>
        }
        rightIcon={<ChevronDownIcon color="blue.main" />}
        w='265px'
      >
        <Text textColor='blue.main'
          fontSize={{base:'xs', md:'sm'}}>
          <Trans t={t}>
            {t('menu_livraison.livraison', {date: jourMoisEnTexte(date_de_livraison)})}
          </Trans>
        </Text>
      </Button>
    </Tooltip>
  );
};
