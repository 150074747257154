import {useMe} from '@/haligator-factory';
import {Box, Flex, HStack, Image, Text, VStack} from '@chakra-ui/react';
import {HaliRenderOne} from '@synako/haligator';
import {useTranslation} from 'react-i18next';
import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon';
import {SwitchContact} from 'api-types/ffconnect';
import {PartieGaucheConnexion} from './PartieGaucheConnexion';
import {LOGO_FF_COULEUR_URL} from '@/domains/mediatheque/logoFFCouleurUrl';
import {useCapture} from '@/posthog/PosthogSetup';

export const SelectClientView = () => {
  const {t} = useTranslation();
  return <HaliRenderOne
    nav={useMe().follow('switch')}
    render={({data: {items: choices}}) => (
      <Flex minHeight="100vh"
        direction={{base: 'column', md: 'row'}}
        bg='white'>
        {/* Uniquement en desktop */}
        <PartieGaucheConnexion />

        <VStack h='100vh'
          flex="1"
          alignItems="center"
          overflow="auto"
          minWidth={{base: 'none', md: '600px'}}>
          <VStack p={{base: '1em', md: '2em'}}
            mt={{base:'2em', md:'5em'}}
            bgColor="#0072BA19"
            w={{base:'calc(100vw - 2em)', md:'80%'}}>
            <Image src={LOGO_FF_COULEUR_URL}
              alt={t('global.alt_logo_france_frais')}
              w="156px"
              height="auto" />
            <Text mt="1em"
              color="#202125"
              fontWeight="bold"
              fontSize="xl"
              textAlign="center">
              {t('select_client.greeting')}
            </Text>
            <Text color="#202125"
              textAlign="center">
              {t('select_client.selectionnez_compte')}
            </Text>
            <Box mt="1.5em"
              overflow="auto"
              w="100%"
              bgColor="white"
              border="0.5px solid #cfcfcf"
              borderRadius='6px'>
              {choices.map((item, index) => (
                <SelectClient
                  key={item.id}
                  item={item}
                  index={index}
                  count={choices.length}
                />
              ))}
            </Box>
          </VStack>
        </VStack>
      </Flex>
    )}
  />;
};

const SelectClient: React.FC<{
  item: SwitchContact,
  index: number,
  count: number,
}> = ({
  item,
  index,
  count,
}) => {
  const redirect_to = '/authn/switch-contact';
  const params = new URLSearchParams();
  params.append('contact_id', item.id);
  const href = `${redirect_to}?${params.toString()}`;
  const capture = useCapture();
  return <a href={href}
    onClick={() => {
      capture({name: 'auth:choisir_client', props: {index, count}});
    }}>
    <HStack justifyContent="space-between"
      borderBottom="0.5px solid #cfcfcf">
      <Box p="1.52em 1em">
        <Text fontWeight="bold"
          color="blue.main">{item.client.raison_sociale}</Text>
        <Text fontSize="sm"
          mb="0.5em">{item.contact.prenom} {item.contact.nom}</Text>
      </Box>
      <Box pr="1em">
        <RightSingleArrow />
      </Box>
    </HStack>
  </a>; 
};
