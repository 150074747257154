import {Text, VStack} from '@chakra-ui/react';
import {TFunction} from 'i18next';

export const LeftPaniersAValider: React.FC<{t: TFunction, nbPaniers: number}> = ({t, nbPaniers}) => (
  <VStack alignItems='start'
    px='9'
    py={{base:'9', md:'0'}}>
    <Text 
      as={'h2'} 
      fontSize={{base:'2xl', md:'3xl'}} 
      fontWeight='700'
    >
      {t('panier.vide.vous_avez_x_panier', {count: nbPaniers})}
    </Text>
    <Text fontSize='lg'
      fontWeight='500'>{t('panier.vide.validez_pour_reserver')}</Text>
  </VStack>
);
