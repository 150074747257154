import {Tooltip, VStack} from '@chakra-ui/react';
import {SwitchIcon} from '@/components/svg/SwitchIcon';
import {HaliRenderOne} from '@synako/haligator';
import {useClient} from '@/haligator-factory';
import {TFunction} from 'i18next';

export const LignePanierRemplacementPicto: React.FC<{
  remplacement_de: string,
  t: TFunction
}> = ({
  remplacement_de,
  t
}) => {
  return (
    <HaliRenderOne 
      nav={useClient().follow('go:item_catalogue', {code_article:remplacement_de}).follow('article')} 
      render={({data}) => (
        <Tooltip label={t('panier.remplacement_de', {produit: data.tolede.libelle})}>
          <VStack 
            position='absolute' 
            top='0' 
            left='0' 
            justify='center' 
            bg='blue.main' 
            p='1' 
            m='3' 
            borderRadius='8px'
            zIndex='docked'
          >
            <SwitchIcon color='white'
              width='12px'
              height='12px' />
          </VStack>
        </Tooltip>
      )}
    />
  );
};
