import {DownloadIcon} from '@chakra-ui/icons';
import {Box, HStack, Link, Text, Tooltip, VStack} from '@chakra-ui/react';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {Article} from 'api-types/ffconnect';
import {useTranslation} from 'react-i18next';

export const FicheProduit: React.FC<{articleState:State<Article>}> = ({articleState}) => {
  const mediasState = useHalState(articleState.follow('medias'));
  const {t} = useTranslation();

  return (
    <VStack spacing={2}
      alignItems="stretch">
      <HaliRenderOne
        nav={mediasState.navigable.follow('fiche_produit')}
        render={({state: ficheProduitState}) => (
          <HStack bg="white"
            p={4}
            borderRadius="md"
            borderColor="blue.500"
            borderWidth="1px"
            width="100%"
            margin="auto"
            justifyContent="space-between">
            <VStack alignItems='left'>
              <Text color="blue.500"
                fontSize="xs"
                fontWeight='700'>
                {t('fiche_produit.fiche_produit_pdf.telecharger_fiche_produit')}
              </Text>
            </VStack>
            <Link
              href={ficheProduitState.data.url}
              target="_blank"
              rel="noopener noreferrer"
              boxSize='35px'
              bg="gray.light"
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={0}>
              <DownloadIcon />
            </Link>
          </HStack>
        )}
        notFound={() => (
          <HStack bg="gray.75"
            p={4}
            borderRadius="md"
            borderColor="blue.transparency_300_60"
            borderWidth="1px"
            width="100%"
            margin="auto"
            justifyContent="space-between">
            <VStack alignItems='left'>
              <Text fontSize="xs"
                color="gray.dark"
                fontWeight="400">{t('fiche_produit.fiche_produit_pdf.fiche_produit_non_disponible')}</Text>
            </VStack>
            <Tooltip label="Non disponible"
              aria-label="Fiche produit non disponible">
              <Box boxSize='35px'
                bg="gray.light"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={0}>
                <DownloadIcon color='gray.300'/>
              </Box>
            </Tooltip>
          </HStack>
        )}
      />
    </VStack>
  );
};
