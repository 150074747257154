import {Icon, ResponsiveValue} from '@chakra-ui/react';

export const CartIcon: React.FC<{
  color?:ResponsiveValue<string>;
  width?:ResponsiveValue<string>;
  height?:ResponsiveValue<string>;
}> = ({color='blue.main', width='19px', height='19px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 19 19" 
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5273_26756)">
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M14.25 16.625C14.25 15.7506 14.9589 15.0417 15.8333 15.0417C16.7078 15.0417 17.4167 15.7506 17.4167 16.625C17.4167 17.4995 16.7078 18.2084 15.8333 18.2084C14.9589 18.2084 14.25 17.4995 14.25 16.625Z"/>
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5415 16.625C5.5415 15.7506 6.25039 15.0417 7.12484 15.0417C7.99929 15.0417 8.70817 15.7506 8.70817 16.625C8.70817 17.4995 7.99929 18.2084 7.12484 18.2084C6.25039 18.2084 5.5415 17.4995 5.5415 16.625Z"/>
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.791667C0 0.354441 0.354441 0 0.791667 0H3.95833C4.33566 0 4.66055 0.266305 4.7346 0.636297L5.39951 3.95833H18.2083C18.4443 3.95833 18.6679 4.06357 18.8183 4.24536C18.9687 4.42714 19.0302 4.66654 18.986 4.8983L17.7182 11.5463C17.6096 12.093 17.3121 12.5841 16.878 12.9337C16.4459 13.2815 15.9059 13.467 15.3515 13.4583H7.67018C7.1158 13.467 6.57574 13.2815 6.14371 12.9337C5.7097 12.5843 5.41234 12.0934 5.30364 11.547C5.30359 11.5468 5.30368 11.5472 5.30364 11.547L3.98113 4.93937C3.97576 4.91751 3.9713 4.8953 3.9678 4.87277L3.30942 1.58333H0.791667C0.354441 1.58333 0 1.22889 0 0.791667ZM5.71641 5.54167L6.8565 11.2379C6.89269 11.4201 6.99184 11.5838 7.13657 11.7003C7.2813 11.8168 7.4624 11.8787 7.64816 11.8751L7.66333 11.875H15.3583L15.3735 11.8751C15.5593 11.8787 15.7404 11.8168 15.8851 11.7003C16.0292 11.5843 16.128 11.4216 16.1647 11.2404L17.2514 5.54167H5.71641Z"/>
      </g>
      <defs>
        <clipPath id="clip0_5273_26756">
          <rect width="19"
            height="19"
            fill="white"/>
        </clipPath>
      </defs>
    </Icon>
  );
};