import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, IconButton, Image} from '@chakra-ui/react';
import {BsFillPauseFill, BsFillPlayFill} from 'react-icons/bs';

export const PartieGaucheConnexion: React.FC = () => {
  // state pour gérer l'index de l'image courante
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const images = [
    '/connexion/img/interface6.png',
    '/connexion/img/interface7.png',
    '/connexion/img/interface8.png',
    '/connexion/img/interface9.png',
    '/connexion/img/interface10.png',
    '/connexion/img/interface11.png',
    '/connexion/img/interface12.png',
    '/connexion/img/interface13.png',
    '/connexion/img/interface14.png',
    '/connexion/img/interface15.png',
    '/connexion/img/interface16.png',
    '/connexion/img/interface17.png',
    '/connexion/img/interface18.png',
    '/connexion/img/interface19.png',
    '/connexion/img/interface20.png',
    '/connexion/img/interface21.png',
    '/connexion/img/interface22.png',
    '/connexion/img/interface23.png',
    '/connexion/img/interface24.png',
    '/connexion/img/interface25.png',
    '/connexion/img/interface26.png',
    '/connexion/img/interface27.png',
    '/connexion/img/interface28.png',
    '/connexion/img/interface29.png',
    '/connexion/img/interface30.png',
  ];

  // fonction pour passer à l'image suivante
  const nextImage = useCallback(() => {
    if (!isPaused) {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % images.length
      );
    }
  }, [isPaused, images.length]);

  // fonction pour démarrer l'intervalle
  const startInterval = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    // on change d'image toutes les 6 secondes
    intervalIdRef.current = setInterval(nextImage, 6000);
  }, [nextImage]);

  // pour changer d'image toutes les 3 secondes
  useEffect(() => {
    if (!isPaused) {
      startInterval();
    } else if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [isPaused, startInterval]);

  // fonction pour mettre en pause ou reprendre le défilement des images
  const togglePause = () => {
    setIsPaused(prev => !prev);
  };

  return (
    <Box position="relative"
      height="100vh"
      display={{base: 'none', md: 'block'}}
      maxWidth="800px">
      <Box position="relative"
        width="100%"
        height="100%"
        overflow="hidden">
        <Image
          src={`${import.meta.env.BASE_URL}${images[currentImageIndex]}`}
          alt={`Image ${currentImageIndex + 1}`}
          width="100%"
          height="100%"
          objectFit="cover"
          style={{
            transition: 'transform 0.5s ease-in-out'
          }}
        />
      </Box>

      <IconButton
        aria-label={isPaused ? 'Play' : 'Pause'}
        icon={isPaused ? <BsFillPlayFill /> : <BsFillPauseFill />}
        position="absolute"
        bottom="20px"
        left="50%"
        transform="translateX(-50%)"
        onClick={togglePause}
        colorScheme="whiteAlpha"
        size="lg"
        zIndex={200}
      />
    </Box>
  );
};
