import {Chargement} from '@/components/Chargement/Chargement';
import {LOGO_FF_COULEUR_URL} from '@/domains/mediatheque/logoFFCouleurUrl';
import {AssistanceClientBanniere} from '@/backoffice/assistance/AssistanceClientBanniere';
import {Image, VStack} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {imageOptimisee} from '../mediatheque/optimisation';

export const ChargementCompte: React.FC<{
  idMessage: string
}> = ({
  idMessage
}) => {
  const {id_client_assistance} = useParams<{id_client_assistance?: string}>();
  const {t} = useTranslation();

  return(
    <>
      {id_client_assistance && <AssistanceClientBanniere />}
      <VStack bg='white'
        minHeight='100vh'
        width='100%'
        justify='center'
        align='center'
        spacing={2}>
        <Image 
          src={imageOptimisee(LOGO_FF_COULEUR_URL, 300)}
          alt={t('global.alt_logo_france_frais')}
          maxWidth="300px"
        />
        <Chargement texte={t(`accueil.${idMessage}`)}/>
      </VStack>
    </>
  );
};
