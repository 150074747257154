import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Box, Input, InputGroup, InputRightAddon, VStack} from '@chakra-ui/react';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {SearchIcon} from '../../svg/SearchIcon';
import {SEARCH_MIN_CHARS, routes, useLivraisonsPossiblesContext, useSearchClient} from 'ff-common';
import {Result, Results} from '@orama/orama';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {useTranslation} from 'react-i18next';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {SearchResultItemComponent} from './SearchResultItemComponent';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {debounce} from 'lodash-es';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import {useCapture} from '@/posthog/PosthogSetup';

export const SearchModal = NiceModal.create(() => {
  const navigate = useNavigate();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const [searchParams] = useSearchParams();
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const textToast = useSimpleTextToast();
  const modal = useModal();
  const {t} = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [term, setTerm] = useState(searchParams.get('q') ?? '');
  const search = useSearchClient();
  const [itemResults, setItemResults] = useState<Results<HitItemCatalogue>>();
  
  const capture = useCapture();
  const debounceCapture = useMemo(() => debounce(capture, 1000), [capture]);

  const handleTermSubmit = useCallback((newTerm: string) => {
    setTerm(newTerm);
    const rechercheUrl = buildAssistanceRoute(routes.client.recherche, {}, {q: newTerm});
    navigate(rechercheUrl);
    modal.hide();
    NiceModal.remove(CatalogueModale);
  }, [buildAssistanceRoute, navigate, modal]);

  const itemLink = useCallback((code_article: string) => {
    return buildAssistanceRoute(routes.client.produit, {code_article}, {from: 'recherche', query: encodeURI(`?q=${term}`)});
  }, [term, buildAssistanceRoute]);

  const {submit: AjouterAction} = useAjouterAuPanier(livraisonSelectionnee.data.id, textToast);

  const AjouterAuPanier = useCallback(async (code_article: string, quantite: number) => {
    AjouterAction({code_article, quantite, commande_en: 'unitaire'});
  }, [AjouterAction]);

  // pour un article qui n'est pas en precommande
  const handleCartIconClick = useCallback((code: string, multiple_de_commande_client: number | undefined) => {
    AjouterAuPanier(code, multiple_de_commande_client ?? 1);
    navigate(itemLink(code));
    NiceModal.hide(SearchModal);
    NiceModal.remove(CatalogueModale);
  }, [AjouterAuPanier, navigate, itemLink]);

  // pour un article qui est en precommande
  const handlePrecommandeAction = useCallback((itemState: State<ItemCatalogueDesc>) => {
    textToast(t('toast.produit_precommande'));
    navigate(itemLink(itemState.data.tolede.code_article));
    NiceModal.hide(SearchModal);
  }, [navigate, itemLink, textToast, t]);

  useEffect(() => {
    if (!modal.visible) return;
    // Petit délai pour laisser la modale s'ouvrir complètement
    const timeoutId = setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, 10);

    return () => {clearTimeout(timeoutId);};
  }, [modal.visible]);

  const debouncedSearch = useMemo(() =>
    debounce(async (searchTerm: string) => {
      if (search.indexIsBuilt && searchTerm.length >= SEARCH_MIN_CHARS) {
        const results = await search.searchItems({q: searchTerm});
        setItemResults(results);
        debounceCapture({
          name: 'search:hits',
          props: {
            count: results.count,
            first_score: results.hits[0]?.score,
            term: searchTerm,
          }
        });
      } else {
        setItemResults(undefined);
      }
    }, 300),
  [search, debounceCapture]
  );

  const handleTermChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newTerm = event.target.value;
    setTerm(newTerm);
    debouncedSearch(newTerm);
  }, [debouncedSearch]);

  const handleTermClick = useCallback(() => {
    if (term) {
      handleTermSubmit(term);
    }
    modal.hide();
    NiceModal.remove(CatalogueModale);
  }, [term, handleTermSubmit, modal]);

  // Touche entrée
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      capture({
        name: 'search:enter',
        props: {
          term,
        }
      });
      handleTermClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTermClick]);

  const handleProductClick = useCallback((result: Result<HitItemCatalogue>, index: number) => {
    capture({
      name: 'search:go_hit',
      props: {
        term: term,
        index,
        score: result.score,
        code: result.document.code,
        libelle: result.document.libelle_commercial,
      }
    });
    modal.hide();
  }, [modal, capture, term]);
  const contenuBarreModale = (
    <Box>
      <InputGroup bg='white'
        borderTopRadius='8px'
        opacity={search.indexIsBuilt ? 1 : 0.2}>
        <Input
          ref={inputRef}
          placeholder={search.indexIsBuilt ? t('catalogue.chercher_produit', {min_lettres: SEARCH_MIN_CHARS}) : t('catalogue.chargement')}
          onChange={handleTermChange}
          value={term}
          onKeyDown={handleKeyDown}
          isDisabled={!search.indexIsBuilt}
          _focus={{
            boxShadow: 'none',
            borderColor: 'gray.200',
          }}
          height="40px"
          cursor='pointer'
        />
        <InputRightAddon pointerEvents="auto"
          cursor="pointer"
          onClick={handleTermClick}>
          <SearchIcon />
        </InputRightAddon>
      </InputGroup>
      {itemResults && term.length >= SEARCH_MIN_CHARS && (
        <Box 
          bg='white' 
          w='100%' 
          fontSize='sm' 
          fontWeight='400' 
          maxHeight={{base:'calc(100vh - 120px)', md:'400px'}} 
          overflowY="auto" 
          mt={2} 
          sx={{
            '&::-webkit-scrollbar': {
              width: '0px',
              background: 'transparent'
            },
            '&': {
              scrollbarWidth: 'none',
              msOverflowStyle: 'none'
            },
          }}
        >
          {itemResults.hits.slice(0, 15).map((item, index) => (
            <SearchResultItemComponent
              key={item.id}
              item={item}
              index={index}
              itemLink={itemLink}
              handleProductClick={handleProductClick}
              handleCartIconClick={handleCartIconClick}
              handlePrecommandeAction={handlePrecommandeAction}
            />
          ))}
        </Box>
      )}
    </Box>
  );

  return (
    <ModaleLayout
      visible={modal.visible}
      hide={modal.hide}
      size_desktop="xl"
      drawerPlacement="top"
      drawerSize="full"
      contentStyle={{
        top: '0',
        h: {base: '100vh', md: 'auto'},
        mb: '0',
      }}
      closeButtonStyle={{
        marginTop: {base: '5px', md: '6'},
        marginRight: {base: 'auto', md: '1'},
        height: '40px',
      }}
    >
      <Box pr={{base: '20px', md: '0'}}
        width="100%">
        <VStack spacing={4}
          align="stretch">
          {contenuBarreModale}
        </VStack>
      </Box>
    </ModaleLayout>
  );
});
