import {HStack} from '@chakra-ui/layout';
import {Image, Tag, Tooltip} from '@chakra-ui/react';
import {ArticleDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {useHalState} from '@synako/haligator';
import {useLabelAsset} from '@/haligator-factory';
import {useState} from 'react';
import {Label} from 'api-types/ffconnect';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';

const LabelImage: React.FC<{label: Label}> = ({label}) => {
  const labelHook = useHalState(useLabelAsset(label.code));
  const [hasError, setHasError] = useState(false);
  const labelImage = imageOptimisee(labelHook.data?.url, 22);

  const onError = () => {
    setHasError(true);
  };

  if (hasError || labelHook.error) {
    return <Tag fontSize='3xs'>{label.libelle}</Tag>;
  }

  return (
    <Tooltip label={label.libelle}>
      <Image
        boxSize="22px"
        src={labelImage}
        alt={label.libelle}
        onError={onError}
      />
    </Tooltip>
  );
};

export const DisplayLabels: React.FC<{articleState: State<ArticleDesc>;}> = ({articleState}) => {
  return (
    <HStack spacing={2}>
      {articleState.data.tolede.SIQO_et_regimes_alimentaires?.map((label) => (
        <LabelImage key={label.code}
          label={label} />
      ))}
    </HStack>
  );
};
