/* eslint-disable i18next/no-literal-string */
import React, {useEffect, useMemo, useState} from 'react';
import {useSearchClient} from 'ff-common';
import {Box, Heading, Input, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {HitItemCatalogue} from 'api-types/ffconnect';

export const SearchRemplacementView: React.FC = () => {
  const search = useSearchClient();

  const [aRemplacer, setARemplacer] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [config, setConfig] = useState<any>();
  const [itemResults, setItemResults] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const tous = useMemo<Record<number, HitItemCatalogue>>(() => {
    return search.indexes?.catalogue_idx.data.docs.docs ?? {};
  }, [search.indexes]);

  useEffect(() => {
    if(!search.indexIsBuilt || !aRemplacer) return;

    const rempl = Object.values(tous).find(hit => hit.code === aRemplacer);
    if (rempl) {
      const config = {
        code_article: rempl.code,
        sous_sous_famille_std: rempl.cat_std[rempl.cat_std.length - 1],
        mot_directeur: rempl.mot_directeur,
        enleverPrecommande: false,
      };
      setConfig(config);
      search.remplacementPour2(config).then((r) => {
        setItemResults(r);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.indexIsBuilt, aRemplacer]);

  return (
    <Box fontSize='sm'>
      <Box padding={5}>
        <Heading as='h2'>Remplacement {search.indexIsBuilt ? '(READY)' : '(WAIT !)'}</Heading>
        Code article à remplacer :
        <Input onChange={(e) => {
          setARemplacer(e.target.value);
        }} />
        <div>
          Remplacement de {config?.code_article}.
          Sélection des articles avec le mot directeur ’{config?.mot_directeur}’ et la catégorie standard {config?.sous_sous_famille_std}.
        </div>
        <div>
          {itemResults?.count} résultats en {itemResults?.elapsed.formatted}.
        </div>
        <Table width='auto'
          variant='striped'
          size='sd'>
          <Thead>
            <Tr>
              <Th>Code</Th>
              <Th>Libellé commercial</Th>
              <Th>Marque</Th>
              <Th>Mot directeur</Th>
              <Th>SSF</Th>
              <Th>Cad.</Th>
              <Th>TAC</Th>
              <Th>MP</Th>
              <Th>Préco.</Th>
            </Tr>
          </Thead>
          <Tbody>
            {itemResults?.hits.map(hit => {
              return <Tr key={hit.id}
                title={JSON.stringify(hit, null, 2)}>
                <Td>{hit.document.code}</Td>
                <Td>{hit.document.libelle_commercial}</Td>
                <Td>{hit.document.marque ?? '-'}</Td>
                <Td>{hit.document.mot_directeur ?? '-'}</Td>
                <Td>{hit.document.cat_std[hit.document.cat_std.length - 1]}</Td>
                <Td>{hit.document.est_cadencier ? 'OUI' : '-'}</Td>
                <Td>{hit.document.est_TAC ? 'OUI' : '-'}</Td>
                <Td>{hit.document.type_de_marque}</Td>
                <Td>{hit.document.precommande ? 'OUI' : '-'}</Td>
              </Tr>;
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};