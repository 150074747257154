import {Button} from '@chakra-ui/react';
import React from 'react';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import NiceModal from '@ebay/nice-modal-react';
import {ModalSelectList} from '@/domains/liste/components/ModalSelectList';
import {ListIcon} from '@/components/svg/ListIcon';

export const AjoutListeBouton: React.FC<{itemState: State<ItemCatalogueDesc>;}> = ({itemState}) => {
  return <Button bg='gray.light'
    _hover={{bg: 'gray.100'}}
    onClick={() => {NiceModal.show(ModalSelectList, {itemState});}}>
    <ListIcon color='gray.dark' />
  </Button>;
};
