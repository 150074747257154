import {useState} from 'react';
import {Box, Button, Flex, Grid, GridItem, HStack, Icon, Text, VStack} from '@chakra-ui/react';
import {CategorieWeb} from 'api-types/ffconnect';
import {Link, useNavigate} from 'react-router-dom';
import {routes, useLivraisonsPossiblesContext} from 'ff-common';
import {ChevronLeftIcon, ChevronRightIcon, CloseIcon} from '@chakra-ui/icons';
import {Searchbar} from '@/components/Navbar/Searchbar/Searchbar';
import {CategorieImage} from './CategorieImage';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {PromotionIcon} from '@/components/svg/PromotionIcon';
import {ElapsedTime} from '@orama/orama';
import {useHalState} from '@synako/haligator';

interface PromoCounts {
  count: number;
  values: Record<string, number>;
  totalCount: number;
  elapsed: ElapsedTime;
}

interface CategoriesViewProps {
  categories: CategorieWeb[] | undefined;
  onClose: () => void;
  counts: Record<string, number>;
  promoCounts: PromoCounts | undefined;
}

const DRAWER_WIDTH = 500; // largeur d'un volet en desktop

export const CategoriesView: React.FC<CategoriesViewProps> = ({categories, onClose, counts, promoCounts,}) => {
  // Chaque fois qu'un utilisateur sélectionne une catégorie, elle est ajoutée à selectedCategories
  const [selectedCategories, setSelectedCategories] = useState<CategorieWeb[]>([]);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const totalPromoCount = promoCounts?.totalCount;

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const tourneeHook = useHalState(livraisonSelectionnee.follow('tournee'));

  const temperature_livraison = tourneeHook.data?.tolede.temperature_de_livraison === 'les_deux'
    ? undefined
    : tourneeHook.data?.tolede.temperature_de_livraison;

  // vérification si une catégorie a des promotions
  const hasPromotions = (categoryId: string) => {
    return promoCounts?.values[categoryId] && promoCounts.values[categoryId] > 0;
  };

  // permet de renvoyer vers le catalogue pour le dernier niveau ou d'ajouter à la liste des categories
  const handleCategorieSelect = (category: CategorieWeb, level: number) => {
    // verification si la catégorie sélectionnée a des sous-catégories 
    const hasSubcategories = categories?.some(cat => cat.id_parent === category.id);

    // si pas de sous categoriees alors envoie vers le catalogue
    if (!hasSubcategories) {
      const catalogueUrl = buildAssistanceRoute(routes.client.catalogue, {}, {cat_web: category.id, temperature_livraison});
      navigate(catalogueUrl);
      onClose();
      // si la catégorie a des sous-catégories, selectedCategories mis à jour
      // prev.slice(0, level) conserve toutes les catégories sélectionnées jusqu'au niveau actuel
      // nouvelle catégorie ajoutée à la fin de cette liste
    } else {
      setSelectedCategories(prev => [...prev.slice(0, level), category]);
    }
  };

  // affiche chaque niveau de catégorie, en tenant compte de la hiérarchie
  const renderCategorieLevel = (level: number) => {
    // level === 0 (premier niveau), pas de catégorie parente || catégorie parente est la catégorie sélectionnée au niveau précédent.
    const parentCategory = level === 0 ? undefined : selectedCategories[level - 1];
    const filteredCategories = parentCategory
      ? categories?.filter(cat => cat.id_parent === parentCategory.id && cat.id !== parentCategory.id && counts[cat.id] > 0)
      // pas de parent, selection des catégories de premier niveau. Les catégories de premier niveau ont un id_parent vide
      : categories?.filter(cat => cat.id_parent === '' && counts[cat.id] > 0 && cat.libelle !== 'Divers');

    // creation du breadcrumb pour mobile
    const handleBreadcrumbClick = (index: number) => {
      setSelectedCategories(prev => prev.slice(0, index + 1));
    };

    const hasSubcategories = (categoryId: string) => {
      return categories?.some(cat => cat.id_parent === categoryId);
    };

    const handlePromoClick = () => {
      navigate(buildAssistanceRoute(routes.client.catalogue, {}, {nature_du_prix: 'promo', from: 'promo', temperature_livraison}));
      onClose();
    };

    return (
      <Box key={level}
        w={{base: '100vw', md: `${DRAWER_WIDTH}px`}}
        h="100%"
        overflowY="auto"
        bg='white'
        pt={{base: '0', md: '30px'}}
        zIndex={101}>
        {/* Uniquement en mobile */}
        {level > 0 && (
          <HStack  display={{base:'flex', md:'none'}}
            alignItems='center'
            justifyContent='space-between'
            mb={4}
            p='10px'
            pl='25px'
            w='100%'
            h='90px'
            bg='categories.produits_laitiers'>
            <HStack spacing={1}>
              {selectedCategories.slice(0, level).map((cat, index) => (
                <HStack key={cat.id}>
                  {index > 0 && <Text color="gray.500">{'>'}</Text>}
                  {/* obtention du breadcrumb */}
                  <Text
                    fontSize={index === level - 1 ? 'lg' : 'md'}
                    fontWeight={index === level - 1 ? 'bold' : '400'}
                    color={index === level - 1 ? 'black' : 'gray.500'}
                    cursor="pointer"
                    onClick={() => {handleBreadcrumbClick(index);}}
                  >
                    {cat.libelle}
                  </Text>
                </HStack>
              ))}
            </HStack>
            <CategorieImage
              categorieId={selectedCategories[0].id}
              libelle={selectedCategories[0].libelle}
              objectFit="cover"
              height='50px'
            />
          </HStack>
        )}
        <VStack align="stretch"
          spacing={0}
          pt={{base:0, md:4}}
          mb={{base:'80px', md:'0'}}>
          {level === 0 && totalPromoCount && totalPromoCount > 0 && <HStack cursor='pointer'
            _hover={{bg: 'gray.100'}}
            pl='45px'>
            <PromotionIcon color="red.200"
              height="24px"
              width="24px"/>
            <Button variant='unstyled'
              color="red.200"
              fontWeight={500}
              onClick={handlePromoClick}
              height='70px'>
              {t('catalogue.promotion_du_moment')}
            </Button>
          </HStack>}
          {level === 1 && selectedCategories[0] && hasPromotions(selectedCategories[0].id) && (
            <VStack align='flex-start'
              height='70px'>
              <Link to={buildAssistanceRoute(routes.client.catalogue, {}, {cat_web: selectedCategories[0].id, nature_du_prix: 'promo', temperature_livraison})}
                style={{width: '100%'}}>
                <HStack cursor='pointer'
                  _hover={{bg: 'gray.100'}}
                  pl='35px'>
                  <PromotionIcon color="red.200"
                    height="24px"
                    width="24px"/>
                  <Button variant='unstyled'
                    color="red.200"
                    fontWeight={500}
                    onClick={onClose}
                    height='70px'>
                    {t('catalogue.promotion_du_moment')}
                  </Button>
                </HStack>
              </Link>
            </VStack>
          )}
          {level > 0 && (
            <Link to={buildAssistanceRoute(routes.client.catalogue, {}, {cat_web: selectedCategories[level - 1].id, temperature_livraison})}>
              <HStack p={2}
                pl='40px'
                bg='white'
                h='70px'
                w='100%'
                _hover={{bg: 'gray.100'}}
                cursor='pointer'
                onClick={() => {onClose();}}>
                <Text mr='auto'
                  color="blue.main"
                  fontWeight={500}>
                  {t('catalogue.voir_tout')}
                </Text>
              </HStack>
            </Link>
          )}
          {filteredCategories?.map((category: CategorieWeb) => (
            <Grid
              key={category.id}
              templateColumns={level === 0 ? '80px 1fr auto' : '1fr auto'}
              gap={4}
              alignItems="center"
              bg={selectedCategories[level]?.id === category.id ? 'gray.bg' : 'transparent'}
              _hover={{bg: 'gray.100'}}
              pt={2}
              pb={2}
              pl='40px'
              borderTop={{base:'solid 1px', md:'none'}}
              borderColor={{base:'gray.light', md:'none'}}
              height='70px'
              cursor="pointer"
              onClick={() => {handleCategorieSelect(category, level);}}
            >
              {/* affichage des images pour les catégories de premier niveau */}
              {level === 0 && (
                <GridItem w='70px' >
                  <CategorieImage
                    categorieId={category.id || ''}
                    libelle={category.libelle || ''}
                    objectFit="cover"
                  />
                </GridItem>
              )}
              <GridItem justifyContent='space-between'
                w='100%'>
                <Text fontWeight={level === 0 ? '600' : '500'}
                  color='gray.dark'
                  _hover={{color: 'blue.main'}}>{category.libelle}</Text>
              </GridItem>
              {hasSubcategories(category.id) && (
                <GridItem pr='20px'>
                  <ChevronRightIcon boxSize="20px" />
                </GridItem>
              )}
            </Grid>
          ))}
        </VStack>
      </Box>
    );
  };

  return (
    <Box height="100%"
      overflowX="hidden">
      {/* Version mobile */}
      <Box height='100%'
        display={{base:'block', md:'none'}}>
        <Box position="sticky"
          top={0}
          zIndex={10}
          bg="white">
          <HStack spacing={4}
            alignItems="center"
            bg='gray.bg'
            p={4}>
            <Icon
              as={selectedCategories.length > 0 ? ChevronLeftIcon : CloseIcon}
              boxSize={selectedCategories.length > 0 ? 6 : 3}
              mr={selectedCategories.length > 0 ? '0' : '12px'}
              cursor="pointer"
              onClick={selectedCategories.length > 0 ? () => {setSelectedCategories(prev => prev.slice(0, -1));} : onClose}
            />
            <Box flex={1}>
              <Searchbar props={{bg: 'white'}} />
            </Box>
          </HStack>
        </Box>
        {/* affichage des catégories correspondant au niveau actuel de profondeur dans la hiérarchie */}
        {renderCategorieLevel(selectedCategories.length)}
      </Box>

      {/* Version desktop */}
      <Flex height="100%"
        overflowX="auto"
        display={{base:'none', md:'flex'}}>
        {/* affiche toujours le premier niveau des catégories*/}
        {renderCategorieLevel(0)}
        {/* affiche les catégories de niveau inférieur*/}
        {selectedCategories.map((_, index) => renderCategorieLevel(index + 1))}
      </Flex>
    </Box>
  );
};
