import {IconeTemperatureLivraison} from '@/components/IconeTemperatureLivraison';
import {PolygonDown} from '@/components/svg/PolygonDown';
import {Box, HStack, Text} from '@chakra-ui/layout';
import {VStack} from '@chakra-ui/react';
import {TemperatureDeLivraison} from 'api-types/ffconnect';
import {Trans, useTranslation} from 'react-i18next';

interface InfoLivraisonProps {
  onClick:()=>void,
  date_de_livraison: string;
  nb_article: number;
  active: boolean;
  temperature_tournee?: TemperatureDeLivraison
}

export const InfoLivraisonMobile: React.FC<InfoLivraisonProps> 
= ({onClick, date_de_livraison, nb_article, active, temperature_tournee}) => {
  const {t} = useTranslation('common');
  return (
    <HStack onClick={onClick}
      borderRadius='8px'
      p='3'
      border='1px solid'
      borderColor={active ? 'blue.main' : 'gray.dark'}
      bg={active ? 'lblue.100' : 'transparent.0'}
      userSelect='none'>
      <IconeTemperatureLivraison temperature_tournee={temperature_tournee}
        boxSize={15}/>
      <Text textColor={active ? 'blue.main' : 'gray.dark'}>
        <Trans t={t}>{t('panier.pour_la_livraison_du_mobile', {date:new Date(date_de_livraison), 
          formatParams: {
            date: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
          },
        })}</Trans>
      </Text>
      <Box p='1.5'
        px='2.5'
        borderRadius='8px'
        backgroundColor='gray.light'
        textColor={active ? 'blue.main' : 'gray.dark'}>{nb_article}</Box>
    </HStack>
  );
};

export const InfoLivraisonDesktop: React.FC<InfoLivraisonProps> 
= ({onClick, date_de_livraison, nb_article, active, temperature_tournee}) => {
  const {t} = useTranslation('common');
  
  return (
    <VStack gap='0'
      cursor='pointer'
      onClick={onClick}
      userSelect='none'>
      <VStack alignItems='start'
        textColor='blue.main'
        backgroundColor={active ? 'lblue.100' : 'transparent.0'}
        p='5'
        px='6'
        borderRadius='8px'
        border='1px solid'
        borderColor={active?'transparent':'gray.light'}>
        <Text><Trans t={t}>{t('panier.pour_la_livraison_du', {date:new Date(date_de_livraison), 
          formatParams: {
            date: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
          },
        })}</Trans></Text>
        <HStack fontSize='sm'>
          <IconeTemperatureLivraison temperature_tournee={temperature_tournee}
            boxSize={15}/>
          <Box p='1.5'
            px='2.5'
            borderRadius='8px'
            backgroundColor={active ? 'transparent.50' : 'gray.light'}
            color='blue.main'>
            {t('global.produit', {count:nb_article})}
          </Box>
          <Box p='1.5'
            px='2.5'
            borderRadius='8px'
            backgroundColor='yellow.warning'
            color='yellow.warning_text'>{t('global.a_valider')}</Box>
        </HStack>
      </VStack>
      <VStack mt='-1px'
        h='14px'>{active && <PolygonDown />}</VStack>
    </VStack>
  );
};
