export const SwitchAccountIcon = () => {
  return (
    <svg width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5917 4.35457L13.2583 1.1879C13.1806 1.11409 13.0884 1.05553 12.9869 1.01559C12.8854 0.975639 12.7765 0.955078 12.6667 0.955078C12.4447 0.955078 12.2319 1.03883 12.075 1.1879C11.9181 1.33697 11.8299 1.53916 11.8299 1.74998C11.8299 1.96081 11.9181 2.16299 12.075 2.31207L13.9917 4.12498H4.33333C4.11232 4.12498 3.90036 4.20839 3.74408 4.35686C3.5878 4.50532 3.5 4.70669 3.5 4.91665C3.5 5.12661 3.5878 5.32798 3.74408 5.47644C3.90036 5.62491 4.11232 5.70832 4.33333 5.70832H16C16.1645 5.70754 16.3251 5.66051 16.4616 5.57316C16.598 5.48582 16.7041 5.36206 16.7667 5.21748C16.8305 5.07331 16.8479 4.91442 16.8168 4.76084C16.7856 4.60726 16.7073 4.46589 16.5917 4.35457ZM12.6667 7.29165H1C0.835479 7.29243 0.674883 7.33946 0.538449 7.4268C0.402015 7.51415 0.295851 7.63791 0.233334 7.78248C0.169518 7.92666 0.152082 8.08555 0.183227 8.23913C0.214372 8.3927 0.292702 8.53407 0.408334 8.6454L3.74167 11.8121C3.81914 11.8863 3.9113 11.9452 4.01285 11.9854C4.1144 12.0255 4.22332 12.0462 4.33333 12.0462C4.44334 12.0462 4.55227 12.0255 4.65381 11.9854C4.75536 11.9452 4.84753 11.8863 4.925 11.8121C5.00311 11.7385 5.0651 11.6509 5.10741 11.5544C5.14972 11.458 5.1715 11.3545 5.1715 11.25C5.1715 11.1455 5.14972 11.042 5.10741 10.9455C5.0651 10.8491 5.00311 10.7615 4.925 10.6879L3.00833 8.87498H12.6667C12.8877 8.87498 13.0996 8.79158 13.2559 8.64311C13.4122 8.49464 13.5 8.29328 13.5 8.08332C13.5 7.87335 13.4122 7.67199 13.2559 7.52353C13.0996 7.37506 12.8877 7.29165 12.6667 7.29165Z"
        fill="black"/>
    </svg>
  );
};