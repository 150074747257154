import {ProductControlSideHeader} from '@/components/Product/ProductControlSideHeader';
import {useListes} from '@/haligator-factory';
import {ChevronLeftIcon} from '@chakra-ui/icons';
import {Box, HStack, Stack, Text} from '@chakra-ui/react';
import {useHalCollection} from '@synako/haligator';
import {Link, useParams} from 'react-router-dom';
import {ListeSection} from './components/ListeSection';
import {LoadingSpinner} from '@/components/Loading/LoadingSpinner';
import {routes} from 'ff-common';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useTranslation} from 'react-i18next';

export const ListeView: React.FC = () => {
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {code_liste} = useParams<{code_liste: string}>();
  const listesHook = useHalCollection(useListes());

  // selection de la liste concernée
  const selectedListState = listesHook.items?.find((list) => {
    if (code_liste && list.data.code_liste) {
      return list.data.code_liste === code_liste;
    }
    return false;
  });

  const listItemsHook = selectedListState?.followAllOrThrow('selection');

  if (listItemsHook == undefined) return <LoadingSpinner/>;

  return(
    <Box backgroundColor="white"
      p={4}
      borderRadius="md"
      boxShadow="md"
      m='30px'>
      <Stack justifyContent='start'
        w='100%'
        flexDirection={{md: 'row', base: 'column'}}>
        <HStack>
          <Text fontSize="xl"
            fontWeight="bold">
            <Link to={buildAssistanceRoute(routes.client.listes_default)}><ChevronLeftIcon _hover={{background:'gray.light'}}
              borderRadius='8px'
              boxSize='8'
              m='1'/></Link>{selectedListState?.data.nom_liste} </Text>
          <Box p='2px 5px 2px 5px'
            bg='gray.light'
            borderRadius='5px'>
            <Text fontSize='sm'>
              {t('global.produit', {count: listItemsHook.length})}
            </Text>
          </Box>
        </HStack>
        <ProductControlSideHeader/>
      </Stack>
      <ListeSection produits={listItemsHook}
        liste={selectedListState}/>
    </Box>
  );
};
