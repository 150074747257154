import {Icon} from '@chakra-ui/react';

export const CadencierIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='black', width='27px', height='28px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 27 28" 
      fill={color}
      stroke={color}
      strokeWidth="0"
    >
      <path d="m11.881 2.1602c-0.6684 0.00187-1.3199 0.21096-1.8652 0.59766-0.54524 0.3867-0.95882 0.93226-1.1816 1.5625h-1.2734c-0.85931 0-1.6834 0.3416-2.291 0.94922-0.60762 0.60762-0.94922 1.4317-0.94922 2.291v12.959c0 0.8593 0.3416 1.6834 0.94922 2.291 0.60762 0.6076 1.4317 0.94922 2.291 0.94922h5.1953a7.5 7.5 0 0 1-0.67188-2.1602h-4.5234c-0.28644 0-0.56113-0.11381-0.76367-0.31641-0.20254-0.2025-0.31641-0.47727-0.31641-0.76367v-12.959c0-0.28644 0.11387-0.56113 0.31641-0.76367s0.47723-0.31641 0.76367-0.31641h1.0801v1.0801c0 0.28643 0.11387 0.56113 0.31641 0.76367 0.20254 0.20254 0.47723 0.31641 0.76367 0.31641h6.4785c0.2865 0 0.56117-0.11387 0.76367-0.31641 0.2026-0.20254 0.31641-0.47724 0.31641-0.76367v-1.0801h1.0801c0.2865 0 0.56117 0.11387 0.76367 0.31641 0.2026 0.20254 0.31641 0.47723 0.31641 0.76367v5.4434a7.5 7.5 0 0 1 0.060547-0.003906 7.5 7.5 0 0 1 2.0996 0.30078v-5.7402c0-0.85931-0.34162-1.6834-0.94922-2.291-0.6076-0.60762-1.4317-0.94922-2.291-0.94922h-1.2734c-0.2228-0.63024-0.63644-1.1758-1.1816-1.5625-0.5453-0.3867-1.1967-0.59579-1.8652-0.59766h-2.1582zm0 2.1602h2.1582c0.2865 0 0.56117 0.11387 0.76367 0.31641 0.2026 0.20254 0.31641 0.47723 0.31641 0.76367v1.0801h-4.3184v-1.0801c0-0.28644 0.11381-0.56113 0.31641-0.76367 0.2025-0.20254 0.47727-0.31641 0.76367-0.31641z"/>
      <g clipPath="url(#clip0_7759_25964)"
        strokeWidth=".4">
        <path d="m15.219 20.06c-1e-4 -0.1842-0.0733-0.3609-0.2036-0.4912l-0.1414 0.1414 0.1414-0.1414c-0.1303-0.1303-0.3071-0.2035-0.4914-0.2035-0.1844 0-0.3611 0.0732-0.4915 0.2035-0.1303 0.1303-0.2035 0.3071-0.2035 0.4915v5e-4c0.0027 1.0057 0.2998 1.9886 0.8546 2.8274 0.5549 0.8387 1.3432 1.4967 2.2677 1.8927 0.9244 0.3959 1.9446 0.5126 2.9346 0.3355 0.8427-0.1508 1.6322-0.5088 2.2987-1.0388v0.4377c0 0.1843 0.0732 0.3611 0.2035 0.4914 0.1304 0.1303 0.3071 0.2036 0.4915 0.2036 0.1843 0 0.3611-0.0733 0.4914-0.2036s0.2036-0.3071 0.2036-0.4914v-2.2275l-1e-4 -2e-3c-0.0017-0.1795-0.0728-0.3515-0.1985-0.4797l-0.1429 0.14 0.1429-0.14c-0.1257-0.1283-0.2961-0.2029-0.4756-0.2083v-1e-4h-2.2483c-0.1844 0-0.3611 0.0732-0.4915 0.2036l0.1414 0.1414-0.1414-0.1414c-0.1303 0.1303-0.2035 0.3071-0.2035 0.4914s0.0732 0.3611 0.2035 0.4914c0.1304 0.1304 0.3071 0.2036 0.4915 0.2036h0.6735c-0.4512 0.3604-0.9812 0.6135-1.5499 0.7368-0.7329 0.1589-1.4965 0.095-2.1928-0.1834-0.6962-0.2784-1.2933-0.7586-1.7146-1.379-0.4212-0.6203-0.6473-1.3523-0.6493-2.1021zm0 0v3e-4h-0.2l0.2-6e-4z"/>
        <path d="m15.219 20.06c-1e-4 -0.1842-0.0733-0.3609-0.2036-0.4912l-0.1414 0.1414 0.1414-0.1414c-0.1303-0.1303-0.3071-0.2035-0.4914-0.2035-0.1844 0-0.3611 0.0732-0.4915 0.2035-0.1303 0.1303-0.2035 0.3071-0.2035 0.4915v5e-4c0.0027 1.0057 0.2998 1.9886 0.8546 2.8274 0.5549 0.8387 1.3432 1.4967 2.2677 1.8927 0.9244 0.3959 1.9446 0.5126 2.9346 0.3355 0.8427-0.1508 1.6322-0.5088 2.2987-1.0388v0.4377c0 0.1843 0.0732 0.3611 0.2035 0.4914 0.1304 0.1303 0.3071 0.2036 0.4915 0.2036 0.1843 0 0.3611-0.0733 0.4914-0.2036s0.2036-0.3071 0.2036-0.4914v-2.2275l-1e-4 -2e-3c-0.0017-0.1795-0.0728-0.3515-0.1985-0.4797l-0.1429 0.14 0.1429-0.14c-0.1257-0.1283-0.2961-0.2029-0.4756-0.2083v-1e-4h-2.2483c-0.1844 0-0.3611 0.0732-0.4915 0.2036l0.1414 0.1414-0.1414-0.1414c-0.1303 0.1303-0.2035 0.3071-0.2035 0.4914s0.0732 0.3611 0.2035 0.4914c0.1304 0.1304 0.3071 0.2036 0.4915 0.2036h0.6735c-0.4512 0.3604-0.9812 0.6135-1.5499 0.7368-0.7329 0.1589-1.4965 0.095-2.1928-0.1834-0.6962-0.2784-1.2933-0.7586-1.7146-1.379-0.4212-0.6203-0.6473-1.3523-0.6493-2.1021zm0 0v3e-4h-0.2l0.2-6e-4z"/>
      </g>
      <g transform="rotate(180 19.101 20.06)"
        clipPath="url(#clip0_7759_25964-1)"
        strokeWidth=".4">
        <path d="m15.219 20.06c-1e-4 -0.1842-0.0733-0.3609-0.2036-0.4912l-0.1414 0.1414 0.1414-0.1414c-0.1303-0.1303-0.3071-0.2035-0.4914-0.2035-0.1844 0-0.3611 0.0732-0.4915 0.2035-0.1303 0.1303-0.2035 0.3071-0.2035 0.4915v5e-4c0.0027 1.0057 0.2998 1.9886 0.8546 2.8274 0.5549 0.8387 1.3432 1.4967 2.2677 1.8927 0.9244 0.3959 1.9446 0.5126 2.9346 0.3355 0.8427-0.1508 1.6322-0.5088 2.2987-1.0388v0.4377c0 0.1843 0.0732 0.3611 0.2035 0.4914 0.1304 0.1303 0.3071 0.2036 0.4915 0.2036 0.1843 0 0.3611-0.0733 0.4914-0.2036s0.2036-0.3071 0.2036-0.4914v-2.2275l-1e-4 -2e-3c-0.0017-0.1795-0.0728-0.3515-0.1985-0.4797l-0.1429 0.14 0.1429-0.14c-0.1257-0.1283-0.2961-0.2029-0.4756-0.2083v-1e-4h-2.2483c-0.1844 0-0.3611 0.0732-0.4915 0.2036l0.1414 0.1414-0.1414-0.1414c-0.1303 0.1303-0.2035 0.3071-0.2035 0.4914s0.0732 0.3611 0.2035 0.4914c0.1304 0.1304 0.3071 0.2036 0.4915 0.2036h0.6735c-0.4512 0.3604-0.9812 0.6135-1.5499 0.7368-0.7329 0.1589-1.4965 0.095-2.1928-0.1834-0.6962-0.2784-1.2933-0.7586-1.7146-1.379-0.4212-0.6203-0.6473-1.3523-0.6493-2.1021zm0 0v3e-4h-0.2l0.2-6e-4z"/>
        <path d="m15.219 20.06c-1e-4 -0.1842-0.0733-0.3609-0.2036-0.4912l-0.1414 0.1414 0.1414-0.1414c-0.1303-0.1303-0.3071-0.2035-0.4914-0.2035-0.1844 0-0.3611 0.0732-0.4915 0.2035-0.1303 0.1303-0.2035 0.3071-0.2035 0.4915v5e-4c0.0027 1.0057 0.2998 1.9886 0.8546 2.8274 0.5549 0.8387 1.3432 1.4967 2.2677 1.8927 0.9244 0.3959 1.9446 0.5126 2.9346 0.3355 0.8427-0.1508 1.6322-0.5088 2.2987-1.0388v0.4377c0 0.1843 0.0732 0.3611 0.2035 0.4914 0.1304 0.1303 0.3071 0.2036 0.4915 0.2036 0.1843 0 0.3611-0.0733 0.4914-0.2036s0.2036-0.3071 0.2036-0.4914v-2.2275l-1e-4 -2e-3c-0.0017-0.1795-0.0728-0.3515-0.1985-0.4797l-0.1429 0.14 0.1429-0.14c-0.1257-0.1283-0.2961-0.2029-0.4756-0.2083v-1e-4h-2.2483c-0.1844 0-0.3611 0.0732-0.4915 0.2036l0.1414 0.1414-0.1414-0.1414c-0.1303 0.1303-0.2035 0.3071-0.2035 0.4914s0.0732 0.3611 0.2035 0.4914c0.1304 0.1304 0.3071 0.2036 0.4915 0.2036h0.6735c-0.4512 0.3604-0.9812 0.6135-1.5499 0.7368-0.7329 0.1589-1.4965 0.095-2.1928-0.1834-0.6962-0.2784-1.2933-0.7586-1.7146-1.379-0.4212-0.6203-0.6473-1.3523-0.6493-2.1021zm0 0v3e-4h-0.2l0.2-6e-4z"/>
      </g>
      <defs>
        <clipPath id="clip0_7759_25964">
          <rect transform="translate(13.039 14.12)"
            width="11.88"
            height="11.88"/>
        </clipPath>
        <clipPath id="clip0_7759_25964-1">
          <rect transform="translate(13.039 14.12)"
            width="11.88"
            height="11.88"/>
        </clipPath>
      </defs>
    </Icon>
  );
};
