import {MouseEvent} from 'react';
import {Box, Button, ButtonProps, HStack, Tooltip} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

export const DateButton: React.FC<{
  date: Date,
  className?: string,
  borderColor?: ButtonProps['borderColor'],
  background?: ButtonProps['background'],
  variant?: ButtonProps['variant']
  isDisabled?: boolean,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
  panierEnCours?: boolean,
  commandeExistante?: boolean,
}> = ({
  date,
  className,
  borderColor = 'gray.light',
  background = 'trnsparent',
  variant = 'outline',
  isDisabled = false,
  onClick,
  panierEnCours,
  commandeExistante,
}) => {
  const {t} = useTranslation();
  return(
    <Button
      border='1px solid'
      fontSize={{base:'md', md:'lg'}}
      fontWeight='700'
      p={{base: 0, md: 'initial'}}
      w='100%'
      className={className}
      borderColor={borderColor}
      background={background}
      variant={variant}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {date.getUTCDate()}
      <HStack
        position="absolute"
        bottom="2px"
        left="50%"
        transform="translateX(-50%)"
        spacing={1}
      >
        {panierEnCours && (
          <Tooltip label={t('panier.panier_en_cours')}>
            <Box
              bg='orange.warning'
              borderRadius='50%'
              boxSize='2'
            />
          </Tooltip>
        )}
        {commandeExistante && (
          <Tooltip label={t('panier.commande_existant')}>
            <Box
              bg='green.success'
              borderRadius='50%'
              boxSize='2'
            />
          </Tooltip>
        )}
      </HStack>
    </Button>
  );
};
