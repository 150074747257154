import {Icon} from '@chakra-ui/react';

export const CommandesIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='white', width='21px', height='20px'}) => {
  return (
    <Icon
      width={width}
      height={height} 
      viewBox="0 0 25 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_6817_35801"
        fill="white">
        <path d="M20 8.50524C19.995 8.48138 19.995 8.4567 20 8.43285C19.9957 8.41196 19.9957 8.39039 20 8.3695V8.28806L19.9467 8.15232C19.9251 8.11529 19.8981 8.08174 19.8668 8.05278L19.7868 7.98039H19.7424L16.2427 5.72716L12.9385 3.64586C12.862 3.58408 12.7745 3.53799 12.6809 3.51013H12.6098C12.5304 3.49662 12.4494 3.49662 12.37 3.51013H12.2812C12.178 3.53337 12.0791 3.57308 11.9881 3.62776L5.35289 7.83561L5.27294 7.89895L5.193 7.97134L5.10418 8.03469L5.05977 8.08898L5.00647 8.22472V8.30616V8.36045C4.99784 8.42046 4.99784 8.48143 5.00647 8.54144V16.4413C5.00617 16.5951 5.04434 16.7464 5.11739 16.881C5.19043 17.0156 5.29594 17.129 5.42394 17.2105L12.0858 21.4093L12.219 21.4636H12.2901C12.4403 21.5121 12.6017 21.5121 12.7519 21.4636H12.823L12.9562 21.4093L19.5648 17.2738C19.6928 17.1923 19.7983 17.0789 19.8713 16.9444C19.9444 16.8098 19.9825 16.6584 19.9822 16.5047V8.60478C19.9822 8.60478 20 8.54144 20 8.50524ZM12.4588 5.47378L14.0399 6.46919L9.07462 9.60018L7.48467 8.60478L12.4588 5.47378ZM11.5706 19.0475L6.68525 16.007V10.2246L11.5706 13.3013V19.0475ZM12.4588 11.7086L10.7623 10.668L15.7276 7.52794L17.433 8.60478L12.4588 11.7086ZM18.2324 15.9798L13.3471 19.0746V13.3013L18.2324 10.2246V15.9798Z"/>
      </mask>
      <path d="M20 8.50524C19.995 8.48138 19.995 8.4567 20 8.43285C19.9957 8.41196 19.9957 8.39039 20 8.3695V8.28806L19.9467 8.15232C19.9251 8.11529 19.8981 8.08174 19.8668 8.05278L19.7868 7.98039H19.7424L16.2427 5.72716L12.9385 3.64586C12.862 3.58408 12.7745 3.53799 12.6809 3.51013H12.6098C12.5304 3.49662 12.4494 3.49662 12.37 3.51013H12.2812C12.178 3.53337 12.0791 3.57308 11.9881 3.62776L5.35289 7.83561L5.27294 7.89895L5.193 7.97134L5.10418 8.03469L5.05977 8.08898L5.00647 8.22472V8.30616V8.36045C4.99784 8.42046 4.99784 8.48143 5.00647 8.54144V16.4413C5.00617 16.5951 5.04434 16.7464 5.11739 16.881C5.19043 17.0156 5.29594 17.129 5.42394 17.2105L12.0858 21.4093L12.219 21.4636H12.2901C12.4403 21.5121 12.6017 21.5121 12.7519 21.4636H12.823L12.9562 21.4093L19.5648 17.2738C19.6928 17.1923 19.7983 17.0789 19.8713 16.9444C19.9444 16.8098 19.9825 16.6584 19.9822 16.5047V8.60478C19.9822 8.60478 20 8.54144 20 8.50524ZM12.4588 5.47378L14.0399 6.46919L9.07462 9.60018L7.48467 8.60478L12.4588 5.47378ZM11.5706 19.0475L6.68525 16.007V10.2246L11.5706 13.3013V19.0475ZM12.4588 11.7086L10.7623 10.668L15.7276 7.52794L17.433 8.60478L12.4588 11.7086ZM18.2324 15.9798L13.3471 19.0746V13.3013L18.2324 10.2246V15.9798Z"
        stroke="white"
        strokeWidth="0.2"
        mask="url(#path-1-inside-1_6817_35801)"/>
    </Icon>
  );
};    