import {TriangleDownIcon} from '@chakra-ui/icons';
import {Select, StyleProps} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {UniteQuantite} from 'api-types/ffconnect';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useGetSelectUniteOptions} from 'ff-common';
import {useTranslation} from 'react-i18next';

export interface SelectUniteProps {
  /**
   * Pour commander, tout part d'un item du catalogue.
   */
  itemCatalogue: State<ItemCatalogueDesc>,
  /**
   * Dans le panier, on peut vouloir partir de l'unité qui avait mise sur la ligne de panier.
   */
  value: UniteQuantite | undefined,
  quantite_max?: number | undefined
  onSelect: (unite: UniteQuantite) => void,
}

export const SelectUnite: React.FC<StyleProps & SelectUniteProps> = ({
  itemCatalogue,
  value,
  quantite_max,
  onSelect,
  ...props
}) => {
  const ic = itemCatalogue.data.tolede;
  const {t} = useTranslation();

  const {optionsList, isDisabled, commandableEnColis} = useGetSelectUniteOptions({ic, quantite_max, t});

  // utilisation direct de value mise à jour si préférences client sont présentes
  const unite = value ?? (commandableEnColis ? 'colis' : 'unitaire');

  // TODO TODO: gérer la préférence client, quand on arrive et qu'on a rien de défini !?

  return (
    <Select 
      bg='gray.bg' 
      borderColor={'#CDCFD7'} 
      icon={!isDisabled ? <TriangleDownIcon /> : <></>} 
      iconColor='gray.dark' 
      iconSize='xs' 
      w={{base: 'inherit', md: '200px'}}
      fontSize={{base: 'sm', md: 'md'}} 
      fontWeight={{base: '500', md: 'normal'}} 
      _disabled={{opacity:1}}
      value={unite} 
      onChange={(event) => {
        event.preventDefault();
        onSelect(event.target.value as UniteQuantite);
      }}
      isDisabled={isDisabled}
      {...props}
    >
      {optionsList.map((opt)=>{
        return <option key={opt.value}
          value={opt.value}>{opt.texte}</option>;
      })} 
    </Select>
  );
};
