import {Icon} from '@chakra-ui/react';

export const SearchIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='#232930', width='24px', height='24px'}) => {
  return (
    <Icon
      width={width}
      height={height} 
      viewBox="0 0 25 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8539 20.1504L21.8538 20.1504C22.0701 20.3742 22.191 20.6732 22.191 20.9844C22.191 21.2956 22.0701 21.5947 21.8538 21.8184L21.852 21.8203C21.7405 21.9327 21.6077 22.022 21.4615 22.0829C21.3153 22.1438 21.1584 22.1752 21 22.1752C20.8416 22.1752 20.6848 22.1438 20.5385 22.0829C20.3926 22.0221 20.26 21.933 20.1486 21.8208L21.8539 20.1504ZM21.8539 20.1504L21.8509 20.1474L18.2674 16.5929C19.664 14.78 20.3361 12.5094 20.1482 10.2243C19.9553 7.87934 18.8713 5.69739 17.119 4.12712C15.3668 2.55684 13.0795 1.7176 10.7275 1.78196C8.37546 1.84632 6.1375 2.80939 4.47375 4.47314C2.81 6.13689 1.84693 8.37485 1.78257 10.7269C1.71821 13.0789 2.55745 15.3662 4.12773 17.1184C5.698 18.8707 7.87995 19.9547 10.2249 20.1476C12.5102 20.3355 14.7811 19.6632 16.5941 18.2663L20.148 21.8203L21.8539 20.1504ZM11 17.7994C9.65511 17.7994 8.3404 17.4006 7.22215 16.6534C6.10389 15.9062 5.23232 14.8442 4.71765 13.6017C4.20297 12.3591 4.06831 10.9919 4.33069 9.6728C4.59307 8.35373 5.2407 7.14209 6.1917 6.19109C7.1427 5.24009 8.35434 4.59246 9.67341 4.33008C10.9925 4.0677 12.3597 4.20236 13.6023 4.71704C14.8448 5.23171 15.9068 6.10328 16.654 7.22154C17.4012 8.33979 17.8 9.6545 17.8 10.9994C17.8 12.8029 17.0836 14.5325 15.8084 15.8077C14.5331 17.083 12.8035 17.7994 11 17.7994Z"
        stroke="#232930"
        strokeWidth="0.4"/>
    </Icon>
  );
};