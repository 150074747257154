import {HStack, Text, VStack} from '@chakra-ui/layout';
import {useNavigate} from 'react-router-dom';
import {routes} from 'ff-common';
import {useTranslation} from 'react-i18next';
import {HaliRenderOne} from '@synako/haligator';
import {useClient} from '@/haligator-factory';
import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';

export const CardButtonListes: React.FC<{backgroundColor?: string, borderWidth?:string}> = ({
  backgroundColor = 'blue.main_light', borderWidth = '0px'
}) => {
  const navigate = useNavigate();
  const {buildAssistanceRoute} = useIsAssistanceMode();

  const client = useClient();

  const {t} = useTranslation('common', {keyPrefix: 'panier'});

  return (
    <VStack backgroundColor={backgroundColor}
      borderWidth={borderWidth}
      align="start"
      borderRadius="10px"
      p={7}
      spacing="10px"
      flex={1}
      cursor="pointer"
      _hover={{opacity: '0.8'}}
      onClick={()=>{navigate(buildAssistanceRoute(routes.client.listes_default));}}
      justifyContent="space-between">
      <VStack align="start"
        spacing="10px">
        <HStack spacing="13px">
          <Text fontWeight="600">{t('depuis_listes')}</Text>
          <RightSingleArrow />
        </HStack>
        <HaliRenderOne
          nav={client.follow('listes')}
          render={({data}) => (
            typeof data.total_items_count !== 'undefined' && (
              <Text fontWeight="400"
                color="gray.dark">
                {t('listes', {count: data.total_items_count})}
              </Text>
            )
          )}
        />
      </VStack>
    </VStack>
  );
};
