import {Button, ButtonProps} from '@chakra-ui/react';
import {ReactNode} from 'react';

interface BlueButtonProps extends ButtonProps {
  texte: ReactNode;
}

export const BlueButton: React.FC<BlueButtonProps> = ({texte, ...props}) => {
  return (
    <Button
      fontSize={{base:'md', md:'sm'}}
      bg="blue.main"
      color="white"
      px="8"
      h="52px"
      mt='20px'
      fontWeight="600"
      transition="all 0.2s ease-in-out"
      cursor='pointer'
      _hover={{bg: 'blue.dark', borderColor: 'white'}}
      {...props}
    >
      {texte}
    </Button>
  );
};
