import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Heading, Image, Text, VStack} from '@chakra-ui/react';
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useCategorieWeb} from 'ff-common';
import useBreadCrumbs from '@/hooks/catalogue/useBreadCrumbs';
import {useClient} from '@/haligator-factory';
import {HaliRenderOne} from '@synako/haligator';
import {getImageUrlOptimisee, getObjectPosition, getTextColor, getTextShadow} from '@/domains/mediatheque/imageMetier';
import {useTranslation} from 'react-i18next';

export const CatalogHeader: React.FC<{catWeb: string}> = ({catWeb}) => {
  // split pour récupérer l'image
  const idCategoriePrincipale = catWeb.split('-')[0];
  const navigate = useNavigate();
  const {t} = useTranslation();
  const client = useClient();
  const categorie = useCategorieWeb(catWeb);
  const crumbs = useBreadCrumbs(catWeb);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPromo = searchParams.get('from') === 'promo';
  const fromMP = searchParams.get('from') === 'mp';

  type CategorieId = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '170';

  // gestion des couleurs de fond de header
  const getCategoryInfo = (categorieP: string) => {
    const categories = {
      '1': {
        bgColor: 'categories.frais',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '2': {
        bgColor: 'categories.epicerie',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '3': {
        bgColor: 'categories.boissons',
        breadcrumbTextColor: 'white',
        headingTextColor: 'white'
      },
      '4': {
        bgColor: 'categories.frais',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '5': {
        bgColor: 'categories.produits_laitiers',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '6': {
        bgColor: 'categories.epicerie',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '7': {
        bgColor: 'categories.fruits_et_legumes',
        breadcrumbTextColor: 'white',
        headingTextColor: 'white'
      },
      '8': {
        bgColor: 'categories.surgeles',
        breadcrumbTextColor: 'white',
        headingTextColor: 'white'
      },
      '9': {
        bgColor: 'categories.epicerie',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '10': {
        bgColor: 'categories.non_alimentaire',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '11': {
        bgColor: 'categories.maree_fraiche',
        breadcrumbTextColor: 'white',
        headingTextColor: 'white'
      },
      '12': {
        bgColor: 'categories.frais',
        breadcrumbTextColor: 'gray.dark',
        headingTextColor: 'black.text'
      },
      '170': {
        bgColor: 'categories.boissons',
        breadcrumbTextColor: 'white',
        headingTextColor: 'white'
      },
    };

    type CategorieInfo = typeof categories[CategorieId];

    return (categories as Record<string, CategorieInfo>)[categorieP] ?? categories['5'];
  };

  const {bgColor, breadcrumbTextColor, headingTextColor} = getCategoryInfo(idCategoriePrincipale);

  return (
    <Box position="relative"
      overflow="hidden">
      <HStack bg={fromPromo || fromMP ? 'transparent' : bgColor}
        p={4}
        justify="space-between"
        position="relative"
        zIndex={1}>
        {/* Version desktop */}
        <>
          <VStack align="left"
            justifyContent="space-between"
            height="126px"
            paddingLeft='20px'
            display={{base:'none', md:'flex'}}>
            <Breadcrumb spacing='8px'
              separator={<ChevronRightIcon color={fromPromo || fromMP ? getTextColor() : breadcrumbTextColor}
                opacity="0.8"/>}>
              {crumbs.map((crumb, index) => (
                <BreadcrumbItem key={index}>
                  <BreadcrumbLink as={Link}
                    to={crumb.link}
                    opacity={fromPromo || fromMP ? '1' : '0.8'}
                    fontWeight={index === crumbs.length - 1 ? '700' : '400'} 
                    fontSize="sm"
                    lineHeight="17px"
                    color={fromPromo || fromMP ? getTextColor() : breadcrumbTextColor}
                    textShadow={fromPromo || fromMP ? getTextShadow() : 'none'}>
                    {crumb.name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ))}
              {fromPromo && <BreadcrumbItem><Text fontWeight='700'
                fontSize="sm"
                color={getTextColor()}
                textShadow={getTextShadow()}>{t('catalogue.les_promotions')}</Text></BreadcrumbItem>}
              {fromMP && <BreadcrumbItem><Text fontWeight='700'
                fontSize="sm"
                color={getTextColor()}
                textShadow={getTextShadow()}>{t('catalogue.les_indispensables')}</Text></BreadcrumbItem>}
            </Breadcrumb>
            <Heading as="h2"
              fontSize="3xl"
              fontWeight="700"
              color={headingTextColor}
              mr="4">
              {categorie?.libelle}
            </Heading>
          </VStack>
          <Box width="447px"
            height="143px"
            position="relative"
            flex="1"
            display={{base:'none', md:'flex'}}
            justifyContent="flex-end">
            {(!fromPromo && !fromMP) && (
              <Image onError={(e)=>e.currentTarget.src=`${import.meta.env.BASE_URL}/categories/unknown.webp`}
                src={`${import.meta.env.BASE_URL}/categories/${idCategoriePrincipale}.webp`}
                maxWidth="100%"
                maxHeight="100%"
                objectFit="contain" />
            )}
          </Box>
        </>
        {/* Version Mobile */}
        <HStack display={{base:'flex', md:'none'}}
          onClick={() => {navigate(-1);}}
          cursor='pointer'>
          <ChevronLeftIcon boxSize="25px"
            color={fromPromo || fromMP ? getTextColor() : breadcrumbTextColor}/>
          <Heading as="h5"
            fontSize="lg"
            fontWeight="700"
            color={breadcrumbTextColor}>
            {categorie?.libelle}
          </Heading>
        </HStack>
      </HStack>
      {(fromPromo || fromMP) && (
        <HaliRenderOne
          nav={client}
          render={({data}) => {
            const metier = data.tolede.metier;
            const objectPosition = getObjectPosition(metier);
            const imageWidth = 1200;
            return (
              <>
                <Image position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  src={getImageUrlOptimisee(metier, imageWidth)}
                  alt='Image métier'
                  objectFit='cover'
                  objectPosition={objectPosition}
                  zIndex={0} />
                <Box position="absolute"
                  width="150%"
                  height="60px"
                  bg={fromPromo ? 'red.500' : 'blue.main'}
                  top="50%"
                  left="75%"
                  transform="translate(-50%, -50%) rotate(-35deg)"
                  zIndex={2}>
                  <Box position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    transform="skew(-15deg)">
                    <Text color='white'
                      fontSize={{base: '2xs', md: 'xl'}}
                      fontWeight="700"
                      letterSpacing="wider"
                      textTransform="uppercase"
                      whiteSpace="nowrap"
                      transform="skew(15deg)">
                      {fromPromo ? t('catalogue.promotions') : t('catalogue.indispensables')}
                    </Text>
                  </Box>
                </Box>
              </>
            );
          }}
        />
      )}
    </Box>
  );
};
