import {Box, HStack, Text, Tooltip, VStack} from '@chakra-ui/react';
import {CartIcon} from '../../svg/CartIcon';
import React from 'react';
import {toDecimalFr} from 'ff-common';
import {Link} from 'react-router-dom';
import {dateEnTexte, usePanier} from 'ff-common';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {useHalState} from '@synako/haligator';

export const AffichagePanierNavTemplate: React.FC = () => {
  const {data: panierData} = useHalState(usePanier());

  const nombre_de_lignes = panierData?.nombre_de_lignes ?? 0;
  const total_estime_ht = panierData?.total_estime_ht ?? 0;

  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();

  return (
    <Tooltip label={t('panier.menu_tooltip', {date: dateEnTexte(panierData?.livraison_tolede.date_de_livraison)})}>
      <Link to={buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison: panierData?.livraison_tolede.date_de_livraison})}>
        <HStack 
          _hover={{bg: 'gray.light'}} 
          cursor='pointer' 
          borderRadius={{base: '0', md: '0 50px 50px 0'}}
          height='100%' 
          w='min-content' 
          alignItems='center' 
          px={{base: '1', md: '3'}}
        >
          <Box position='relative'>
            <CartIcon />
            {nombre_de_lignes > 0 && (
              <VStack 
                justify='center' 
                position='absolute' 
                top='0.5' 
                right='-2.5'
                bg='blue.main' 
                textColor='white' 
                borderRadius='50%' 
                boxSize='3.5' 
                fontSize='xs'
              >            
                <Box as='span'>{nombre_de_lignes}</Box>
              </VStack>
            )}
          </Box>
          <Text color='blue.main'
            fontWeight='bold'
            fontSize={{base: 'sm', md: ''}}
            m='1'>{toDecimalFr(total_estime_ht)}&nbsp;€</Text>
        </HStack>
      </Link>
    </Tooltip>
  )
  
  ;
};
