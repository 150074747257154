import {useLivraisonsPossiblesContext} from 'ff-common';
import {useClient} from '@/haligator-factory';
import {dateEnTexte} from 'ff-common';
import {Text, VStack} from '@chakra-ui/layout';
import {Tooltip} from '@chakra-ui/react';
import {HaliRenderMany} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';

export const ProduitDejaCommande: React.FC<{
  itemState: State<ItemCatalogueDesc>,
  // date_de_livraison: string,
}> = ({
  itemState,
  // date_de_livraison,
}) => {
  const client = useClient();
  const {t} = useTranslation();
  const {date_de_livraison} = useLivraisonsPossiblesContext();

  // console.log('RERENDER : ProduitDejaCommande '+ itemState.data.tolede.code_article);
  
  return(
    <HaliRenderMany
      many={client.follow('commande_statistiques', {date_de_livraison}).followAll('items')}
      render={({states: statsArticles}) => {
        const articleTrouve = statsArticles.find((article) => article.data.id === itemState.data.tolede.code_article);
        if(articleTrouve){
          return (
            <VStack justifyContent='end'
              w='100%'
              alignItems='end'
              mr='50px'
              pr='10px'>
              {(articleTrouve.data).tolede.stats_par_livraison.map((stat, index) => (
                <Tooltip
                  key={index}
                  label={t('commande.pour_livraison_du', {date: dateEnTexte(date_de_livraison)})}
                >
                  <Text color='blue.main'
                    fontSize='sm'>
                    {t('commande.deja_commande')} {stat.quantite} {t(`unite.${itemState.data.tolede.unite_de_commande_client}`, {count: stat.quantite})}
                  </Text>
                </Tooltip>
              ))}
            </VStack>
          );
        }
        return null;
      }}
      fallback={() => null}
      skeleton={() => null}
    />
  );
};
