
import {SwitchAccountIcon} from '@/components/svg/SwitchAccountIcon';
import {useMe} from '@/haligator-factory';
import {routes} from 'ff-common';
import {Box, Button, Divider, Heading, VStack} from '@chakra-ui/react';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {Link, Outlet, useLocation} from 'react-router-dom';
import {ChevronLeftIcon} from '@chakra-ui/icons';
import NiceModal from '@ebay/nice-modal-react';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {MenuClientMobileModale} from './MenuClientMobileModale';
import {MenuEspaceClient, MenuItems} from './MenuItem';

export const SidebarMenu: React.FC = () => {
  const location = useLocation();
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();

  const contact = useMe().follow('contact');
  const contacts = useHalState(useMe().follow('switch'));

  const handleDrawerClick = () => {
    NiceModal.show(MenuClientMobileModale);
  };

  return (<Box display="flex"
    bg="gray.bg"
    overflowX="hidden">
    {/* Version desktop */}
    <Box display={{base:'none', md:'block'}}>
      <Box p={5}
        minWidth="327px"
        bg="white"
        borderRight="1px"
        borderColor="gray.200"
        height="100%"
        minHeight='600px'
        pb={300}>
        <HaliRenderOne
          nav={contact}
          render={({data}) => (
            <Heading size="md"
              color="blue.main"
              mt="40px"
              ml="50px"
              mb="360px">{t('accueil.bonjour', {nom:data.tolede.prenom})}</Heading>
          )}
          notFound={() => (
            <Heading size="md"
              color="blue.main"
              mt="40px"
              ml="50px"
              mb="360px">{t('accueil.bonjour', {nom:''})}</Heading>
          )}
          fallback={() => (
            <Heading size="md"
              color="blue.main"
              mt="40px"
              ml="50px"
              mb="360px">{t('accueil.bonjour', {nom:''})}</Heading>
          )}
        />
        <VStack alignItems="flex-start"
          position="absolute"
          top="120"
          left="50"
          zIndex="1"
          bg="white"
          paddingY={4}>
          {MenuItems.map((item) => (
            <MenuEspaceClient
              key={item.to}
              to={buildAssistanceRoute(routes.client[item.to])}
              Icon={item.Icon}
              label={t(item.label)}
              isActive={location.pathname === routes.client[item.to].path}
            />
          ))}
          <Divider/>
          {(contacts.data?.items.length ?? 0) > 1 && (
            <Link to={routes.client.clientSelect.path}
              style={{width: '100%'}}>
              <Button leftIcon={<SwitchAccountIcon/>}
                variant="ghost"
                justifyContent="flex-start"
                color="black.ff"
                mt="15px"
                fontWeight="500"
                width="100%"
                _hover= {{bg: 'blue.main_transparency_10'}}>
                {t('menu.changer_de_client')}
              </Button>
            </Link>
          )}
          <Button as='a'
            href='/authn/logout'
            variant="outline"
            color="gray.dark"
            mt="20px"
            ml={46}>
            {t('menu.deconnecter')}
          </Button>
        </VStack>
      </Box>
    </Box>

    {/* Version mobile */}
    <Button display={{base:'inline-flex', md:'none'}} 
      onClick={handleDrawerClick}
      variant="ghost" 
      position="absolute"
      top="23px"
      left={{base:'10px', md:'37px'}}
      _hover={{background: 'transparent'}}
      p={0}>
      <ChevronLeftIcon color="black"
        width="37px"
        height="37px"/>
    </Button>
    
    <Outlet />
  </Box>
  );
};

export default SidebarMenu;
