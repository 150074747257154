export const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none">
      <path d="M7.16667 11.5H10.8333V9.66667H7.16667V11.5ZM0.75 0.5V2.33333H17.25V0.5H0.75ZM3.5 6.91667H14.5V5.08333H3.5V6.91667Z"
        fill="#565D79"/>
    </svg>
  );
};
