import React from 'react';
import {Box, Checkbox, Tag, Text} from '@chakra-ui/react';
import {HStack} from '@chakra-ui/layout';
import {TemperatureDeLivraison} from 'api-types/ffconnect';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import NiceModal from '@ebay/nice-modal-react';
import {dateEnTexte, useLivraisonsPossiblesContext} from 'ff-common';
import {BlueButton} from '@/components/Button/BlueButton';
import {IconeTemperatureLivraison} from '@/components/IconeTemperatureLivraison';
import {useTranslation} from 'react-i18next';

interface FrozenToggleProps {
  temperature_de_livraison: TemperatureDeLivraison;
  isChecked?: boolean;
  onChange?: () => void;
}

// Composant pour afficher l'option surgelé dans le cadencier
export const FrozenToggle: React.FC<FrozenToggleProps> = ({ 
  temperature_de_livraison,
  isChecked = false, 
  onChange,
}) => {
  const {t} = useTranslation('common');
  
  if (temperature_de_livraison === 'les_deux') return null;

  // Gère le changement d'état
  const handleToggle = () => {
    if (isChecked) {
      NiceModal.show(ModaleWarning, {temperature_de_livraison});
    }
    onChange?.();
  };

  return (
    <Box
      p={{base:'0 10px 0 10px', md:'0 0 10px 10px'}}
    >
      <Tag
        size='md'
        cursor="pointer"
        border='solid 1px'
        borderColor={isChecked ? 'black.text' : 'gray.light'}
        bg='white'
        color={'black.text'}
        _hover={{bg: 'gray.dark', color:'white'}}
        gap={2}
        userSelect="none"
      >
        <Checkbox
          isChecked={isChecked}
          onChange={handleToggle}
          colorScheme="blue"
          p={2}
        >
          <HStack>

            <Text fontSize="sm"
              fontWeight="medium">
              {temperature_de_livraison === 'negatif' 
                ? t('temperature_livraison.surgeles_uniquement') 
                : t('temperature_livraison.non_surgeles_uniquement')}
            </Text>
            <HStack
              backgroundColor="cyan.surgele"
              borderRadius={14}
              pr={2}
              flex={1}
            >
              <IconeTemperatureLivraison temperature_tournee={temperature_de_livraison}
                boxSize={6}/>

              <Text fontSize="sm"
                fontWeight="medium"
                color="white"
                py={1}>
                {temperature_de_livraison === 'negatif' 
                  ? t('temperature_livraison.livraison_surgelee') 
                  : t('temperature_livraison.pas_de_surgele')}
              </Text>
            </HStack>

          </HStack>
        </Checkbox>

      </Tag>
    </Box>
  );
};

const ModaleWarning = NiceModal.create<{temperature_de_livraison:TemperatureDeLivraison}>(({temperature_de_livraison}) => {
  const modal = NiceModal.useModal();
  const {date_de_livraison} = useLivraisonsPossiblesContext();
  const {t} = useTranslation('common');

  return (
    <ModaleLayout 
      visible={modal.visible}
      hide={modal.remove} 
      headerIcon={<IconeTemperatureLivraison temperature_tournee={temperature_de_livraison}
        boxSize={8}/>}
      titre={temperature_de_livraison==='negatif' 
        ? t('temperature_livraison.livraison_surgeles_uniquement') 
        : t('temperature_livraison.livraison_non_surgeles_uniquement')}
    >
      <Text mb={4}>
        {t('temperature_livraison.la_livraison_du')}
        <Text as="span"
          fontWeight="bold">
          {` ${dateEnTexte(date_de_livraison)} `}
        </Text>
        {temperature_de_livraison==='negatif' 
          ? t('temperature_livraison.est_livraison_surgeles_uniquement')
          : t('temperature_livraison.est_livraison_non_surgeles_uniquement')}
      </Text>         

      <BlueButton
        colorScheme="blue"
        variant='outline'
        size="md"
        width="full"
        onClick={modal.remove}
        texte={t('global.jai_compris')}
      />
    </ModaleLayout>
  );
});