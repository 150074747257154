import {Text, VStack} from '@chakra-ui/react';
import {DotLoaderSlow} from '../Loading/DotLoaderSlow';

export const Chargement: React.FC<{texte: string}> = ({texte}) => {
  return (
    <VStack bg='white'
      p='20px'
      pt='30px'
      minH='100vh'>
      <Text fontSize="lg"
        fontWeight="medium">{texte}</Text>
      <DotLoaderSlow />
    </VStack>
  );
};
