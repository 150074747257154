import {Button} from '@chakra-ui/react';
import {Link, useLocation} from 'react-router-dom';
import {ContactsIcon} from '@/components/svg/ContactsIcon';
import {FactureIcon} from '@/components/svg/FactureIcon';
import {CommandesIcon} from '@/components/svg/CommandesIcon';
import {SettingsIcon} from '@/components/svg/SettingsIcon';
import {BonsLivraisonsIcon} from '@/components/svg/BonsLivraisonsIcon';
import {UserIcon} from '@/components/svg/UserIcon';

interface IconProps {
  color?: string;
  width?: string;
  height?: string;
}

interface MenuItemProps {
  to: string;
  Icon: React.ComponentType<IconProps>;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  isMobile?: boolean;
}

export const MenuEspaceClient: React.FC<MenuItemProps> = ({to, Icon, label, isActive, onClick, isMobile = false}) => {
  const location = useLocation();
  const isCurrentlyActive = isActive ?? location.pathname === to;

  const content = (
    <Button
      leftIcon={
        <Icon
          width="25px"
          height="25px"
          color={isCurrentlyActive ? 'blue.main' : isMobile ? 'black' : 'dark'}
        />
      }
      variant="ghost"
      justifyContent="flex-start"
      sx={
        isCurrentlyActive && !isMobile
          ? {color: 'blue.main', backgroundColor: 'blue.main_transparency_10'}
          : {color: 'black.ff'}
      }
      pl={3}
      _hover={isMobile ? {background: 'transparent'} : {bg: 'blue.main_transparency_10'}}
      width="100%"
      fontWeight="500"
      onClick={onClick}
      color={isMobile ? 'black.text' : undefined}
      ml={isMobile ? -4 : undefined}
    >
      {label}
    </Button>
  );

  // en mobile avec onClick pour fermer le menu mobile après la navigation
  if (isMobile) {
    return (
      <Link to={to}
        style={{width: '100%'}}
        onClick={onClick}>
        {content}
      </Link>
    );
  }

  // en desktop 
  return (
    <Link to={to}
      style={{width: '100%'}}>
      {content}
    </Link>
  );
};

export const MenuItems = [
  {to: 'profil', Icon: UserIcon, label: 'menu.informations'},
  {to: 'commandes', Icon: CommandesIcon, label: 'menu.commandes'},
  {to: 'livraisons', Icon: BonsLivraisonsIcon, label: 'menu.bons_de_livraison'},
  {to: 'factures', Icon: FactureIcon, label: 'menu.factures_et_avoirs'},
  {to: 'contacts', Icon: ContactsIcon, label: 'menu.contacts'},
  {to: 'preferences', Icon: SettingsIcon, label: 'menu.preferences'},
] as const;
