import React from 'react';
import {Box, Spinner, UseRadioProps, useRadio} from '@chakra-ui/react';

interface RadioCardProps extends UseRadioProps {
  children: React.ReactNode;
  loading?: boolean;
}

export const RadioCard: React.FC<RadioCardProps> = (props) => {
  const {getInputProps, getRadioProps} = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        boxShadow='md'
        fontSize='xs'
        _checked={{bg: 'blue.main', color: 'white', borderColor: 'blue.main',}}
        _hover={{bg: 'blue.dark', color: 'white'}}
        px={2}
        py={2}
        position="relative"
      >
        {props.children}
        {props.loading && (
          <Spinner
            size="sm"
            position="absolute"
            right={2}
            top="50%"
            transform="translateY(-50%)"
          />
        )}
      </Box>
    </Box>
  );
};
