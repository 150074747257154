import {Box, HStack, Image, VStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {ArticleDesc, ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useHalState} from '@synako/haligator';
import {imageOptimiseeH} from './optimisation';
import {useTranslation} from 'react-i18next';
import {Text} from '@chakra-ui/layout';

export const ImagePrincipaleDuProduit: React.FC<{articleState: State<ArticleDesc>, itemState:State<ItemCatalogueDesc>,}>
= ({articleState, itemState}) => {
  const {t} = useTranslation('common');
  const imagePrincipale = useHalState(articleState.follow('medias').follow('image_principale'));
  const natureDuPrix = itemState.data.tolede.prix_par_livraison?.[0]?.type;

  return(
    <Box position="relative"
      p={{base: '10px 20px 0px 20px', md: ''}}>
      {/* Version mobile */}
      <VStack mt='10px'
        display={{base:'flex', md:'none'}}>
        {natureDuPrix === 'marche' &&
        <HStack position="absolute"
          top='10px'
          left="30px"
          bg="blue.main_light"
          color="blue.main"
          fontSize="xs"
          fontWeight="bold"
          py="2px"
          px="8px"
          borderRadius="md">
          <Text>{t('global.produit_marche')}</Text>
        </HStack>
        }
        <Box maxHeight="274px">
          <Image src={imageOptimiseeH(imagePrincipale.data?.url, 274)}
            alt=""
            objectFit="cover"
            width="100%"
            maxHeight="274px"/>
        </Box>
      </VStack>
      {/* Version desktop */}
      <Box m="20px"
        display={{base:'none', md:'block'}}>
        <Box position="relative">
          {natureDuPrix === 'marche' &&
          <HStack position="absolute"
            top='0px'
            left="10px"
            bg="blue.main_light"
            color="blue.main"
            fontSize="xs"
            fontWeight="bold"
            py="2px"
            px="8px"
            borderRadius="md">
            <Text>{t('global.produit_marche')}</Text>
          </HStack>
          }
          <HStack height="372px"
            width='100%'
            justifyContent='center'>
            <Image src={imageOptimiseeH(imagePrincipale.data?.url, 372)}
              alt=""
              objectFit="contain"
              maxH="372px" />
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
