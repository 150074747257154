import {Box, Table, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {ArticleDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';

export const ProduitTable: React.FC<{articleState: State<ArticleDesc>}> = ({articleState}) => {
  const {t} = useTranslation();
  
  const allergenes = [
    ...(
      articleState.data.tolede.allergenes
        ?.filter(allergene => allergene.niveau_de_contenance !== 'FREE_FROM')
        .map(allergene => allergene.libelle)
        ?? []
    )
  ];

  const typesDeNutrimentsStates = articleState.data.tolede.type_de_nutriments;

  return(
    <Box m="20px"
      mb="50px"
      p={{base: '10px 20px 0px 20px', md:''}}>
      <>
        <Text fontSize="xl"
          fontWeight="bold"
          mb={4}>
          {t('fiche_produit.table.ingredient_nutrition')}
        </Text>
        {typesDeNutrimentsStates && typesDeNutrimentsStates.length > 0 ? (
          <Table variant="unstyled">
            <Thead bg="blue.main"
              borderTopRadius="8px">
              <Tr color="white.100">
                <Th
                  color="white.100"
                  textTransform="capitalize"
                  textAlign="left"
                  fontSize={{base: '2xs', md: 'xs'}}
                  p={{base: 2, md: 4}}
                >
                  {t('fiche_produit.table.informations_nutritionelles')}
                </Th>
                <Th
                  color="white.100"
                  textTransform="capitalize"
                  width="110px"
                  textAlign="center"
                  fontSize={{base: '2xs', md :'xs'}}
                  p={{base:2, md:4}}
                  isNumeric
                  whiteSpace='nowrap'
                >
                  {t('fiche_produit.table.pour100gml')}
                </Th>
                <Th
                  color="white.100"
                  textTransform="capitalize"
                  textAlign="center"
                  fontSize={{base: '2xs', md: 'xs'}}
                  p={{base: 2, md: 4}}
                >
                  {t('fiche_produit.table.ajr')}
                </Th>
              </Tr>
            </Thead>
            <Tbody borderTopLeftRadius="8px">
              {typesDeNutrimentsStates.map((typeDeNutriment, index) => (
                <Tr
                  key={index}
                  fontSize={{base: 'xs', md: 'sm'}}
                  bg={index % 2 === 0 ? 'white' : 'gray.light'}
                >
                  <Td p={{base: 2, md: 4}}
                    textAlign="left">
                    {typeDeNutriment.libelle}
                  </Td>
                  <Td p={{base: 2, md: 4}}
                    textAlign="center"
                    isNumeric>
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    {typeDeNutriment.quantite}g
                  </Td>
                  <Td p={{base: 2, md: 4}}
                    textAlign="center"
                    isNumeric></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) :
          <Table variant="unstyled">
            <Thead bg="blue.main"
              borderTopRadius="8px">
              <Tr color="white.100">
                <Th
                  color="white.100"
                  textAlign="left"
                  fontSize={{base: '2xs', md: 'xs'}}
                  p={{base: 2, md: 4}}
                >
                  {t('fiche_produit.table.ingredients_manquants')}
                </Th>
              </Tr>
            </Thead>
          </Table>
        }
      </>
      {allergenes.length > 0 &&
      <Text fontSize="sm"
        fontWeight="400"
        mt="25px"
        pb='20px'>
        <Text as="span"
          fontWeight="700">{t('fiche_produit.table.allergenes')}</Text> {allergenes.join(', ')}
      </Text>}
    </Box>
  );
};
