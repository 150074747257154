import {ExternalLinkIcon} from '@chakra-ui/icons';
import {Box, Button, HStack, Text} from '@chakra-ui/react';
import {Link, useParams} from 'react-router-dom';
import {useApi} from '@/haligator-factory';
import {HaliRenderOne} from '@synako/haligator';
import {buildRoute} from '@synako/enhanced-router';
import {routes} from 'ff-common';
import {useTranslation} from 'react-i18next';

export const AssistanceClientBanniere: React.FC = () => {
  const {id_client_assistance} = useParams<{id_client_assistance?: string}>();
  const client = useApi().follow('get-client', {client_id:id_client_assistance});
  const societe = client.follow('societe_commerciale');

  const {t} = useTranslation();

  return (
    <Box bg="red.200"
      color="white"
      w='100%'
      p='5px 10px 5px 10px'
      position="sticky"
      top={0}
      height='50px'
      zIndex='sticky'>
      <HStack justifyContent="space-between"
        alignItems="center">
        <HaliRenderOne
          nav={client}
          render={({data: clientData}) => {
            return (
              <Text color='white'>{t('assistance.banniere', {raison_sociale:clientData.tolede.raison_sociale})}</Text>
            );
          }}
        />
        <HaliRenderOne
          nav={societe}
          render={({data: societeData}) => {
            if (!societeData.id || !id_client_assistance) {
              return null;
            }
            return (
              <Link
                to={buildRoute(routes.client.backOfficeSocieteCommercialeClientDetail, {
                  id_societe_commerciale: societeData.id,
                  id_client: id_client_assistance
                })}
              >
                <Button variant="unstyled"
                  color="white"
                  display="flex"
                  alignItems="center"
                  _hover={{textDecoration: 'underline'}} >
                  <ExternalLinkIcon mr={2} />
                  <Text>{t('global.revenir')}</Text>
                </Button>
              </Link>
            );
          }}
        />
      </HStack>
    </Box>
  );
};
