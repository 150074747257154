import {ChangerDateModale} from '@/components/Modale/CalendarModale/ChangerDateModale';
// import {useLivraisonsPossiblesContext} from 'ff-common';
import {AddIcon} from '@chakra-ui/icons';
import {HStack, Text} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {useTranslation} from 'react-i18next';

export const NewLivraisonButtonMobile: React.FC = () => {
  // const {livraisonActuelleAll} = useLivraisonsPossiblesContext();
  const {t} = useTranslation();
  return (<HStack as={Button}
    h='100%'
    borderRadius='8px'
    p='3'
    border='1px solid'
    borderColor='gray.dark'
    bg='white'
    userSelect='none'
    onClick={()=>{
      NiceModal.show(ChangerDateModale);
    }}
  >
    <AddIcon color='gray.dark' /><Text p='1.5'
      textColor='gray.dark'>{t('panier.liste_livraisons_prompt_mobile')}</Text>
  </HStack>);
};
