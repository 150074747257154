import {useEffect, useState} from 'react';
import {useSearchClient} from './use-search';
import {TemperatureDeLivraison} from 'api-types/ffconnect';

export const usePromoMpSearch = ({temperature_de_livraison}:{temperature_de_livraison:TemperatureDeLivraison|undefined}) => {
  const search = useSearchClient();
  const [itemResultsPromo, setItemResultsPromo] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [itemResultsMP, setItemResultsMP] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [isLoading, setIsLoading] = useState(true);
  
  //Si on n'a pas pu respecter le temperature de livraison de la tournée, on l'indique à l'utilisateur
  const [temperatureTourneeIgnoree, setTemperatureTourneeIgnoree] = useState(false);

  const temperature_livraison = temperature_de_livraison !== 'les_deux' ? temperature_de_livraison : undefined;

  useEffect(() => {
    if (search.indexIsBuilt) {
      setIsLoading(true);
      Promise.all([
        search.searchItems({nature_du_prix: 'promo'}),
        search.searchItems({est_MP: true, temperature_livraison}),
        search.searchItems({est_MP: true}),
      ])
        .then(([promoResults, mpResults, mpResultsAll]) => {
          setItemResultsPromo(promoResults);
          if (!temperature_livraison)
            setItemResultsMP(mpResults);
          else {
            if (mpResults.hits.length === 0) {
              setTemperatureTourneeIgnoree(true);
              setItemResultsMP(mpResultsAll);
            } else {
              setItemResultsMP(mpResults);
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.indexIsBuilt]);

  const promoDispo = itemResultsPromo?.hits && itemResultsPromo.hits.length > 0;

  return {
    itemResultsPromo,
    itemResultsMP,
    isLoading,
    promoDispo,
    temperatureTourneeIgnoree
  };
};
