import React from 'react';
import {Circle, HStack} from '@chakra-ui/react';
import {keyframes} from '@emotion/react';

export const DotLoaderSlow: React.FC = () => {
  const pulseKeyframe = keyframes`
  0% { transform: scale(0.5); opacity: 0.5; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.5); opacity: 0.5; }
  `;
  const pulseAnimation = `${pulseKeyframe} 1.5s ease-in-out infinite`;
  return (<HStack spacing={3}>
    {[0, 1, 2].map(index => <Circle key={index}
      size="12px"
      bg="gray.300"
      animation={pulseAnimation}
      sx={{
        animation: pulseAnimation,
        animationDelay: `${index * 0.3}s`
      }} />)}
  </HStack>);
};
