import {isDateEqual} from 'ff-common';
import {Grid, GridItem, HStack, Text} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {useCallback} from 'react';
import {CalendarCellInactive} from './components/CalendarCellInactive';
import {CalendarCellPanier} from './components/CalendarCellPanier';
import {useHalStates} from '@synako/haligator';
import {useLivraisons} from '@/haligator-factory';

export const CalendarGrid: React.FC<{
  selectedLivraison: State<LivraisonDesc>,
  onDateClick: (livraison: State<LivraisonDesc>) => void,
  monthOffset:number,
  dateDebut: Date | undefined
}> = ({
  selectedLivraison,
  onDateClick, 
  monthOffset, 
  dateDebut
}) => {
  const generateDateGrid = useCallback((selectedDate: Date) => {
    const selectedMonth = selectedDate.getMonth() + monthOffset;
    const selectedYear = selectedDate.getUTCFullYear();
    //On n'affiche automatiquement pas les dates du mois suivant, ainsi on peut se permettre de récupérer plus de dates que nécessaire
    const daysInMonth = 31;
    const startDate = new Date(selectedYear, selectedMonth, 1);
    const startDay = startDate.getDay();
    const dateGrid: Date[] = [];

    for (let i = 0; i <= daysInMonth + startDay; i++) {
      const currentDate = new Date(selectedYear, selectedMonth, i - startDay + 2);
      
      dateGrid.push(currentDate);
    }

    return dateGrid;
  }, [monthOffset]);

  const selectedDate = new Date(selectedLivraison.data.tolede.date_de_livraison);
  
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  return (
    <Grid 
      w='100%' 
      templateColumns="repeat(7, 1fr)" 
      gap='2' 
      columnGap={{base: '0', md: '3'}} 
      mt={{base:'5', md:'7'}} 
      justifyItems='stretch' 
      alignItems='stretch'
    >
      {['D', 'L', 'M', 'M', 'J', 'V', 'S'].map((day, index) => (
        <GridItem 
          as={HStack} 
          key={index} 
          alignItems='stretch' 
          justify='center' 
          paddingBottom={2} 
          color='#9E9E9E'
        >
          <Text>{day}</Text>
        </GridItem>
      ))}
      {
        generateDateGrid(selectedDate).map((d, idx) =>{
          // date notée en UTC mais avec la timezone appliquée 
          // -> décalage de 1 ou 2h si le système est en Europe/Paris
          const date =  new Date(d.getTime() + d.getTimezoneOffset());
          const isSelected = isDateEqual(date, selectedDate);
          const livraisonAssociee = livraisonsPossibles.states?.find((livraison) => {
            const dateLivraison = new Date(livraison.data.tolede.date_de_livraison);
            if (dateDebut && dateLivraison < dateDebut) {
              return false;
            }
            return isDateEqual(dateLivraison, date); 
          });

          const offsetDate = new Date(selectedDate);
          //Si la date selectionnée est > 28, Février est sauté par la prochaine opération
          offsetDate.setUTCDate(1); 
          offsetDate.setUTCMonth(offsetDate.getUTCMonth()+monthOffset);
          const offsetMonth = offsetDate.getUTCMonth();
          const dateMonth = date.getUTCMonth();

          if (dateMonth <  offsetMonth || date.getUTCFullYear() < offsetDate.getUTCFullYear()) {
            return <GridItem key={idx}></GridItem>;
          }
          if (dateMonth > offsetMonth) return null;
          if (livraisonAssociee) { // la date sélectionnée correspond à une livraison
            return (
              <CalendarCellPanier
                key={idx}
                date={date}
                isSelected={isSelected}
                livraisonAssociee={livraisonAssociee}
                onDateClick={onDateClick}
              />
            );
          } else { // date non sélectionnable
            return <CalendarCellInactive key={idx}
              date={date} />;
          }
        })}
    </Grid>
  );
};
