import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon';
import {ShippingCartIcon} from '@/components/svg/ShippingCartIcon';
import {Box, Button, HStack, Image, Spacer, Stack, Text, VStack} from '@chakra-ui/react';
import {HaliRenderOne, useHalStates} from '@synako/haligator';
import {Link, useParams} from 'react-router-dom';
import {useClient, useLivraisons, useMe} from '@/haligator-factory';
import {routes} from 'ff-common';
import {CatalogueViewAccueil} from './components/CatalogueViewAccueil';
import {CommandesIcon} from '@/components/svg/CommandesIcon';
import {FactureIcon} from '@/components/svg/FactureIcon';
import {CardButtonCadencier} from '@/domains/panier/components/CardButtonCadencier';
import {Metier} from 'api-types/ffconnect';
import {CommandeDateTexte} from './components/CommandeDateTexte';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {getImageUrlOptimisee, getObjectPosition, getTextColor, getTextShadow} from '@/domains/mediatheque/imageMetier';
import {FollowPromiseOne} from '@synako/halitrek';
import {ClientDesc} from 'api-types/ffconnect-hal';
import {ListePaniersEnCours} from './components/ListePaniersEnCours';
import {CardButtonListes} from '../panier/components/CardButtonListes';

const DeliveryInfo: React.FC = () => {
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  const laProchaine = livraisonsPossibles.states?.[0];
  if (!laProchaine) {
    return;
  }
  
  return (
    <HStack
      color="blue.main"
      bg='blue.main_transparency_10'
      py="12px"
      px="14px"
      borderRadius='8px'
    >
      <ShippingCartIcon color="blue.main" />
      <CommandeDateTexte date_de_livraison={laProchaine.data.tolede.date_de_livraison}
        color="blue.main"
        fontSize="xs" />
    </HStack>
  );
};

export const AccueilView: React.FC = () => {
  const me = useMe();
  const {t} = useTranslation();

  const client = useClient();
  
  const {id_client_assistance} = useParams();
  const isAssistanceMode = !!id_client_assistance;
  
  return (
    <VStack width="100%">
      <Box width="100%"
        zIndex={0}>
        <MetierImage client={client} />
        <Box ml={{base:'5%', lg:'152px'}}
          mr={{base:'5%', lg:'152px'}}>
          <Box mt="48px"
            mb="43px"
            fontWeight="700"
            fontSize={{base:'3xl', md:'4xl'}}
            color={getTextColor()}
            textShadow={getTextShadow()}
            position="relative">
            {isAssistanceMode ? (
              t('accueil.bonjour', {nom: ''})
            ) : (
              <HaliRenderOne
                nav={me.follow('contact')}
                render={({data}) => (
                  <>{t('accueil.bonjour', {nom: data.tolede.prenom})}</>
                )}
                fallback={() => <></>}
              />
            )}
          </Box>
          <Box position="relative"
            flex={1}>
            <Stack direction={{base:'column', lg:'row'}}
              spacing="30px"
              mb="30px" >
              <Box bg="white"
                p="30px"
                pr={{base:'19px', md:'34px'}}
                pl={{base:'19px', md:'34px'}}
                borderRadius="8px"
                width={{base:'100%', xl:'50%'}}
                flex={1}
                boxShadow={'md'}>
                <VStack align="start"
                  spacing="20px"
                  height='100%'>
                  <Stack direction={{base:'column', xl:'row'}}
                    width="100%" >
                    <Text fontSize={{base:'lg', md:'xl'}}
                      fontWeight="700">
                      {t('catalogue.commander_maintenant')}
                    </Text>
                    <Spacer />
                    <Box>
                      <DeliveryInfo />
                    </Box>
                  </Stack>
                  <Stack direction={{base:'column', xl:'row'}}
                    spacing="20px"
                    width="100%"
                    alignItems="stretch"
                    height='100%'>
                    <CardButtonCadencier width='11em' />
                    <CardButtonListes/>
                  </Stack>
                </VStack>
              </Box>
              <ListePaniersEnCours afficheInfo={true}
                maxPaniersAffiches={2}/>
            </Stack>
          </Box>
        </Box>
        <Box zIndex={0}
          bg="white">
          <CatalogueViewAccueil/>
        </Box>
      </Box>
    </VStack>
  );
};

const MetierImage: React.FC<{client: FollowPromiseOne<ClientDesc>}> = ({client}) => {
  return (
    <HaliRenderOne
      nav={client}
      render={({data}) => {
        const metier: Metier = data.tolede.metier;
        const objectPosition = getObjectPosition(metier);
        const imageWidth = 1200;
        return (
          <Image
            src={getImageUrlOptimisee(metier, imageWidth)}
            alt='Image métier'
            width='100%'
            height={{base: '750px', xl: '700px'}}
            objectFit='cover'
            position={{base: 'absolute', lg: 'fixed'}}
            objectPosition={objectPosition}
            zIndex={-1}
          />
        );
      }}
      fallback={() => <Box width="100%"
        height={{base: '750px', xl: '700px'}}
        bg="gray.100" />}
    />
  );
};

export const MonCompte: React.FC = () => {
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  return(
    <Box bg="white"
      p="30px"
      pr={{base:'19px', md:'34px'}}
      pl={{base:'19px', md:'34px'}}
      borderRadius="8px"
      boxShadow={'md'}
      width={{base:'100%', xl:'30%'}}
      flex={0.5}>
      <Text fontSize="xl"
        fontWeight="700"
        mb="22px">
        {t('menu.compte')}
      </Text>
      <VStack spacing={{base:'13px', lg:'34px', xl:'13'}}>
        <Box width="100%">
          <Link to={buildAssistanceRoute(routes.client.factures)}>
            <HStack borderWidth="1px"
              width="100%"
              borderRadius="8px"
              pr="20px"
              spacing="0"
              cursor="pointer"
              pt={{lg:'40px', xl:'0'}}
              pb={{lg:'40px', xl:'0'}}>
              <Box bg='blue.main_transparency_10'
                borderRadius="8px"
                p="6px"
                ml="10px"
                mt="10px"
                mb="10px"
                mr="5px">
                <FactureIcon color='blue.main'
                  width='25px'
                  height='25px'/>
              </Box>
              <Button color="black"
                bg="white"
                pl="5px"
                flex="1"
                justifyContent="flex-start"
                _hover={{bg:'white'}}
                fontSize={{base:'sm', md:'md'}}>
                {t('menu.factures_et_avoirs')}
              </Button>
              <RightSingleArrow />
            </HStack>
          </Link>
        </Box>
        <Box width="100%">
          <Link to={buildAssistanceRoute(routes.client.commandes)}>
            <HStack borderWidth="1px"
              borderRadius="8px"
              pr="20px"
              spacing="0"
              cursor="pointer"
              pt={{lg:'40px', xl:'0'}}
              pb={{lg:'40px', xl:'0'}}>
              <Box bg='blue.main_transparency_10'
                borderRadius="8px"
                p="6px"
                ml="10px"
                mt="10px"
                mb="10px"
                mr="5px">
                <CommandesIcon color='blue.main'
                  height='25px'
                  width='25px'/>
              </Box>
              <Button color="black"
                bg="white"
                pl="5px"
                flex="1"
                justifyContent="flex-start"
                _hover={{bg:'white'}}
                fontSize={{base:'sm', md:'md'}}>
                {t('menu.commandes')}
              </Button>
              <RightSingleArrow />
            </HStack>
          </Link>
        </Box>
      </VStack>
    </Box>
  );
};
