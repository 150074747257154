import {TemperatureDeLivraison} from 'api-types/ffconnect';
import {ReactElement} from 'react';
import ColdIcon from './svg/ColdIcon';
import NotColdIcon from './svg/NotColdIcon';

export const IconeTemperatureLivraison:React.FC<{
  temperature_tournee: TemperatureDeLivraison | undefined,
  fallback?: ReactElement,
  boxSize?:number,
}> = ({
  temperature_tournee,
  fallback=null,
  boxSize=25
})=>{
  switch (temperature_tournee) {
    case 'negatif':
      return <ColdIcon boxSize={boxSize}/>;
    case 'positif':
      return <NotColdIcon boxSize={boxSize}/>;
    default:
      return fallback;
  }
};