import {Box, HStack, Text} from '@chakra-ui/layout';
import {useTranslation} from 'react-i18next';

export const CadencierTitreNombreDeProduits: React.FC<{nombreDeProduits: number}> = ({nombreDeProduits}) => {
  const {t} = useTranslation();
  return(
    <HStack alignItems='center'
      px={{base: '5', lg: '0'}}
      py={{base: '5', lg: '0'}}>
      <Text fontSize='xl'
        fontWeight='700'>{t('cadencier.mon_cadencier')}</Text>
      <Box textColor='gray.dark'
        fontWeight='600'
        fontSize='xs'
        bg='gray.light'
        p='1'
        borderRadius='8px'>{t('global.produit', {count:nombreDeProduits})}</Box>
    </HStack>
  );
};
