import {LigneDePanierCommands} from 'ff-common/src/hooks/ligneDePanier/use-ligne-de-panier-commands';
import {HStack, Link, Text, Tooltip} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {LigneProblem} from 'api-types/ffconnect';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {Trans, useTranslation} from 'react-i18next';

export const Warning: React.FC<{
  idError: string,
  ligneDePanier: State<LigneDePanierDesc>,
  probleme: LigneProblem,
  commands: LigneDePanierCommands,
}> = ({
  idError,
  ligneDePanier,
  probleme,
  commands,
}) => {
  const {t} = useTranslation();
  const {somethingSubmitting, ajuster} = commands;
  const errorCode = probleme.problem.code;
  const quantite = ligneDePanier.data.quantite;
  const uniteCommande = ligneDePanier.data.unite_de_commande_client;
  const quantiteMax = probleme.problem.quantite_max ?? 1;
  let message;
  let command;
  switch (errorCode) {
    case 'rupture':
      message = <Trans t={t}>{t('ligne_produit.indisponible.rupture', {
        quantite, 
        unite: t(`unite.${uniteCommande}`, {count: quantite})
      })}</Trans>;
      break;
    case 'quantite_trop_importante':
      message = <Trans t={t}>
        {t('ligne_produit.indisponible.rupture_partielle', {
          quantite_max: `${quantiteMax} ${t(`unite.${uniteCommande}`, {count: quantiteMax})}`,
          quantite_demande: quantite,
          count: quantiteMax
        })}
      </Trans>;
      command = <Link
        opacity={somethingSubmitting ? 0.3 : 1} 
        textDecorationLine='underline' 
        onClick={()=>{
          ajuster.submit({
            quantite: quantiteMax,
          });
        }}
      >
        {t('ligne_produit.indisponible.ajuster_quantite', {
          quantite: quantiteMax,
          unite:  t(`unite.${uniteCommande}`, {count: quantiteMax})
        })}
      </Link>;
      break;
    case 'article_inconnu':
    case 'article_obsolete':
    case 'non_commercialise':
    case 'non_autorise':
    case 'non_web':
    case 'erreur_temperature_tournee':
    case 'erreur_multiple_de_commande':
    case 'heure_depassee':
    case 'generique': // A priori en dessous c'est pour la commande
    case 'adresse_inconnue':
    case 'client_bloque':
    case 'client_inconnu':
    case 'delai_de_prepa_long':
    case 'erreur_code_tournee':
    case 'erreur_date_livraison':
    case 'erreur_mode_de_rendu':
    case 'erreur_nb_lignes':
    case 'franco_poids_prix_non_atteint':
    case 'reference':
      // noop c'est pour la commande
      message = <Tooltip label={`${probleme.problem.message} (${probleme.problem.code})`}
        placement='top'>
        <Text>{t('ligne_produit.indisponible.generique')}</Text>
      </Tooltip>;
      break;
  }
  return (
    <HStack 
      textColor='yellow.warning_text' 
      bg='yellow.warning' 
      py='1' 
      px='5' 
      mt='2' 
      w={{base: 'auto', md: '100%'}}
    >
      <Text>{message}</Text>
      {command ?? <Link 
        textDecorationLine='underline' 
        onClick={()=>{
          const element = document.querySelector(`#${idError}`);
          if (element) {
            element.scrollIntoView({behavior:'smooth', block:'center'});
          }
        }}
      >
        {t('global.voir')}
      </Link>}
    </HStack>
  );
};
