import {Icon} from '@chakra-ui/react';

export const TrashIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='blue.100', width='18', height='18'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill={color}
    >
      <path d="M13.1667 4.0013H17.3334V5.66797H15.6667V16.5013C15.6667 16.7223 15.579 16.9343 15.4227 17.0906C15.2664 17.2468 15.0544 17.3346 14.8334 17.3346H3.16675C2.94573 17.3346 2.73377 17.2468 2.57749 17.0906C2.42121 16.9343 2.33341 16.7223 2.33341 16.5013V5.66797H0.666748V4.0013H4.83342V1.5013C4.83342 1.28029 4.92121 1.06833 5.07749 0.912046C5.23377 0.755766 5.44573 0.667969 5.66675 0.667969H12.3334C12.5544 0.667969 12.7664 0.755766 12.9227 0.912046C13.079 1.06833 13.1667 1.28029 13.1667 1.5013V4.0013ZM14.0001 5.66797H4.00008V15.668H14.0001V5.66797ZM6.50008 8.16797H8.16675V13.168H6.50008V8.16797ZM9.83342 8.16797H11.5001V13.168H9.83342V8.16797ZM6.50008 2.33464V4.0013H11.5001V2.33464H6.50008Z"
        fill="currentColor"/>
    </Icon>
  );
};
