export const VoirIcon = () => {
  return (
    <svg width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1134 8.23268C13.7667 5.10602 11.2334 3.16602 8.50004 3.16602C5.76671 3.16602 3.23338 5.10602 1.88671 8.23268C1.85 8.31679 1.83105 8.40758 1.83105 8.49935C1.83105 8.59112 1.85 8.6819 1.88671 8.76602C3.23338 11.8927 5.76671 13.8327 8.50004 13.8327C11.2334 13.8327 13.7667 11.8927 15.1134 8.76602C15.1501 8.6819 15.169 8.59112 15.169 8.49935C15.169 8.40758 15.1501 8.31679 15.1134 8.23268ZM8.50004 12.4993C6.38671 12.4993 4.38671 10.9727 3.23338 8.49935C4.38671 6.02602 6.38671 4.49935 8.50004 4.49935C10.6134 4.49935 12.6134 6.02602 13.7667 8.49935C12.6134 10.9727 10.6134 12.4993 8.50004 12.4993ZM8.50004 5.83268C7.97263 5.83268 7.45706 5.98908 7.01852 6.2821C6.57999 6.57511 6.2382 6.99159 6.03637 7.47886C5.83453 7.96613 5.78172 8.50231 5.88462 9.01959C5.98751 9.53687 6.24149 10.012 6.61443 10.385C6.98737 10.7579 7.46252 11.0119 7.9798 11.1148C8.49709 11.2177 9.03326 11.1649 9.52053 10.963C10.0078 10.7612 10.4243 10.4194 10.7173 9.98087C11.0103 9.54234 11.1667 9.02677 11.1667 8.49935C11.1667 7.7921 10.8858 7.11383 10.3857 6.61373C9.88557 6.11363 9.20729 5.83268 8.50004 5.83268ZM8.50004 9.83268C8.23634 9.83268 7.97855 9.75448 7.75928 9.60797C7.54002 9.46147 7.36912 9.25323 7.26821 9.00959C7.16729 8.76596 7.14088 8.49787 7.19233 8.23923C7.24378 7.98059 7.37077 7.74301 7.55724 7.55654C7.74371 7.37007 7.98128 7.24308 8.23992 7.19163C8.49857 7.14019 8.76665 7.16659 9.01029 7.26751C9.25392 7.36843 9.46216 7.53932 9.60867 7.75859C9.75518 7.97785 9.83338 8.23564 9.83338 8.49935C9.83338 8.85297 9.6929 9.19211 9.44285 9.44216C9.1928 9.69221 8.85367 9.83268 8.50004 9.83268Z"
        fill="#202125"/>
    </svg>
  );
};    