import {Box, Text, VStack} from '@chakra-ui/react';
import {TFunction} from 'i18next';
import {CardButtonCadencier} from '../components/CardButtonCadencier';
import {CardButtonListes} from '../components/CardButtonListes';

export const Right404: React.FC<{t: TFunction}> = ({t}) => {
  return (
    <VStack alignItems={{base:'stretch', md:'start'}}
      p='6'
      width={{base:'95%', md:'40%'}}>
      <Text
        as='h2'
        fontSize={{base:'xl', md:'3xl'}}
        fontWeight='700'
        w={{base:'100%', md:'initial'}}
        marginBottom={10}
      >
        {t('panier.panier_vide_cta')}
      </Text>
      <Box width="100%"
        display="flex"
        flexDirection="column"
        gap="20px">
        <CardButtonCadencier width={{base:'11em', md:'7em', lg:'11em'}}
          backgroundColor='white'
          borderWidth='1px'/>
        <CardButtonListes backgroundColor='white'
          borderWidth='1px'/>
      </Box>
    </VStack>
  );
};
