import posthogJs from 'posthog-js';

export function initPosthog() {
  if (import.meta.env.VITE_POSTHOG_API_KEY) {
    return posthogJs.init(import.meta.env.VITE_POSTHOG_API_KEY, {
      person_profiles: 'identified_only',
      api_host: 'https://eu.i.posthog.com',
      verbose: true,
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: true,
      capture_heatmaps: true,
      rageclick: true,
    });
  }
  // Note : le usePosthog() supporte bien le fait de ne pas être initialisé.
  console.log('Posthog non configuré (VITE_POSTHOG_API_KEY)');
  return;
}