import {useCommand} from '@synako/haligator';
import {FollowPromiseOne} from '@synako/halitrek';
import {MeDesc} from 'api-types/ffconnect-hal';
import {t} from 'i18next';
import {Dispatch, useEffect, useState} from 'react';
import {TextToastCommand} from '../../types/TextToastCommand';

export const CONFIG = {
  // intervalle de verification de la session
  checkInterval: 30 * 60 * 1000, // 30 minutes en millisecondes
  warningTime: 30 * 60,          // 30 minutes en secondes
};

export const useCheckSessionWarning = (
  meNav: FollowPromiseOne<MeDesc>,
  redirectToLogout:()=>void,
  showWarning: boolean,
  setShowWarning: Dispatch<boolean>,
  toast: TextToastCommand,
) => {
  const [countdown, setCountdown] = useState<number>(0); // In minutes

  const {isAvailable, submit: submitExtendSession, isSubmitting} = useCommand(meNav, 'c:extend-session', {
    onSuccess: async () => {
      setShowWarning(false);
      toast(t('toast.prolonge_session'));
    },
    onError: (error) => {
      console.error('Error extending session:', error);
      toast(t('toast.prolongement_session_impossible', {icon: 'alert'}));
      redirectToLogout();
    }
  });

  useEffect(() => {
    // Regularly check if session is about to expire
    const checkSessionWarning = async () => {
      try {
        const me = await meNav.refresh();

        if (me.data.expires_at) {
          const expiresIn = new Date(me.data.expires_at).getTime() - Date.now();
          if (expiresIn <= 0) {
            redirectToLogout();
          }
          else if (expiresIn <= CONFIG.warningTime * 1000) {
            setShowWarning(true);
            setCountdown(Math.ceil(expiresIn / (60 * 1000)));
          } else {
            setShowWarning(false);
          }
        }
      } catch (error) {
        console.error('Error checking session:', error);
        redirectToLogout();
      }
    };
    
    const intervalId = setInterval(checkSessionWarning, CONFIG.checkInterval);

    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meNav]);

  // Effect to manage countdown in alert message
  useEffect(() => {
    if (!showWarning) return;
    const timer = setInterval(() => {
      setCountdown(prev => {
        const newValue = Math.max(0, prev - 1);
        return newValue;
      });
    }, 15000);

    return () => {clearInterval(timer);};
  }, [showWarning]);

  return {countdown, isAvailable, submitExtendSession, isSubmitting};
};