import {Box, HStack, Text, VStack} from '@chakra-ui/react';
import {HaliRenderMany, HaliRenderOne} from '@synako/haligator';
import {Trans, useTranslation} from 'react-i18next';
import {FollowPromiseMany, FollowPromiseOne} from '@synako/halitrek';
import {AdresseDeFacturationDesc, AdresseDeLivraisonDesc, TourneeDesc} from 'api-types/ffconnect-hal';
import {dateEnTexte, guessTitreAdresseDeLivraison} from 'ff-common';

interface AddressesclientProps {
  adresseFacturation: FollowPromiseOne<AdresseDeFacturationDesc>,
  adressesLivraison: FollowPromiseMany<AdresseDeLivraisonDesc>,
  tournees: FollowPromiseMany<TourneeDesc>,
}

export const Addressesclient: React.FC<AddressesclientProps> = ({adresseFacturation, adressesLivraison, tournees}) => {
  const {t} = useTranslation();

  return (
    <VStack align='stretch'
      spacing='4'>
      <Box>
        <Text fontWeight='bold'>{t('mes_informations.adresses_de_facturation')}</Text>
        <VStack bg='white'
          p='20px'
          borderRadius='8px'
          border='1px solid'
          borderColor='gray.light'
          w='100%'
          mt='5px'
          justifyContent='start'
          alignItems='start'
          spacing='0'>
          <HaliRenderOne
            nav={adresseFacturation}
            render={({data: adresseFacturationData}) => (
              <>
                <Text>{adresseFacturationData.tolede.ligne1}</Text>
                <Text>{adresseFacturationData.tolede.ligne2}</Text>
                <Text>{adresseFacturationData.tolede.code_postal} {adresseFacturationData.tolede.ville}</Text>
              </>
            )}
            fallback={() => null}
          />
        </VStack>
      </Box>
      <HaliRenderMany
        many={tournees}
        render={({data: tourneesData}) => {
          return(
            <Box>
              <Text fontWeight='bold'>{t('mes_informations.les_tournees')}</Text>
              <VStack align='stretch'
                spacing='2'>
                <VStack bg='white'
                  p='20px'
                  borderRadius='8px'
                  border='1px solid'
                  borderColor='gray.light'
                  w='100%'
                  mt='5px'
                  justifyContent='start'
                  alignItems='start'>
                  {tourneesData.map((tournee) => {
                    return(
                      <Box key={tournee.id}>
                        <Text>
                          <Trans t={t}>{t(tournee.tolede.mode_de_rendu === 'enlevement' ? 'mes_informations.tournee_enlevement' : 'mes_informations.tournee_livrante', {numero: tournee.tolede.code_tournee})}</Trans>
                        </Text>
                        {tournee.tolede.planning &&
                        <Text>
                          <Trans t={t}>{t('back_office.prochaine_date_livraison', {date_de_livraison: dateEnTexte(tournee.tolede.planning[0].date_de_livraison)})}</Trans>
                        </Text>}
                        {tournee.tolede.mode_de_rendu === 'enlevement' &&
                          <Box pt='5px'>
                            <HStack>
                              <Text fontWeight='bold'>{t('back_office.adresse_depot')}</Text>
                            </HStack>
                            <VStack spacing='0'
                              justifyContent='start'
                              alignItems='start'>
                              <Text>{tournee.tolede.adresse_d_enlevement?.ligne1}</Text>
                              <Text>{tournee.tolede.adresse_d_enlevement?.ligne2}</Text>
                              <Text>{tournee.tolede.adresse_d_enlevement?.code_postal} {tournee.tolede.adresse_d_enlevement?.ville}</Text>
                            </VStack>
                          </Box>
                        }
                      </Box>
                    );})}
                </VStack>
              </VStack>
            </Box>
          );
        }}
        fallback={() => null}
      />
      <Box>
        <Text fontWeight='bold'>{t('mes_informations.adresses_de_livraison')}</Text>
        <VStack align='stretch'
          spacing='2'>
          <VStack bg='white'
            p='20px'
            borderRadius='8px'
            border='1px solid'
            borderColor='gray.light'
            w='100%'
            mt='5px'
            justifyContent='start'
            alignItems='start'>
            <VStack spacing='0'
              justifyContent='start'
              alignItems='start'>
              <HaliRenderMany
                many={adressesLivraison}
                render={({data: adressesLivraisonData}) => (
                  <>
                    {adressesLivraisonData[0] ? adressesLivraisonData.map((adresse) => 
                    {
                      const {nom_client, enseigne} = guessTitreAdresseDeLivraison(adresse.tolede);
                      return (
                        <Box key={adresse.id}>
                          {enseigne && <Text fontWeight="500">{enseigne}</Text>}
                          {nom_client && <Text fontWeight="500">{nom_client}</Text>}
                          <Text>{adresse.tolede.adresse.ligne1}</Text>
                          <Text>{adresse.tolede.adresse.ligne2}</Text>
                          <Text>{adresse.tolede.adresse.code_postal} {adresse.tolede.adresse.ville}</Text>
                        </Box>
                      );
                    }) : (
                      <Text>{t('back_office.pas_de_contact')}</Text>
                    )}
                  </>
                )}
              />
            </VStack>
          </VStack>
        </VStack>
      </Box>
    </VStack>
  );
};
