import {HStack, Text, Tooltip} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {InfoIcon} from '@chakra-ui/icons';

export const PoidsVariableTooltip: React.FC = () => {
  const {t} = useTranslation();
  return <Tooltip label={t('ligne_produit.poids_variable_label')}>
    <HStack color='gray.dark'
      mt='2'>
      <Text>{t('ligne_produit.poids_variable')}</Text><InfoIcon />
    </HStack>
  </Tooltip>;
};
