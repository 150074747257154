import React from 'react';
import {Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {TextProps} from '@chakra-ui/layout';

interface PrixTagProps {
  typePrix?: string;
  position?: 'absolute' | 'relative';
  styleProps?: TextProps;
}

export const PrixTag: React.FC<PrixTagProps> = ({
  typePrix,
  position = 'relative',
  styleProps = {},
}) => {
  const {t} = useTranslation();

  if (typePrix === 'promo') {
    return (
      <Text
        bg="red.500"
        color="white"
        fontSize="xs"
        fontWeight="bold"
        py="2px"
        px="8px"
        borderRadius="md"
        position={position}
        {...styleProps}
      >
        {t('global.promotion')}
      </Text>
    );
  }

  return null;
};
