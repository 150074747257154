import {State} from '@synako/halitrek';
import {MinusIcon} from '@chakra-ui/icons';
import {Button, Tooltip} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {LigneDePanierCommands} from 'ff-common/src/hooks/ligneDePanier/use-ligne-de-panier-commands';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';

export const AjusterErrorButton: React.FC<{
  ligneDePanier: State<LigneDePanierDesc>,
  commands: LigneDePanierCommands,
  quantiteMaximale?:  number | undefined,
  onClose?: () => void,
  submittingOrRefreshing?: boolean,
}> = ({
  ligneDePanier,
  commands,
  quantiteMaximale,
  onClose,
  submittingOrRefreshing
}) => {
  const {t} = useTranslation();
  const {somethingSubmitting, changerLaQuantite} = commands;
  const quantiteMax = quantiteMaximale ?? 0;
  const uniteCommande = ligneDePanier.data.unite_de_commande_client;
  const handleClick = async () => {
    changerLaQuantite.submit({quantite: quantiteMax});
    onClose?.();
  };
  return (
    <Tooltip 
      label={t('ligne_produit.indisponible.tooltip_ajuster_quantite', {
        quantite: quantiteMax, 
        unite: t(`unite.${uniteCommande}`, {count: quantiteMax})})}
    >
      <Button 
        colorScheme='blue' 
        isDisabled={somethingSubmitting || submittingOrRefreshing}
        onClick={handleClick}
      ><MinusIcon /></Button>
    </Tooltip>
  );
};
