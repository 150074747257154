import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useCallback, useEffect, useState} from 'react';
import {useDebouncedCallback} from './useDebouncedCallback';

export const useBuyProductInputCommand = (
  itemCatalogue:State<ItemCatalogueDesc>,
  commandeEnColis:boolean,
  colisEntier:number,
  quantite:number, 
  onQuantite:(quantite: number) => void
) => {
  const [debouncedQuantite, setDebouncedQuantite] = useState(quantite);
  const step = commandeEnColis ? colisEntier : itemCatalogue.data.tolede.multiple_de_commande_client;

  useEffect(()=>{
    //Reflète les changement fait à l'exterieur du composant (switch unite, ligne lors d'une précommande)
    setDebouncedQuantite(quantite);
  }, [quantite]);
  
  const debouncedPush = useDebouncedCallback((nouvelleQuantite : number) => {
    onQuantite(nouvelleQuantite);
  }, 300);
  const diminuerLaQuantite = useCallback(() => {
    //Le minimum est toujours 0
    //Permet d'éviter le négatif lors d'une erreur de multiple
    const nouvelleQuantite = Math.max(0, debouncedQuantite - step); 
    setDebouncedQuantite(nouvelleQuantite);
    debouncedPush(nouvelleQuantite);
  }, [debouncedPush, debouncedQuantite, step]);
  const augmenterLaQuantite = useCallback(() => {
    setDebouncedQuantite(debouncedQuantite + step);
    debouncedPush(debouncedQuantite + step);
  }, [debouncedPush, debouncedQuantite, step]);

  return {diminuerLaQuantite, augmenterLaQuantite, debouncedQuantite, setDebouncedQuantite};
};