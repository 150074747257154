import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {toDecimalFr} from '../utils/toDecimalFr';
import {State} from '@synako/halitrek';
import {useTranslation} from 'react-i18next';

export const useBoutonAchatQuantiteDisplay = (
  {
    quantite,
    commandeEnColis,
    itemCatalogue
  }: {
    quantite: number; commandeEnColis: boolean;
    itemCatalogue:State<ItemCatalogueDesc>;
  }) => {
  const {
    quantite_colis_entier,
    coefficient_commande_client_vers_facturation,
    unite_de_commande_client,
    unite_de_facturation
  } = itemCatalogue.data.tolede;
  const {t} = useTranslation();
  const quantiteFacturation = quantite * (coefficient_commande_client_vers_facturation ?? 1);

  const quantite_text = !commandeEnColis
    ? (unite_de_commande_client === unite_de_facturation
      ? <>{toDecimalFr(quantite, 0, 3)}&nbsp;{t(`unite.${unite_de_facturation}`, {count: quantite})}</>
      : <>
        {toDecimalFr(quantite, 0, 3)}&nbsp;
        {t(`unite.${unite_de_commande_client}`, {count: quantite})} (
        {toDecimalFr(quantiteFacturation, 0, 3)}
        &nbsp;{t(`unite.${unite_de_facturation}`, {count: quantiteFacturation})})
      </>)
    : <>{t('global.colis', {
      // Permet de faire le calcul un peu en avance de phase par rapport à ce qui va être fait en back par le switch
      count: quantite < (quantite_colis_entier ?? 1) ? 1 : Math.round(quantite / (quantite_colis_entier ?? 1))
    })} (
    {toDecimalFr(quantite, 0, 3)}&nbsp;
    {t(`unite.${unite_de_commande_client}`, {count: quantite})})
    </>;
  return {quantite_text};
};
