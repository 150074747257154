import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {fr as common} from './common/fr';

export const resources = {
  fr: {
    common,
  }
} as const;

export const i18nConfig = (locale: string) => {
  i18n
    .use(initReactI18next)
    .init({
      defaultNS: 'common',
      ns: ['common'],
      resources,
      lng: locale,
      fallbackLng: 'fr',
      interpolation: {
        escapeValue: false 
      },
    });
};
