import {Box, Button, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, HStack, Image, Input, InputGroup, InputLeftElement, SimpleGrid, Skeleton, Stack, Text, VStack} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import React, {CSSProperties, useContext, useEffect, useRef, useState} from 'react';
import {Results} from '@orama/orama';
import {Article, HitItemCatalogue} from 'api-types/ffconnect';
import {Trans, useTranslation} from 'react-i18next';
import {DisplayLabels} from './DisplayLabels';
import {SwitchIcon} from '@/components/svg/SwitchIcon';
import {SearchIcon} from '@chakra-ui/icons';
import {CatalogFilter} from '@/domains/catalogue/components/CatalogFilter/CatalogFilter';
import {
  coerceFiltresItemCatalogue as coerce, toDecimalFr,
  useGetSelectUniteOptions, useLivraisonsPossiblesContext,
  useSearchClient
} from 'ff-common';
import {useDebouncedChange, useTypedFilters} from '@synako/typed-path';
import {useApi, useClient} from '@/haligator-factory';
import {RemplacerUnArticleCommand} from 'api-types/ffconnect';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {LabelDisponibilite} from '@/domains/catalogue/components/LabelDisponibilite';
import {differenceInDays} from 'date-fns/differenceInDays';
import {ColdIcon} from '@/components/svg/ColdIcon';
import {PromoIcon} from '@/components/svg/PromoIcon';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {Link} from 'react-router-dom';
import {FiltresItemCatalogue} from 'ff-common/src/hooks/search/use-search';
import range from 'lodash-es/range';
import {FixedSizeGrid} from 'react-window';
import {useScrollbarWidth} from '@/chakra/useScrollbarWidth';
import {BreakPointContext} from '@/chakra/BreakpointProvider';

export const ModaleRemplacementCatalogue = NiceModal.create<{
  articleData: Article,
  remplacerSubmit: (payload: RemplacerUnArticleCommand)=> void,
}>(({
      articleData,
      remplacerSubmit,
    }) => {
      const modal = useModal();
      const {t} = useTranslation();

      const search = useSearchClient();
      const [remplacements, setRemplacements] = useState<Results<HitItemCatalogue>|undefined>(undefined);

      const filtersHook = useTypedFilters<FiltresItemCatalogue>({q: ''}, coerce);
      const debouncedChange = useDebouncedChange(filtersHook, 'q', 300);
      const [hits, setHits] = useState<Results<HitItemCatalogue>|undefined>(undefined);

      const {date_de_livraison} = useLivraisonsPossiblesContext();
      const diff = differenceInDays(date_de_livraison, new Date());
      const ApourB = diff <= 1;

      // Cela laisse le temps à la transition de se faire
      const [goRender, setGoRender] = useState(false);
      useEffect(() => {
        if(modal.visible) {
          setTimeout(() => {
            setGoRender(true);
          }, 600);
        }
      }, [modal.visible]);

      useEffect(() => {
        if(goRender) {
          if (filtersHook.params.nature_du_prix ??
              filtersHook.params.labels?.length ??
              filtersHook.params.temperature_livraison ??
              filtersHook.params.q) {
            // Si des filtres sont actifs, on fait une recherche normale
            search.searchItems({
              nature_du_prix: filtersHook.params.nature_du_prix,
              labels: filtersHook.params.labels,
              temperature_livraison: filtersHook.params.temperature_livraison,
              q: filtersHook.params.q,
              precommande: ApourB ? false : undefined,
            }).then(setHits);
          } else {
            // Si aucun filtre n'est actif, on revient aux articles de remplacement
            search.remplacementPour(
              articleData,
              {
                enleverPrecommande: ApourB,
              }
            ).then(setRemplacements);
            setHits(undefined);
          }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [filtersHook.params, goRender]);

      useEffect(() => {
        if(goRender) {
          if (!filtersHook.params.q) {
            setHits(undefined);
            return;
          }
          search.searchItems({
            nature_du_prix: filtersHook.params.nature_du_prix,
            // pays_d_origine: filtersHook.filters.pays_d_origine,
            labels: filtersHook.params.labels,
            temperature_livraison: filtersHook.params.temperature_livraison,
            q: filtersHook.params.q,
            precommande: ApourB ? false : undefined,
          }).then(setHits);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [filtersHook.params, goRender]);

      // useEffect pour supprimer barre de defilement de la page entière
      // et éviterla superposition avec barre de défilement de la modale
      useEffect(() => {
        document.body.style.overflow = 'hidden'; // désactive le scroll de défilement de la page
        return () => {
          document.body.style.overflow = 'unset'; // réactive le défilement quand la modale est fermée
        };
      }, []);

      const remplacerClick = (itemState : State<ItemCatalogueDesc>) => {
        remplacerSubmit({
          code_article: itemState.data.tolede.code_article
        });
        modal.remove();
      };

      const hitsToRender = hits?.hits ?? remplacements?.hits ?? [];
      const placeholder = range(0, 6).map((_, i) => <RemplacementItemSkeleton key={i} />);

      const {isMobile} = useContext(BreakPointContext)!;
      const scollW = useScrollbarWidth();
      const boxRef = useRef<HTMLDivElement | null>(null);
      const usableWidth = boxRef.current?.clientWidth;
      // console.log('usableWidth:', usableWidth);
      // console.log('clientHeight:', boxRef.current?.clientHeight);
      const columnCount = isMobile ? 1 : 3;
      const columnWidth = isMobile ? usableWidth : Math.floor((usableWidth ?? 100 - scollW)/3);
      return <Drawer
        placement='right'
        isOpen={modal.visible}
        onClose={()=>{modal.remove();}}
        size='lg'
      >
        <DrawerOverlay />
        <DrawerContent position='relative'
          bg='#F5F5F5'
          transition='all 0.2s ease-out'>
          <VStack height="100%"
            spacing={0}>
            <HStack p='4'
              bg='blue.main'
              color='white'
              position='sticky'
              top='0'
              zIndex='sticky'
              w='100%'
              spacing={4}>
              <VStack flex={1}
                alignItems='flex-start'>
                <Text>
                  <Trans t={t}>{t('commande.remplacer_article', {nom:articleData.tolede.libelle_commercial})}</Trans>
                </Text>
              </VStack>
              <Box>
                <DrawerCloseButton position='static' />
              </Box>
            </HStack>
            <Box w='100%'
              maxW='container.xl'
              mx='auto'>
              <VStack spacing={0}
                align='stretch'
                w='100%'>
                <Box bg='gray.light'
                  p='5'>
                  <InputGroup zIndex={1003}
                    bg='white'
                    borderRadius='8px'>
                    <InputLeftElement
                      pointerEvents="auto"
                      cursor="pointer"
                    >
                      <SearchIcon />
                    </InputLeftElement>
                    <Input
                      placeholder={t('commande.chercher_tout_catalogue')}
                      onChange={e => {debouncedChange.change(e.target.value);}}
                      value={debouncedChange.value}
                    />
                  </InputGroup>
                </Box>
                {debouncedChange.value && <Box pt='3'
                  pr='5'
                  pl={{base:'5', md:'0'}}
                  w='100%'>
                  <CatalogFilter
                    modaleRemplacement={true}
                    itemResults={hits}
                    filtersHook={filtersHook}
                    props={{bg:'transparent', p:'0!important'}}
                    direction='column-reverse'/>
                </Box>}
              </VStack>
            </Box>
            <Box flex={1}
              w="100%"
              mb='0'
              ref={boxRef}
              overflow='auto'>
              {
                (!debouncedChange.value && remplacements?.hits.length === 0) ?
                  <Text textAlign='center'
                    my='10'
                    fontSize='xl'
                    color='gray.dark'>{t('commande.remplacement_aucun_article')}</Text>
                  :
                  ! usableWidth ?
                    <SimpleGrid columns={{base:1, md:3}}
                      spacing="0"
                      p="20px"
                      pt={hits ? '10px' : '20px'}
                      gap="0">
                      {placeholder}
                    </SimpleGrid>
                    :
                    <FixedSizeGrid
                      rowHeight={isMobile ? 255 : 450}
                      columnWidth={columnWidth!}
                      columnCount={columnCount}
                      height={boxRef.current?.clientHeight ?? 400}
                      width={usableWidth}
                      rowCount={Math.floor(1 + hitsToRender.length / 3)}
                    >{
                        ({rowIndex, columnIndex, style}) => {
                          const hit = hitsToRender[rowIndex*3 + columnIndex];
                          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                          if (!hit) {
                            return null;
                          }
                          return (
                            <RemplacementItem
                              key={hit.id}
                              hit={hit.document}
                              remplacerClick={remplacerClick}
                              style={style}
                              isMobile={isMobile}
                            />
                          );
                        }
                      }</FixedSizeGrid>
              }
            </Box>
          </VStack>
        </DrawerContent>
      </Drawer>;
    });

const RemplacementItemSkeleton: React.FC = () => {
  return (
    <VStack height='420px'
      padding="10px"
      bg='white'
      border="1px"
      borderColor='gray.light'
      alignItems='stretch'
      position='relative'>
      <Skeleton height='20px'
        width='100%' />
      <Skeleton height='134px'
        width='100%'
        mb='10px' />
      <VStack width='100%'>
        <HStack height='22px'
          justifyContent="left"
          width='100%'>
          <Skeleton height='12px'
            width='80px' />
        </HStack>
        <Skeleton height='60px'
          width='100%' />
        <Skeleton height='10px'
          width='100%'/>
      </VStack>
      <HStack justifyContent='end'
        width='100%'
        mb='1'>
        <VStack height='40px'
          alignItems='flex-end'
          gap='0'
          mt='10px'>
          <Skeleton height='20px'
            width='100px' />
          <Skeleton height='11px'
            width='80px' />
        </VStack>
      </HStack>
      <Skeleton height='40px'
        width='100%' />
    </VStack>
  );
};

const RemplacementItem:React.FC<{
  hit: HitItemCatalogue,
  remplacerClick: (itemState: State<ItemCatalogueDesc>) => void;
  style: CSSProperties;
  isMobile: boolean;
}> = React.memo(({
  hit,
  remplacerClick,
  style,
  isMobile
}) => {
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const modal = useModal();
  const code_article = hit.code;
  const itemHook = useHalState(useClient().follow('go:item_catalogue', {code_article}));
  const articleNav = useApi().follow('get-article', {code_article});
  const {t} = useTranslation();

  const handleItemLink = () => {
    modal.remove();
  };
  const href = buildAssistanceRoute(routes.client.produit, {code_article});
  
  return (
    <HaliRenderOne nav={itemHook.navigable}
      skeleton={RemplacementItemSkeleton}
      render={({data:itemData, state:itemState}) => {
        const itemVignette = imageOptimisee(hit.vignette_url, 200);
        const {tolede:toledeItem} = itemData;
        const montant_ht = toledeItem.prix_par_livraison?.[0]?.montant_ht;
        return (
          !isMobile? // Version desktop
            <VStack style={style}
              display={{base:'none', md:'flex'}}
              padding="10px"
              bg='white'
              border="1px"
              borderColor='gray.light'
              alignItems='stretch'
              position='relative'>
              <Link
                to={href}
                onClick={handleItemLink}
                style={{textDecoration: 'none', color: 'inherit', display: 'contents', width: '100%', cursor:'pointer'}}
              >
                <LabelDisponibilite precommande={hit.precommande} />
                <Stack maxHeight='150px'
                  flexGrow={1}
                  p='5px'
                  justifyContent="center"
                  alignItems="center"
                  mt='10px'>
                  <Box maxWidth="202px"
                    maxHeight="134px"
                    position='relative'>
                    <Image src={itemVignette}
                      alt={hit.libelle_commercial}
                      objectFit="contain"
                      width="100%"
                      height="100%" />
                    <Box position="absolute"
                      bottom="0"
                      left="0">{hit.temperature_livraison === 'negatif' && <ColdIcon boxSize='25px' />}</Box>
                  </Box>
                  {hit.nature_du_prix?.includes('promo') && (
                    <HStack position="absolute"
                      top={hit.precommande ? '35px' : '10px'}
                      left="12px"
                      bg="red"
                      color="white"
                      fontSize="xs"
                      fontWeight="bold"
                      py="2px"
                      px="8px"
                      borderRadius="md"
                      zIndex='docked'>
                      <PromoIcon color='white'/>
                      <Text>{t('global.promotion')}</Text>
                    </HStack>
                  )}
                </Stack>
                <VStack width='100%'
                  alignItems='stretch'
                  spacing='0'
                  flex={1}>
                  <HStack height='22px'
                    alignItems="center"
                    justifyContent="start">
                    <Text align='left'
                      color='gray.dark'
                      fontSize='xs'
                      fontWeight='400'
                      noOfLines={1}>
                      {code_article}
                    </Text>
                    <HStack>
                      <HaliRenderOne nav={articleNav}
                        render={({state: articlestate}) => {
                          return <DisplayLabels articleState={articlestate}/>;
                        }}/>
                      {/* <ListeDrapeaux listepays={pays} max={4}/> */}
                    </HStack>
                  </HStack>
                  <Text align='left'
                    color='black'
                    fontSize='sm'
                    lineHeight='18px'
                    fontWeight='600'
                    noOfLines={4}>
                    {hit.libelle_commercial}
                  </Text>
                  <Text align='left'
                    color='black'
                    fontSize='xs'
                    lineHeight='18px'
                    h='18px'
                    mb='20px'
                    noOfLines={1}>
                    {hit.marque}
                  </Text>
                  <RemplacementDisplayMultiples itemState={itemState}/>
                </VStack>
              </Link>
              <HStack justifyContent='end'
                width='100%'
                mb='3'>
                <VStack height='40px'
                  alignItems='flex-end'
                  gap='0'
                  mt='10px'>
                  <HStack gap='0.5'
                    alignItems='start'>
                    <Text textColor='black.text'
                      fontSize='xl'
                      fontWeight='700'>{toDecimalFr(montant_ht, 2,3)}</Text>
                    <Text fontSize='xs'>€</Text>
                    <Text fontSize='xs'>{t('ligne_produit.HT')}/{t(`unite.${toledeItem.unite_de_facturation}`, {count: 1})}</Text>
                  </HStack>
                  <Text fontSize='2xs'
                    fontWeight='400'
                    color='gray.dark'
                    textAlign='left'
                    width='100%'>
                    {t('ligne_produit.tva_percent', {tauxTVA:toledeItem.taux_de_tva})}
                  </Text>
                </VStack>
              </HStack>
              <Button
                onClick={() => {remplacerClick(itemState);}}
                variant='outline'
                borderColor='blue.main'
                color='blue.main'
                leftIcon={<SwitchIcon color='blue.main'/>}
                h='40px'
              >
                {t('global.remplacer')}
              </Button>
            </VStack>

            : // Version mobile
            <VStack style={style}
              display={{base:'flex', md:'none'}}
              padding="20px"
              pt='30px'
              border="1px"
              borderColor='gray.light'
              position="relative"
              bg='white'>
              <Link to={href}
                onClick={handleItemLink}
                style={{textDecoration: 'none', color: 'inherit', display: 'contents', width: '100%'}}>
                <LabelDisponibilite precommande={hit.precommande} />
                <HStack height='85px'
                  width='100%'
                  mb='10px'
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing={4}>
                  <Box boxSize="90px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative">
                    <Image src={itemVignette}
                      alt={hit.libelle_commercial}
                      objectFit="contain"
                      maxWidth="100%"
                      maxHeight="100%"
                      width="auto"
                      height="auto" />
                    {hit.temperature_livraison === 'negatif' && (
                      <Box position="absolute"
                        bottom="0"
                        left="0"
                        zIndex="1">
                        <ColdIcon boxSize='25px' />
                      </Box>
                    )}
                  </Box>
                  {hit.nature_du_prix?.includes('promo') && (
                    <HStack position="absolute"
                      top={hit.precommande ? '35px' : '10px'}
                      left="12px"
                      bg="red"
                      color="white"
                      fontSize="xs"
                      fontWeight="bold"
                      py="2px"
                      px="8px"
                      borderRadius="md"
                      zIndex='docked'>
                      <PromoIcon color='white'/>
                      <Text>{t('global.promotion')}</Text>
                    </HStack>
                  )}
                  <VStack alignItems="flex-start"
                    spacing={1}>
                    <HStack >
                      <Text align='left'
                        color='gray.dark'
                        fontSize='xs'
                        fontWeight='400'>{code_article}</Text>
                      <HaliRenderOne nav={articleNav}
                        skeleton={null}
                        render={({state: articlestate}) => {
                          return<DisplayLabels articleState={articlestate}/>;
                        }}/>
                    </HStack>
                    <VStack spacing={0}
                      alignItems='start'>
                      <Text align='left'
                        color='black'
                        fontSize='sm'
                        lineHeight='18px'
                        fontWeight='600'>{hit.libelle_commercial}</Text>
                      <Text align='left'
                        color='black'
                        fontSize='xs'
                        lineHeight='18px'>{hit.marque}</Text>
                      <RemplacementDisplayMultiples itemState={itemState}/>
                    </VStack>
                  </VStack>
                </HStack>
              </Link>
              <HStack justifyContent='end'
                width='100%'
                mb='3'>
                <VStack height='40px'
                  alignItems='flex-end'
                  gap='0'
                  mt='10px'>
                  <HStack gap='0.5'
                    alignItems='start'>
                    <Text textColor='black.text'
                      fontSize='xl'
                      fontWeight='700'>{toDecimalFr(montant_ht, 2,3)}</Text>
                    <Text fontSize='xs'>€</Text>
                    <Text fontSize='xs'>{t('ligne_produit.HT')}/{t(`unite.${toledeItem.unite_de_facturation}`, {count: 1})}</Text>
                  </HStack>
                  <Text fontSize='2xs'
                    fontWeight='400'
                    color='gray.dark'
                    textAlign='left'
                    width='100%'>
                    {t('ligne_produit.tva_percent', {tauxTVA:toledeItem.taux_de_tva})}
                  </Text>
                </VStack>
              </HStack>
              <Button
                onClick={() => {remplacerClick(itemState);}}
                variant='outline'
                borderColor='blue.main'
                color='blue.main'
                leftIcon={<SwitchIcon color='blue.main'/>}
                h='40px'
                w='100%'
              >
                {t('global.remplacer')}
              </Button>
            </VStack>
        );
      }}/>
  );
}, (prevProps, nextProps) => {
  // verification si hit.code et hit.libelle_commercial ont changé
  return prevProps.hit.code === nextProps.hit.code;
});

const RemplacementDisplayMultiples:React.FC<{itemState:State<ItemCatalogueDesc>}> = ({itemState})=>{
  const {tolede} = itemState.data;
  const {t} = useTranslation();

  const {optionsList} = useGetSelectUniteOptions({ic:tolede, quantite_max:undefined, t});
  //Place le | au milieu de la liste des unités
  const optionsListText = optionsList.flatMap((opt, index) => [
    <span key={`opt-${index}`}>{opt.texte}</span>,
    index < optionsList.length - 1 ? <span key={`separator-${index}`}> | </span> : null
  ]);

  return (
    <Text align='left'
      color='black'
      fontSize='2xs'
      textColor='gray.dark'>
      {optionsListText}
    </Text>
  );
};
