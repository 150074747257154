import React, {useEffect, useState} from 'react';
import {CatalogHeader} from './components/CatalogHeader';
import {CatalogProductsGrid} from './CatalogProductsGrid';
import {CatalogFilter} from './components/CatalogFilter/CatalogFilter';
import {
  ITEMS_PAR_PAGE, coerceFiltresItemCatalogue as coerce, 
  useSearchClient
} from 'ff-common';
import {Box, Text} from '@chakra-ui/react';
import {RechercheView} from './RechercheView';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Pagination} from '@/components/Pagination';
import {Chargement} from '@/components/Chargement/Chargement';
import {FiltresItemCatalogue} from 'ff-common/src/hooks/search/use-search';
import {TPath, useTypedQuery} from '@synako/typed-path';

export const CatalogueView: React.FC = () => {
  const filtersHook = useTypedQuery<TPath<{Query: FiltresItemCatalogue}>>(coerce);

  const search = useSearchClient();
  const [itemResults, setItemResults] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const term = filtersHook.params.q;
  const {t} = useTranslation('common');

  useEffect(() => {
    if (search.indexIsBuilt) {
      search.searchItems(filtersHook.params).then((r) => {
        setItemResults(r);
        setTotalPages(Math.ceil(r.count / ITEMS_PAR_PAGE));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersHook.params, search.indexIsBuilt]);

  if (!search.indexIsBuilt) {
    return (
      <Chargement texte={t('catalogue.chargement')}/>
    );
  }

  return (
    <Box bg='white'>
      {location.pathname.includes('/catalogue') ? (
        <CatalogHeader catWeb={filtersHook.params.cat_web?.[0] ?? ''} />
      ) : (
        <RechercheView term={term}
          itemResults={itemResults}/>
      )}
      <CatalogFilter
        itemResults={itemResults}
        filtersHook={{
          ...filtersHook,
          change: (name, val) => {filtersHook.changeMultiple({
            [name]: val,
            page: undefined, // reset to page 1
          });}
        }}/>
      <CatalogProductsGrid
        hitResult={itemResults?.hits ?? []}
        location={location}
      />
      {itemResults?.count === 0 ?
        <Box mt='30px'
          pb='30px'>
          <Text textAlign='center'
            fontWeight='500'>{t('catalogue.aucun_produit')}</Text>
        </Box>
        :
        <Pagination
          pageActuelle={filtersHook.params.page ?? 1}
          totalPages={totalPages}
          onPageChange={p => {filtersHook.change('page', p);}}
        />
      }
    </Box>
  );
};
