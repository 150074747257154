import {CloseIcon} from '@chakra-ui/icons';
import {HStack, Text} from '@chakra-ui/layout';
import {Button, VStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {routes} from 'ff-common';
import {useTranslation} from 'react-i18next';

export const HeaderPanierListe: React.FC<{nbPaniers: number}> = ({nbPaniers}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return <VStack flexDirection={{base: 'column-reverse', md: 'column'}}
    w='100%'>
    <HStack
      bg={{base: 'blue.main', md: 'white'}}
      w='100%'
      p={{base: '5', md: '2'}}
      textColor={{base: 'white', md: 'black'}}
    >
      {nbPaniers > 0 && (
        <Text
          fontSize='xl'
          fontWeight='700'
        >
          {t('panier.x_panier_a_valider', {count: nbPaniers})}
        </Text>
      )}
      <Button variant='ghost'
        ml='auto'
        display={{base: 'flex', md: 'none'}}
        onClick={() => {
          navigate(routes.index.path);
        }}>
        <CloseIcon color='white' />
      </Button>
    </HStack>
  </VStack>;
};
