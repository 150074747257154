import {FactureIcon} from '@/components/svg/FactureIcon';
import {SwitchAccountIcon} from '@/components/svg/SwitchAccountIcon';
import {UserIcon} from '@/components/svg/UserIcon';
import {useMe} from '@/haligator-factory';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {Box, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, HStack, Heading, Text, VStack, useDisclosure} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {MenuEspaceClient, MenuItems} from './MenuItem';

export const MenuClientMobileModale = NiceModal.create(() => {
  const modal = useModal();
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();

  const contact = useMe().follow('contact');
  const contacts = useHalState(useMe().follow('switch'));
  const {isOpen, onClose} = useDisclosure({isOpen: modal.visible, onClose: modal.remove});

  return (
    <Drawer isOpen={isOpen}
      placement='left'
      size="full"
      onClose={onClose}>
      <DrawerOverlay bg="gray.bg"/>
      <DrawerContent>
        <DrawerBody>
          <VStack spacing={4}
            align="start">
            <HaliRenderOne
              nav={contact}
              render={({data}) => (
                <Heading size="md"
                  mt="20px"
                  mb="20px"
                  fontSize="2xl">{t('accueil.bonjour', {nom: data.tolede.prenom})}</Heading>
              )}
              notFound={() => (
                <Heading size="md"
                  mt="20px"
                  mb="20px"
                  fontSize="2xl">{t('accueil.bonjour', {nom: ''})}</Heading>
              )}
              fallback={() => (
                <Heading size="md"
                  mt="20px"
                  mb="20px"
                  fontSize="2xl">{t('accueil.bonjour', {nom: ''})}</Heading>
              )}
            />
            <HStack spacing={4}
              width="100%"
              alignItems='stretch'>
              <Link to={buildAssistanceRoute(routes.client.factures)}
                style={{flex:1}}
                onClick={() => {onClose();}}>
                <Box p={4}
                  bg="blue.bg"
                  borderRadius="md"
                  h='100%'>
                  <Box bg='blue.main_transparency_10'
                    borderRadius="8px"
                    p="6px"
                    mb="10px"
                    width="37px">
                    <FactureIcon color='blue.main'
                      width='25px'
                      height='25px'/>
                  </Box>
                  <Text fontSize="sm"
                    fontWeight="600">{t('menu.factures_et_avoirs')}</Text>
                </Box>
              </Link>
              <Link to={buildAssistanceRoute(routes.client.profil)}
                style={{flex:1}}
                onClick={() => {onClose();}}>
                <Box p={4}
                  bg="blue.bg"
                  borderRadius="md"
                  h='100%'>
                  <Box bg='blue.main_transparency_10'
                    borderRadius="8px"
                    p="6px"
                    mb="10px"
                    width="37px">
                    <UserIcon color='blue.main'
                      width='25px'
                      height='20px'/>
                  </Box>
                  <Text fontSize="sm"
                    fontWeight="600">{t('menu.informations')}</Text>
                </Box>
              </Link>
            </HStack>
            <Box bg="white"
              width="100%"
              borderRadius="8px"
              p={5}>
              <Text fontWeight="bold"
                mb={7}>
                {t('menu.compte')}
              </Text>
              <VStack spacing={4}
                align="start">
                {MenuItems.map((item) => (
                  <MenuEspaceClient
                    key={item.to}
                    to={buildAssistanceRoute(routes.client[item.to])}
                    Icon={item.Icon}
                    label={t(item.label)}
                    isMobile={true}
                    onClick={onClose}
                  />
                ))}
              </VStack>
            </Box>
            <VStack width="100%"
              bg="white"
              borderRadius="8px"
              spacing={3}
              mb={20}>
              {(contacts.data?.items.length ?? 0) > 1 && (
                <Link to={buildAssistanceRoute(routes.client.clientSelect)}
                  style={{width:'calc(100% - 48px)'}}
                  onClick={() => {onClose();}}>
                  <Button leftIcon={<SwitchAccountIcon/>}
                    variant="outline"
                    color="black.ff"
                    mt="12px"
                    width="100%">
                    {t('menu.changer_de_client')}
                  </Button>
                </Link>
              )}
              <Button as='a'
                href='/authn/logout'
                variant="outline"
                color="gray.dark"
                m="12px"
                width='calc(100% - 48px)'>
                {t('menu.deconnecter')}
              </Button>
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});
