import {Icon, ResponsiveValue} from '@chakra-ui/react';

export const LocationIcon: React.FC<{
  color?:ResponsiveValue<string>;
  width?:ResponsiveValue<string>;
  height?:ResponsiveValue<string>;
}> = ({color='blue.main', width='18px', height='18px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 18 18" 
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M8.52714 1.5C7.01959 1.5 5.57379 2.13214 4.50779 3.25736C3.4418 4.38258 2.84293 5.9087 2.84293 7.5C2.84293 9.82354 4.27516 12.0782 5.84558 13.8279C6.61626 14.6866 7.38935 15.3896 7.97057 15.8781C8.18688 16.0599 8.37581 16.2114 8.52714 16.3294C8.67846 16.2114 8.8674 16.0599 9.08371 15.8781C9.66493 15.3896 10.438 14.6866 11.2087 13.8279C12.7791 12.0782 14.2113 9.82354 14.2113 7.5C14.2113 5.9087 13.6125 4.38258 12.5465 3.25736C11.4805 2.13214 10.0347 1.5 8.52714 1.5ZM8.52714 17.25C8.13301 17.874 8.13283 17.8739 8.13262 17.8738L8.13059 17.8723L8.12586 17.869L8.10957 17.8574C8.09574 17.8474 8.07601 17.8332 8.05077 17.8147C8.0003 17.7778 7.92778 17.7241 7.83636 17.6544C7.65358 17.5151 7.39482 17.3117 7.08535 17.0516C6.46756 16.5323 5.64197 15.7821 4.81396 14.8596C3.18701 13.0468 1.42188 10.4265 1.42188 7.5C1.42188 5.51088 2.17046 3.60322 3.50296 2.1967C4.83545 0.790176 6.6427 0 8.52714 0C10.4116 0 12.2188 0.790176 13.5513 2.1967C14.8838 3.60322 15.6324 5.51088 15.6324 7.5C15.6324 10.4265 13.8673 13.0468 12.2403 14.8596C11.4123 15.7821 10.5867 16.5323 9.96892 17.0516C9.65946 17.3117 9.4007 17.5151 9.21792 17.6544C9.12649 17.7241 9.05397 17.7778 9.0035 17.8147C8.97827 17.8332 8.95854 17.8474 8.9447 17.8574L8.92842 17.869L8.92368 17.8723L8.92218 17.8734C8.92198 17.8735 8.92127 17.874 8.52714 17.25ZM8.52714 17.25L8.92127 17.874C8.6826 18.042 8.37129 18.0417 8.13262 17.8738L8.52714 17.25Z"/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5257 6C7.74087 6 7.10465 6.67157 7.10465 7.5C7.10465 8.32843 7.74087 9 8.5257 9C9.31052 9 9.94675 8.32843 9.94675 7.5C9.94675 6.67157 9.31052 6 8.5257 6ZM5.68359 7.5C5.68359 5.84315 6.95605 4.5 8.5257 4.5C10.0954 4.5 11.3678 5.84315 11.3678 7.5C11.3678 9.15685 10.0954 10.5 8.5257 10.5C6.95605 10.5 5.68359 9.15685 5.68359 7.5Z"/>
    </Icon>
  );
};
