import {Box, HStack} from '@chakra-ui/react';
import {ReactNode, useCallback, useState} from 'react';
import {CalendarGrid} from './CalendarGrid';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {CalendarHeader} from './CalendarHeader';
import {BlueButton} from '@/components/Button/BlueButton';
import {useTranslation} from 'react-i18next';
import {useModal} from '@ebay/nice-modal-react';

export const Calendar: React.FC<{
  onCancel?: () => void,
  onDateSelect: (resource: State<LivraisonDesc>) => void,
  onValidate: () => Promise<unknown>,
  selectedLivraison: State<LivraisonDesc>,
  dateDebut?: Date | undefined
  children?: ReactNode
}> 
= ({
  onDateSelect,
  onValidate,
  selectedLivraison,
  dateDebut,
  children
}) => {
  const {t} = useTranslation();
  const [monthOffset, setMonthOffset] = useState<number>(0);

  const onDateClick = useCallback((livraison: State<LivraisonDesc>) => {
    onDateSelect(livraison);
    setMonthOffset(0);
  }, [onDateSelect, setMonthOffset]);

  const modal = useModal();
  const [validating, setValidating] = useState(false);
  const valideThenClose = () => {
    setValidating(true);
    onValidate().then(() => {
      // TODO : gérer ici une gestion des erreurs ?
      // modal.hide();
      modal.remove();
    }).finally(() => {
      setValidating(false);
    });
  };
  return (
    <Box>
      <CalendarHeader 
        dateLivraison={selectedLivraison.data.tolede.date_de_livraison} 
        monthOffset={monthOffset} 
        setMonthOffset={setMonthOffset}
      />
      <CalendarGrid
        selectedLivraison={selectedLivraison}
        onDateClick={onDateClick} 
        monthOffset={monthOffset} 
        dateDebut={dateDebut}
      />
      {children}
      {/* FOOTER */}
      <HStack
        mt={{base:'5', md:'10'}} 
        gap='3' 
        flexDirection={{base: 'column', md: 'row'}} 
        alignItems='stretch' 
        bottom='50px'
        p='5px'
      >
        <BlueButton texte={t('modale_livraison.valider_date')}
          isDisabled={validating}
          onClick={valideThenClose}
          flex='1'
          p='3'
          flexShrink={0}
          mt='0'/>
      </HStack>  
    </Box>
  );
};
