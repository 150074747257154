import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {MonCadencier} from './domains/cadencier/MonCadencier.tsx';
import {routes} from 'ff-common';
import {Layout} from './views/Layout';
import {PanierView} from './domains/panier/PanierView.tsx';
import {CatalogueView} from './domains/catalogue/CatalogueView.tsx';
import {ProduitView} from './domains/article/FicheArticle/FicheArticleView.tsx';
import {Protected} from './domains/auth/Protected.tsx';
import {SearchView} from './domains/debug/SearchView.tsx';
import {SearchRemplacementView} from './domains/debug/SearchRemplacementView.tsx';
import {DebugNomenclatureView} from './domains/debug/DebugNomenclatureView.tsx';
import {MentionsLegales} from './domains/orga/MentionsLegalesView.tsx';
import {MesListes} from './domains/liste/MesListes.tsx';
import {ListeView} from './domains/liste/ListeView.tsx';
import {CacheView} from './domains/cache/CacheView.tsx';
import ProfilView from './domains/client/ProfilView.tsx';
import CommandesView from './domains/commande/CommandesView.tsx';
import {AccueilView} from './domains/accueil/AccueilView.tsx';
import SettingsView from './domains/client/SettingsView.tsx';
import BonLivraisonView from './domains/expedition/BonLivraisonView.tsx';
import FacturesAvoirsView from './domains/facture/FacturesAvoirsView.tsx';
import RepresentantsView from './domains/client/RepresentantsView.tsx';
import {SelectClientView} from './domains/auth/SelectClientView.tsx';
import SidebarMenu from './domains/client/menu/SideBarMenu.tsx';
import {Erreur404} from './components/Erreurs/Erreur404.tsx';
import {CommandeDetailView} from './domains/commande/CommandeDetailView.tsx';
import {ClientApp} from './domains/auth/ClientApp.tsx';
import {AuthErrors, authErrorRoute} from './domains/auth/AuthErrors.tsx';
import {Connection, connectionRoute} from './domains/auth/Connection.tsx';
import {AuthLayout} from './domains/auth/AuthLayout.tsx';
import {RedirectToPanier} from './domains/panier/controllers/RedirectToPanier.tsx';
import {DebugModaleRemplacementCatalogueView} from './domains/debug/DebugModaleRemplacementCatalogueView.tsx';
import {PostHogPageView} from './posthog/PosthogSetup.tsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<PostHogPageView />}>
      {/* Routes d'authentification */}
      <Route element={<AuthLayout />}>
        <Route element={<Connection />}
          path={connectionRoute.path} />
        <Route element={<AuthErrors />}
          path={authErrorRoute.path} />
      </Route>
      <Route element={<Protected />}
        errorElement={<Erreur404 />}>
        {/* Selection du client sur lequel se connecter */}
        <Route path={routes.client.clientSelect.path}
          element={<SelectClientView />} />
        
        <Route element={<ClientApp />}>
          {/* Pour debug */}
          <Route path={routes.client.inspect_search.path}
            element={<SearchView />} />
          <Route path={routes.client.inspect_remplacement.path}
            element={<SearchRemplacementView />} />
          <Route path={routes.client.inspect_nomenclature.path}
            element={<DebugNomenclatureView />} />
          <Route path={'/ffconnect/inspect/modale'}
            element={<DebugModaleRemplacementCatalogueView />} />

          <Route path={routes.index.path}
            element={<Layout />}>
            <Route path="*"
              element={<Erreur404/>} />

            <Route index
              element={<Navigate to={routes.client.accueil.path}
                replace/>}/>
        
            {/* Cadencier */}
            <Route path={routes.client.cadencier.path}
              element={<MonCadencier />} />

            {/* Catalogue */}
            <Route path={routes.client.catalogue.path}
              element={<CatalogueView />}/>
        
            {/* Fiche produit */}
            <Route path={routes.client.produit.path}
              element={<ProduitView />}/>

            {/* Recherche */}
            <Route path={routes.client.recherche.path}
              element={<CatalogueView />}/>

            {/* Panier */}
            <Route path={routes.client.panier_default.path}
              element={<PanierView />} />
            <Route path={routes.client.panier.path}
              element={<PanierView />}/>
            <Route path={routes.client.panier_redirect.path}
              element={<RedirectToPanier />}/>

            {/* Mentions legales */}
            <Route path={routes.client.mentions.path}
              element={<MentionsLegales />}/>

            {/* Mes Listes */}
            <Route path={routes.client.listes_default.path}
              element={<MesListes />}/>
            <Route path={routes.client.listes.path}
              element={<ListeView />}/>

            {/* Cache */}
            <Route path={routes.client.cache.path}
              element={<CacheView />} />

            {/* Accueil */}
            <Route path={routes.client.accueil.path}
              element={<AccueilView />} />
        
            {/* SideBarMenu de l'espace client */}
            <Route element={<SidebarMenu />}>
              {/* Profil */}
              <Route path={routes.client.profil.path}
                element={<ProfilView/>} />

              {/* Commandes */}
              <Route path={routes.client.commandes.path}
                element={<CommandesView/>} />

              {/* Factures et avoirs */}
              <Route path={routes.client.factures.path}
                element={<FacturesAvoirsView/>} />

              {/* Bons de livraison */}
              <Route path={routes.client.livraisons.path}
                element={<BonLivraisonView/>} />

              {/* Contacts */}
              <Route path={routes.client.contacts.path}
                element={<RepresentantsView/>} />

              {/* Préférences */}
              <Route path={routes.client.preferences.path}
                element={<SettingsView/>} />
            </Route>

            {/* Commande Détaillée */}
            <Route path={routes.client.commande_detail.path}
              element={<CommandeDetailView/>} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
