import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {CodeProbleme, LigneDePanier, LigneProblem} from 'api-types/ffconnect';
import {State} from '@synako/halitrek';
import {DisponibiliteArticleDesc} from 'api-types/ffconnect-hal';

export const usePanierAffichageBoutonsErreurs = (
  {
    ligneDePanier,
    probleme,
    dispo,
    tooltip,
    RemplacerErrorButton,
    DiffererErrorButton,
    SupprimerErrorButton,
    AjusterErrorButton,
    multipleCommandeClient = 1,
    strong = <strong/>,
    i=<i/>
  }
  :{
    ligneDePanier:State<LigneDePanier>,
    probleme:LigneProblem | undefined,
    dispo:State<DisponibiliteArticleDesc> | undefined,
    tooltip:(label:string, message:string)=>ReactElement,
    RemplacerErrorButton:ReactElement,
    DiffererErrorButton:ReactElement,
    SupprimerErrorButton:ReactElement,
    AjusterErrorButton:(quantiteMax:number|undefined)=>ReactElement,
    multipleCommandeClient?: number,
    strong?:ReactElement,
    i?:ReactElement
  }) => {
  const {t} = useTranslation();
  const quantite = ligneDePanier.data.quantite;
  const uniteCommande = ligneDePanier.data.unite_de_commande_client;

  let errorCode: CodeProbleme = 'generique';
  let quantiteMax: number | undefined;
  
  if (probleme) {
    errorCode = probleme.problem.code;
    quantiteMax = probleme.problem.quantite_max;
  } else if (dispo) {
    if (dispo.data.tolede.disponibilite === 'rupture') {
      errorCode = 'rupture';
    } else if (dispo.data.quantite_manquante) {
      errorCode = 'quantite_trop_importante';
    }
    quantiteMax = dispo.data.quantite_livrable;
  }

  let message;
  let buttons;

  switch (errorCode) {
    case 'rupture':
      message = <Trans t={t}
        components={{strong, i}}>{t('ligne_produit.indisponible.rupture', {
          quantite, 
          unite: t(`unite.${uniteCommande}`, {count: quantite})
        })}</Trans>;
      buttons = <>
        {RemplacerErrorButton}
        {DiffererErrorButton}
        {SupprimerErrorButton}
      </>;
      break;
    case 'quantite_trop_importante': {
      const isQuantiteMaxMultiple = quantiteMax !== undefined && quantiteMax % multipleCommandeClient === 0;

      buttons = <>
        {RemplacerErrorButton}
        {isQuantiteMaxMultiple ? AjusterErrorButton(quantiteMax) : SupprimerErrorButton}
      </>;
      message = <Trans t={t}
        components={{strong, i}}>
        {t('ligne_produit.indisponible.rupture_partielle', {
          quantite_max: `${quantiteMax} ${t(`unite.${uniteCommande}`, {count: quantiteMax})}`,
          quantite_demande: quantite,
          count: quantiteMax
        })}
      </Trans>;
      break;
    }
    case 'article_inconnu':
    case 'article_obsolete':
    case 'non_commercialise':
    case 'non_autorise':
    case 'non_web':
    case 'erreur_temperature_tournee':
    case 'erreur_multiple_de_commande':
    case 'generique':
    case 'heure_depassee':
    case 'adresse_inconnue': // A partir de là normalement cela ne concerne que la commande
    case 'client_bloque':
    case 'client_inconnu':
    case 'delai_de_prepa_long':
    case 'erreur_code_tournee':
    case 'erreur_date_livraison':
    case 'erreur_mode_de_rendu':
    case 'erreur_nb_lignes':
    case 'franco_poids_prix_non_atteint':
    case 'reference':
      buttons = <>
        {/* {RemplacerErrorButton} */}
        {SupprimerErrorButton}
      </>;
      message = tooltip(`${probleme?.problem.message} (${probleme?.problem.code})`, t('ligne_produit.indisponible.generique'))
      ;
      break;
  }

  return {message, buttons};
};
