import {Box, GridItem, HStack, Text} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {ModaleAlerteConfirmation} from './ModaleAlerteConfirmation';
import {ProductControlSideHeader} from '@/components/Product/ProductControlSideHeader';
import {PanierCommands} from 'ff-common/src/hooks/panier/use-panier-commands';
import {useTranslation} from 'react-i18next';
import {HaliRenderOne} from '@synako/haligator';
import {FollowPromiseOne} from '@synako/halitrek';
import {TourneeDesc} from 'api-types/ffconnect-hal';
import {IconeTemperatureLivraison} from '@/components/IconeTemperatureLivraison';

export const PanierHeader: React.FC<{
  viderCommand: PanierCommands['vider'],
  nombre_de_lignes: number,
  tourneeNav:FollowPromiseOne<TourneeDesc>
}> = ({
  viderCommand,
  nombre_de_lignes,
  tourneeNav
}) => {
  const {t} = useTranslation();
  return (
    <GridItem display="flex"
      colSpan={{base: 1, lg: 5}}
      flexDir={{base:'column', md:'row'}}>
      <HStack margin={{base: '2', lg: '0'}}>
        <Text fontWeight='600'>{t('panier.panier')}</Text>
        <Box textColor='gray.dark'
          fontWeight='600'
          fontSize='xs'
          bg='gray.light'
          p='1'
          borderRadius='8px'
          minW={'2em'}
          aspectRatio={1}
          textAlign='center'>
          {nombre_de_lignes}
        </Box>
        <Box>
          <HaliRenderOne nav={tourneeNav}
            render={({data:tournee})=>{
              if (tournee.tolede.temperature_de_livraison === 'les_deux') return;
              return <HStack
                textColor='white'
                fontWeight='600'
                fontSize='xs'
                bg='gray.light'
                p='1'
                pr='3'
                borderRadius='30'
                backgroundColor="cyan.surgele"
              >
                <IconeTemperatureLivraison temperature_tournee={tournee.tolede.temperature_de_livraison}
                  boxSize={6}/>
                <Text>
                  {tournee.tolede.temperature_de_livraison === 'negatif' 
                    ? t('temperature_livraison.livraison_surgelee')
                    : t('temperature_livraison.livraison_non_surgelee')
                  }
                </Text>
              </HStack>;
            }}/>
        </Box>
      </HStack>
      <ProductControlSideHeader deleteText={t('panier.supprimer_panier_action')}
        deleteAction={() => {
          NiceModal.show(ModaleAlerteConfirmation, {
            validate_texte:t('panier.supprimer_panier_action'),
            body: t('panier.supprimer_panier_confirm'),
            validateAction: () => {
              viderCommand.submit({} as never);
            }
          });
        }} />
    </GridItem>
  );
};
