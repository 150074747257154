import {ChakraProvider, Skeleton} from '@chakra-ui/react';
import {HaliRenderProvider, HaligatorProvider} from '@synako/haligator';
import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import theme from './chakra/index.ts';
import {createClient} from './halitreck-factory.js';
import {i18nConfig} from './locales/i18n-config.js';
import {toastTheme} from './chakra/components/Toast/index.ts';
import {fr as datesFr} from 'date-fns/locale/fr';
import {getLocale} from './utils/get-locale.ts';
import {setDefaultOptions as defaultDateFnsOptions} from 'date-fns';
import {middlewares} from '@synako/halitrek';
import {initPosthog} from './posthog/posthog-react.ts';
import {CaptureSetup} from './posthog/PosthogSetup.tsx';

const locale = getLocale(i18next.language);

const halitreck = createClient(locale);
halitreck.fetcher.use(middlewares.clientApp('client'));
halitreck.fetcher.use(async (request, next) => {
  const resp = await next(request);
  // Surtout utile quand un switch contact a été fait dans une fenêtre
  if (resp.status === 403) {
    window.location.href = '/ffconnect/auth/error?error=switched';
  }
  return resp;
});

const posthog = initPosthog();

defaultDateFnsOptions({locale: datesFr});
i18nConfig(locale);
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HaligatorProvider client={halitreck}>
      <HaliRenderProvider defaults={{
        skeleton:()=><Skeleton w='100%'
          h='10'
          m='1'/>,
      }} >
        <ChakraProvider theme={theme}
          toastOptions={toastTheme}>
          <CaptureSetup posthog={posthog}>
            <App />
          </CaptureSetup>
        </ChakraProvider>
      </HaliRenderProvider>
    </HaligatorProvider>
  </React.StrictMode>
);