import {CustomToast} from '@/components/toast/CustomToast';
import {AlertIcon, Text, VStack, useToast} from '@chakra-ui/react';
import {isArray} from 'lodash-es';
import {ReactElement, useCallback} from 'react';
import {TextToastCommand, TextToastCommandIcons} from 'ff-common/src/types/TextToastCommand';

const icon_table:Record<TextToastCommandIcons, ReactElement> = {
  'alert': <AlertIcon/>
  //'autre': <AutreIcon/>
};

export const useSimpleTextToast = ()=>{
  const toast = useToast();

  const textToast = useCallback<TextToastCommand>((content, options={})=>{
    const {id, duration=2000, icon} = options;
    const contentArray = (!isArray(content)) ? [content] : content;

    if (id && toast.isActive(id)) {
    // si un toast avec cet id est déjà actif, on le met à jour au lieu d'en créer un nouveau
      toast.update(id, {
        duration: duration,
        render: ({onClose}) => (
          <CustomToast onClose={onClose}
            icon={icon ? icon_table[icon] : undefined}
            body={
              <VStack alignItems='start'
                gap='0'>
                {contentArray.map((c, cidx) => <Text key={cidx}>{c}</Text>)}
              </VStack>
            } />
        )
      });
      return;
    }

    // plus besoin de faire un closeAll
    toast({id, duration, isClosable: true, render: ({onClose}) => {
      return <CustomToast onClose={onClose}
        icon={icon}
        body={<VStack alignItems='start'
          gap='0'>
          {
            contentArray.map((c, cidx)=><Text key={cidx}>{c}</Text>)
          }
        </VStack>} />;
    }});
  }, [toast]);

  return textToast;
};
