/* eslint-disable i18next/no-literal-string */
import React, {useEffect, useState} from 'react';
import {Box, Heading, Input, Select, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {
  coerceFiltresItemCatalogue as coerce, useSearchClient
} from 'ff-common';
import {FiltresItemCatalogue} from 'ff-common/src/hooks/search/use-search';
import {TypedFiltersProvider, useDebouncedChange, useTypedFilters, useTypedFiltersContext} from '@synako/typed-path';
// import {syncBoundedLevenshtein} from '@orama/orama/internals'

export const SearchView: React.FC = () => {
  const search = useSearchClient();

  // const [term, setTerm] = useState('');
  const filtersHook = useTypedFilters<FiltresItemCatalogue>({}, coerce);
  const [itemResults, setItemResults] = useState<Awaited<ReturnType<typeof search.searchItems>>>();

  useEffect(() => {
    if(!search.indexIsBuilt) return;

    search.searchItems(filtersHook.params).then((r) => {
      setItemResults(r);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.indexIsBuilt, filtersHook.params]);
  
  const catWebFacet = itemResults?.facets?.['cat_web'];

  return (
    <Box fontSize='sm'>
      <TypedFiltersProvider hook={filtersHook}>
        <Heading as='h1'>FULL</Heading>
        <Box padding={5}>
          <Heading as='h2'>Articles</Heading>
          <SearchQ />
          Catégories :
          <Select onChange={(e) => {
            filtersHook.change('cat_web', e.target.value);
          }}>
            <option value=''>Catégories ({catWebFacet?.count})</option>
            {Object.entries(catWebFacet?.values ?? {}).map(([key, val])=> {
              return <option key={key}
                value={key}>{key} - ({val})</option>;
            })}
          </Select>
          <div>
            {itemResults?.count} résultats en {itemResults?.elapsed.formatted}.
            Tokens: {filtersHook.params.q ? search.tokenize(filtersHook.params.q ?? '').join(' ') : null}
          </div>
          <Table width='auto'
            variant='striped'
            size='sd'>
            <Thead>
              <Tr>
                <Th>Code</Th>
                <Th>Libellé commercial</Th>
                <Th>Marque</Th>
                <Th>Score</Th>
                <Th>Préco.</Th>
                <Th>Cad.</Th>
                <Th>TAC</Th>
                <Th>T. Marque</Th>
                <Th>Mot directeur</Th>
                <Th>Tokens (libellé + marque + mot directeur)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {itemResults?.hits.map(hit => {
                return <Tr key={hit.id}
                  title={JSON.stringify(hit, null, 2)}>
                  <Td>{hit.document.code}</Td>
                  <Td>{hit.document.libelle_commercial}</Td>
                  <Td>{hit.document.marque ?? '-'}</Td>
                  <Td>{hit.score}</Td>
                  <Td>{hit.document.precommande ? 'OUI': '-'}</Td>
                  <Td>{hit.document.est_cadencier ? 'OUI' : '-'}</Td>
                  <Td>{hit.document.est_TAC ? 'OUI' : '-'}</Td>
                  <Td>{hit.document.type_de_marque}</Td>
                  <Td>{hit.document.mot_directeur ?? '-'}</Td>
                  <Td>
                    {search.tokenize(hit.document.libelle_commercial).join(' ')}
                    &nbsp;+ {search.tokenize(hit.document.marque ?? '-').join(' ')} 
                    &nbsp;+ {search.tokenize(hit.document.mot_directeur ?? '-').join(' ')}
                  </Td>
                </Tr>;
              })}
            </Tbody>
          </Table>
          <Heading as='h2'>Nombre de produits par catégorie</Heading>
          <div>{catWebFacet?.count} catégories</div>
          <Table width='auto'
            variant='striped'
            size='sd'>
            <Tbody>
              {Object.entries(catWebFacet?.values ?? {}).map(([cat, count]) => {
                return <Tr key={cat}>
                  <Th>{cat}</Th>
                  <Td>{count}</Td>
                </Tr>;
              })}
            </Tbody>
          </Table>
        </Box>
      </TypedFiltersProvider>
    </Box>
  );
};

function SearchQ() {
  const hook = useTypedFiltersContext<FiltresItemCatalogue>();
  const {change} = useDebouncedChange(hook, 'q');
  return <>
    Search : <Input type='text'
      onChange={(e) => {
        e.preventDefault();
        change(e.currentTarget.value);
      }} />
  </>;
}