import {Icon} from '@chakra-ui/react';

export const BoueeIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='blue.main', width='22', height='22'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 22 22"
      fill="none"
      stroke={color}
    >
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        strokeWidth="1.5"/>
      <path d="M11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11C7 13.2091 8.79086 15 11 15Z"
        strokeWidth="1.5"/>
      <path d="M14 8L18 4M4 18L8 14M8 8L4 4M18 18L14 14"
        strokeWidth="1.5"/>
    </Icon>
  );
};