export const MailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path d="M17.4166 3.66602H4.58325C3.85391 3.66602 3.15443 3.95575 2.63871 4.47147C2.12298 4.9872 1.83325 5.68667 1.83325 6.41602V15.5827C1.83325 16.312 2.12298 17.0115 2.63871 17.5272C3.15443 18.043 3.85391 18.3327 4.58325 18.3327H17.4166C18.1459 18.3327 18.8454 18.043 19.3611 17.5272C19.8769 17.0115 20.1666 16.312 20.1666 15.5827V6.41602C20.1666 5.68667 19.8769 4.9872 19.3611 4.47147C18.8454 3.95575 18.1459 3.66602 17.4166 3.66602ZM17.0408 5.49935L11.6508 10.8893C11.5655 10.9753 11.4642 11.0435 11.3524 11.09C11.2407 11.1365 11.1209 11.1605 10.9999 11.1605C10.8789 11.1605 10.7591 11.1365 10.6474 11.09C10.5357 11.0435 10.4343 10.9753 10.3491 10.8893L4.95909 5.49935H17.0408ZM18.3333 15.5827C18.3333 15.8258 18.2367 16.059 18.0648 16.2309C17.8929 16.4028 17.6597 16.4993 17.4166 16.4993H4.58325C4.34014 16.4993 4.10698 16.4028 3.93507 16.2309C3.76316 16.059 3.66659 15.8258 3.66659 15.5827V6.79185L9.05659 12.1818C9.57221 12.6968 10.2712 12.9861 10.9999 12.9861C11.7287 12.9861 12.4276 12.6968 12.9433 12.1818L18.3333 6.79185V15.5827Z"
        fill="black"/>
    </svg>
  );
};