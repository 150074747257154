export const RightSingleArrow = () => {
  return (
    <svg width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8711 5.62019C12.8209 5.49743 12.7456 5.38529 12.6495 5.29019L7.37465 0.290185C7.27629 0.196947 7.15951 0.122986 7.03099 0.072526C6.90247 0.0220657 6.76473 -0.00390625 6.62562 -0.00390625C6.34468 -0.00390625 6.07524 0.101882 5.87659 0.290185C5.77822 0.383424 5.7002 0.494114 5.64696 0.615936C5.59373 0.737758 5.56633 0.868326 5.56633 1.00019C5.56633 1.26649 5.67793 1.52188 5.87659 1.71019L9.358 5.00019H1.35075C1.07095 5.00019 0.802617 5.10554 0.604771 5.29308C0.406925 5.48062 0.295776 5.73497 0.295776 6.00019C0.295776 6.2654 0.406925 6.51976 0.604771 6.70729C0.802617 6.89483 1.07095 7.00019 1.35075 7.00019H9.358L5.87659 10.2902C5.77771 10.3831 5.69922 10.4937 5.64566 10.6156C5.5921 10.7375 5.56453 10.8682 5.56453 11.0002C5.56453 11.1322 5.5921 11.2629 5.64566 11.3848C5.69922 11.5066 5.77771 11.6172 5.87659 11.7102C5.97466 11.8039 6.09134 11.8783 6.2199 11.9291C6.34846 11.9798 6.48635 12.006 6.62562 12.006C6.76489 12.006 6.90278 11.9798 7.03134 11.9291C7.1599 11.8783 7.27658 11.8039 7.37465 11.7102L12.6495 6.71019C12.7456 6.61508 12.8209 6.50294 12.8711 6.38019C12.9766 6.13672 12.9766 5.86365 12.8711 5.62019Z"
        fill="#0072BA"/>
    </svg>
  );
};