import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {Box, Button, HStack, VStack} from '@chakra-ui/react';
import {HaliRenderOne, useHalState, useHalStates} from '@synako/haligator';
import {Resource, State} from '@synako/halitrek';
import {ArticleDesc, ItemCatalogueDesc, ItemListeSelectionDesc, LigneDePanierDesc, ListeDesc} from 'api-types/ffconnect-hal';
import {useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {TrashIcon} from '@/components/svg/TrashIcon';
import {usePanier} from 'ff-common';
import {PrixBlockUnitaire} from '@/domains/cadencier/components/PrixBlockUnitaire';
import {ArticleBlock} from '@/domains/panier/PanierFound/ArticleBlock';
import {PoidsVariableTooltip} from '@/domains/panier/PanierFound/ProduitPanier/PoidsVariableTooltip';
import {LabelDisponibilite} from '@/domains/catalogue/components/LabelDisponibilite';
import {useBuyingBlocks} from '@/hooks/panier/useBuyingBlocks';
import {LigneProduitLayout} from './LigneProduitLayout';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import React from 'react';
import {Flex} from '@chakra-ui/layout';

export const ListeSection:React.FC<{produits: Resource<ItemListeSelectionDesc>[], liste: State<ListeDesc> | undefined}> 
= ({produits, liste: selectedListState})=>{
  const {states:lignesDePanier} = useHalStates(usePanier().followAll('lignes_de_panier'));

  return <VStack alignItems='stretch'
    width='100%'
    mt='3'>
    <VStack gap='0'>
      {produits.map((p:Resource<ItemListeSelectionDesc>) => {
        return <LoadLigneListe 
          key={`ligne_liste_${p.uri}`}
          produit={p}
          listState={selectedListState}
          lignesDePanier={lignesDePanier}
        />;
      }
      )}
    </VStack>
  </VStack>;
};

const LoadLigneListe: React.FC<{
  produit: Resource<ItemListeSelectionDesc>;
  listState: State<ListeDesc> | undefined;
  lignesDePanier:State<LigneDePanierDesc>[]|undefined
}> 
  = React.memo(({produit, listState, lignesDePanier}) => {
    const {t} = useTranslation();
    const textToast = useSimpleTextToast();
    const onError = useDefaultOnError(textToast);
    const {state: articleState, loading: articleLoading} = useHalState(produit.follow('self').follow('article'));
  
    const deleteAction = useCallback(async ()=>{
      const code_article = articleState?.data.tolede.code_article;
      if (!code_article) {
        textToast(t('toast.produit_introuvable_supprime'));
        return;
      }
  
      try {
        await listState?.submitCommand('c:supprimer-article-d-une-liste', {liste_id: listState.data.id, code_article});
        textToast(t('toast.supprime_liste', {code_article}));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        onError(error);
      }
    }, [articleState?.data.tolede.code_article, textToast, t, listState, onError]);
  
    if(articleLoading) {
      return 'Chargement...';
    }
  
    return <>
      <HaliRenderOne nav={produit.follow('item_catalogue')}
        render={({state: itemState}) => {
          if (!articleState) {
            // eslint-disable-next-line i18next/no-literal-string
            return <div>Article dans le cadencier mais pas dans la base FF {itemState.data.tolede.code_article}</div>;
          }
          return <ProduitListe
            itemState={itemState}
            articleState={articleState}

            prixHT={itemState.data.tolede.prix_par_livraison![0].montant_ht}
            tauxTVA={itemState.data.tolede.taux_de_tva}

            deleteAction={deleteAction}
            somethingSubmitting={false}
        
            lignePanier={lignesDePanier?.find((l)=>l.data.code_article===itemState.data.tolede.code_article)}
          />;
        }}/>
    </>;
  });

const ProduitListe: React.FC<{
  itemState: State<ItemCatalogueDesc>,
  articleState: State<ArticleDesc>,
  prixHT:number,
  tauxTVA?:number,
  somethingSubmitting:boolean,
  deleteEnCours?: boolean,
  deleteAction?:()=>void,
  lignePanier:State<LigneDePanierDesc>|undefined
}> = React.memo(({
  itemState, articleState, prixHT, tauxTVA, 
  somethingSubmitting,
  deleteEnCours, deleteAction,
  lignePanier
})=>{
  const isMobile = useContext(BreakPointContext)?.isMobile;
  const precommande = itemState.data.tolede.precommande;
  
  const prixBlock = <PrixBlockUnitaire
    prixUnitaire={prixHT}
    uniteFacturation={itemState.data.tolede.unite_de_facturation} 
    tauxTVA={tauxTVA}
    style={{h:'100%', mt:{base:'0', md:'15px'}}}
  />;
  
  const deleteBlock = <Button variant='ghost'
    position={{base:'absolute', lg:'relative'}}
    m={{base:'0', lg:'initial'}}
    top={0}
    right={0}
    onClick={deleteAction}
    isDisabled={deleteEnCours ?? somethingSubmitting}
  >
    <TrashIcon />
  </Button>;
  
  const textToast = useSimpleTextToast();
  const {uniteQuantiteBlock, buyBlock} = useBuyingBlocks(textToast, itemState, lignePanier);
  const articleBlock = <ArticleBlock
    code_article={articleState.data.tolede.code_article}
    article={articleState.follow('self')}
    libelleFallback={lignePanier?.data.libelle}
  />;
  
  const selectBlock = <Box 
    mt={{base:'0', md:'15px'}}
    ml='0'
    minW='fit-content'
    w='100%'
  >
    {uniteQuantiteBlock({minW:{base:'150px', md:'245px'}, w:{base:'100%', md:'245px'}})}
    {articleState.data.tolede.a_peser && <PoidsVariableTooltip/>}
  </Box>;
  
  const precommandeBlock = precommande && <LabelDisponibilite precommande={precommande}/>;
  
  return <LigneProduitLayout precommande={precommande}>
    
    {!isMobile ?
    /* Version desktop */
      <HStack w='100%'
        flexWrap='wrap'>
        {precommandeBlock}
        <HStack>
          {articleBlock}
        </HStack>
        <HStack alignItems='start'
          ml='auto'>
          {prixBlock}
          {selectBlock}
          {buyBlock({somethingSubmitting, afficher_bouton_liste:true, minW:'280px', w:'280px'})}
          <Box mt='15px'>
            {deleteBlock}
          </Box>
        </HStack>
      </HStack>
    /* Version mobile */
      :<VStack alignItems='stretch'
        w='100%'>
        <HStack>
          {precommandeBlock}
          {articleBlock}
          {deleteBlock}
        </HStack>
        <Flex mt='15px'
          flexWrap='wrap'
          justifyContent='space-between'
          width='100%'>
          <Box flex='1 1 auto'
            marginBottom='10px'>{selectBlock}</Box>
          <Box flex='0 1 auto'
            marginLeft='auto'
            ml='5px'>{prixBlock}</Box>
        </Flex>
        <HStack w='100%'>
          {buyBlock({somethingSubmitting, afficher_bouton_liste:true, minW:'200px'})}
        </HStack>
      </VStack>
    }
  </LigneProduitLayout>;
});
