import {Box, HStack} from '@chakra-ui/react';
import {ContactComponent} from './components/ContactComponent';
import {HeaderImage} from './components/HeaderImage';
import {UserMenu} from './components/UserMenu';
import {ShippingWhiteIcon} from '../../components/svg/ShippingWhiteIcon';
import {CommandeDateTexte} from '@/domains/accueil/components/CommandeDateTexte';
import {useHalStates} from '@synako/haligator';
import {useLivraisons} from '@/haligator-factory';

export const HeaderBand: React.FC = () => {
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  const laProchaine = livraisonsPossibles.states?.[0];
  if (!laProchaine) {
    return;
  }
  return (
    <Box position='relative'
      backgroundColor='white'
      w='100vw'>
      <HeaderImage />
      <HStack
        position='absolute'
        top='0'
        left='0'
        alignItems='center'
        justify='center'
        width='100%'
        height='100%'
        pl={{base: '0', md: '4'}}
      >
        <Box display={{base: 'none', md: 'block'}}>
          <ShippingWhiteIcon />
        </Box>
        <Box textAlign={{base:'center', md:'start'}}
          p={1}>
          <CommandeDateTexte date_de_livraison={laProchaine.data.tolede.date_de_livraison}
            color="white"
            fontSize={{base:'2xs', sm:'xs', md:'sm'}}/>
        </Box>
        <HStack ml='auto'
          alignItems='center'
          spacing={0}
          display={{base: 'none', md: 'flex'}}>
          <ContactComponent />
          <Box height='20px'
            borderLeft='1px solid white'
            ml={2} />
          <UserMenu />
        </HStack>
      </HStack>
    </Box>
  );
};
