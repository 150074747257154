export const UserBlackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none">
      <path d="M15.0994 12.9965C15.7891 12.4569 16.2924 11.7169 16.5395 10.8795C16.7866 10.0421 16.7651 9.14893 16.478 8.32425C16.1909 7.49956 15.6525 6.78438 14.9376 6.2782C14.2228 5.77201 13.3671 5.5 12.4896 5.5C11.612 5.5 10.7563 5.77201 10.0415 6.2782C9.32665 6.78438 8.78822 7.49956 8.50112 8.32425C8.21401 9.14893 8.19251 10.0421 8.4396 10.8795C8.68669 11.7169 9.19008 12.4569 9.87974 12.9965C8.69799 13.4674 7.66687 14.2483 6.89631 15.2562C6.12575 16.264 5.64463 17.4609 5.50424 18.7192C5.49408 18.8111 5.50221 18.9041 5.52818 18.9928C5.55414 19.0816 5.59743 19.1643 5.65556 19.2365C5.77298 19.3821 5.94375 19.4754 6.13032 19.4958C6.31689 19.5162 6.50397 19.4621 6.6504 19.3453C6.79684 19.2285 6.89063 19.0587 6.91116 18.8731C7.06562 17.5056 7.72132 16.2425 8.75296 15.3253C9.7846 14.4082 11.1199 13.9011 12.5036 13.9011C13.8874 13.9011 15.2227 14.4082 16.2543 15.3253C17.286 16.2425 17.9416 17.5056 18.0961 18.8731C18.1152 19.045 18.1977 19.2038 18.3276 19.3188C18.4575 19.4338 18.6257 19.4968 18.7996 19.4958H18.877C19.0614 19.4747 19.2299 19.3819 19.3458 19.2378C19.4618 19.0937 19.5158 18.9098 19.496 18.7262C19.3549 17.4643 18.8712 16.2643 18.0967 15.255C17.3222 14.2456 16.2861 13.4649 15.0994 12.9965ZM12.4896 12.4998C11.933 12.4998 11.389 12.3357 10.9263 12.0282C10.4636 11.7207 10.1029 11.2837 9.88993 10.7723C9.67696 10.261 9.62123 9.69832 9.72981 9.15549C9.83838 8.61265 10.1064 8.11403 10.4999 7.72267C10.8934 7.33131 11.3948 7.06478 11.9406 6.95681C12.4864 6.84883 13.0522 6.90425 13.5664 7.11605C14.0805 7.32786 14.52 7.68653 14.8292 8.14672C15.1384 8.60692 15.3034 9.14796 15.3034 9.70143C15.3034 10.4436 15.0069 11.1554 14.4792 11.6802C13.9515 12.205 13.2358 12.4998 12.4896 12.4998Z"
        fill="#202125"/>
    </svg>
  );
};