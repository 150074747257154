export const OrderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none">
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9391 0.992805C8.26177 0.806865 8.62765 0.708984 9.00008 0.708984C9.37253 0.708984 9.73842 0.806873 10.0611 0.992828C10.0616 0.993112 10.0621 0.993396 10.0626 0.99368L15.0209 3.82701C15.3436 4.01334 15.6117 4.28125 15.7982 4.60387C15.9847 4.9265 16.083 5.29249 16.0834 5.66514V11.3333C16.083 11.7059 15.9847 12.0719 15.7982 12.3945C15.6117 12.7171 15.3436 12.9851 15.0209 13.1714L15.0182 13.173L10.0626 16.0047C10.0622 16.005 10.0617 16.0052 10.0613 16.0055C9.73858 16.1915 9.37261 16.2894 9.00008 16.2894C8.62757 16.2894 8.26161 16.1915 7.93889 16.0055C7.93846 16.0052 7.93802 16.005 7.93758 16.0047L2.98198 13.173L2.97925 13.1714C2.65653 12.9851 2.38848 12.7171 2.20199 12.3945C2.0155 12.0719 1.91713 11.7059 1.91675 11.3333V5.66514C1.91713 5.29249 2.0155 4.9265 2.20199 4.60387C2.38848 4.28125 2.65653 4.01334 2.97925 3.82701L2.98198 3.82544L7.9391 0.992805ZM9.00008 2.12565C8.87574 2.12565 8.75359 2.15838 8.64591 2.22055L8.64318 2.22213L3.68758 5.05388C3.68721 5.0541 3.68684 5.05431 3.68646 5.05453C3.57939 5.11661 3.49044 5.20567 3.42849 5.31284C3.36638 5.42029 3.3336 5.54218 3.33341 5.66629V11.3321C3.3336 11.4562 3.36638 11.5781 3.42849 11.6856C3.49044 11.7927 3.57939 11.8818 3.68646 11.9439C3.68684 11.9441 3.68721 11.9443 3.68758 11.9445L8.64592 14.7778C8.7536 14.84 8.87574 14.8727 9.00008 14.8727C9.12442 14.8727 9.24657 14.84 9.35425 14.7778L9.35698 14.7763L14.3126 11.9445C14.313 11.9443 14.3133 11.9441 14.3137 11.9439C14.4208 11.8818 14.5097 11.7927 14.5717 11.6856C14.6338 11.578 14.6666 11.456 14.6667 11.3318V5.66659C14.6666 5.54238 14.6338 5.42038 14.5717 5.31284C14.5097 5.20567 14.4208 5.11661 14.3137 5.05453C14.3133 5.05432 14.313 5.0541 14.3126 5.05388L9.35424 2.22056C9.24656 2.15839 9.12442 2.12565 9.00008 2.12565Z"
        fill="#202125"/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20322 4.57447C2.39911 4.23585 2.83241 4.12013 3.17104 4.31602L9.00011 7.68793L14.8292 4.31602C15.1678 4.12013 15.6011 4.23585 15.797 4.57447C15.9929 4.9131 15.8772 5.34641 15.5385 5.54229L9.35479 9.11938C9.13537 9.2463 8.86485 9.2463 8.64543 9.11938L2.46168 5.54229C2.12305 5.34641 2.00734 4.9131 2.20322 4.57447Z"
        fill="#202125"/>
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00008 7.79102C9.39128 7.79102 9.70841 8.10815 9.70841 8.49935V15.6393C9.70841 16.0306 9.39128 16.3477 9.00008 16.3477C8.60888 16.3477 8.29175 16.0306 8.29175 15.6393V8.49935C8.29175 8.10815 8.60888 7.79102 9.00008 7.79102Z"
        fill="#202125"/>
    </svg>
  );
};