import {Container, Spinner} from '@chakra-ui/react';
import {UseHalStateHook} from '@synako/haligator';
import {advanced} from '@synako/halitrek';

interface CheckLoadedProps<StateDesc extends advanced.AStateDescriptor> {
  hook: UseHalStateHook<StateDesc>,
  render: React.FC<{
    data: NonNullable<UseHalStateHook<StateDesc>['data']>
    state: NonNullable<UseHalStateHook<StateDesc>['state']>
  }>;
}

export function CheckLoaded<StateDesc extends advanced.AStateDescriptor>(props : CheckLoadedProps<StateDesc>) {
  const {loading, error, data, state} = props.hook;

  if (loading) {
    return (
      <Container py="30px"
        centerContent>
        <Spinner color="brand.500"
          aria-label="Chargement..." />
      </Container>
    );
  }

  if (error) {
    // eslint-disable-next-line i18next/no-literal-string
    return <div>Error: {error.message}</div>;
  }

  return props.render({state: state!, data: data!});
}

// const Test = () => {
//   const client = useHalState({} as Navigable<ClientState>);

//   <CheckLoaded 
//     hook={client} 
//     render={({state, data}) => {
//       state.hasCommand('c:changer-nom');
//       return <div>Nom: {data.nom}</div>
//     }}
//   />
// }
