import React, {useMemo} from 'react';
import {Box, Button, HStack, Heading, LinkOverlay, Text, VStack, useRadioGroup} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {BonsLivraisonsIcon} from '@/components/svg/BonsLivraisonsIcon';
import {VoirIcon} from '@/components/svg/VoirIcon';
import {dateAvecSlashes, dateEnTexte} from 'ff-common';
import {Link} from 'react-router-dom';
import {routes} from 'ff-common';
import {StatutBadge} from './StatutBadge';
import DataTable, {HeaderWithTooltip} from '@/components/DataTable';
import {createColumnHelper} from '@tanstack/react-table';
import {useTypedFilters} from '@synako/typed-path';
import {CommandeDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {useClient} from '@/haligator-factory';
import {useHalCollection} from '@synako/haligator';
import * as tolede from 'api-types/tolede';
import {RadioCard} from '@/chakra/components/Radio/RadioCard';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';

export const CommandesView: React.FC = () => {
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();

  const filters = useTypedFilters<{periode: tolede.PeriodeCommande}>({
    periode: 'en_cours',
  }, {
    periode: 'string',
  });

  const collection = useClient().follow('commandes', filters.params);
  const col = useHalCollection(collection);
  const nombreCommandes = col.items?.length;

  const columnHelper = createColumnHelper<State<CommandeDesc>>();
  const columns = useMemo(() => [
    columnHelper.accessor('data', {
      header: t('commande.commande', {count: col.loading || col.refreshing ? 1 : nombreCommandes}),
      cell: (ctx) => (
        <LinkOverlay
          as={Link}
          to={buildAssistanceRoute(routes.client.commande_detail, {cle_commande: ctx.getValue().tolede.cle_commande, client_id: ctx.getValue().tolede.code_client})}
          cursor="pointer"
        >
          <VStack align="start"
            spacing={1}>
            <HStack fontSize="xs"
              pt={{base:'0px', lg:'20px'}}>
              <Text>{t('commande.n°')}{ctx.getValue().tolede.numero} - {dateAvecSlashes(ctx.getValue().tolede.cree_le ?? t('commande.non_definie'))}</Text>
              {ctx.getValue().tolede.passee_avec !== 'autre' && (
                <Box borderRadius="4px"
                  borderWidth="1px"
                  borderColor="gray.dark"
                  p={1}>
                  <Text fontWeight="500"
                    fontSize='10px'>{t(`commande.${ctx.getValue().tolede.passee_avec}`)}</Text>
                </Box>
              )}
            </HStack>
            <HStack pb={{base:'0px', lg:'20px'}}>
              <Text color="blue.main"
                fontSize="sm"
                fontWeight="500"
                pt={1}>{t('commande.livraison_du')} {dateEnTexte(ctx.getValue().tolede.date_de_livraison_ou_d_enlevement)}</Text>
              <BonsLivraisonsIcon color='blue.main'
                height='30px'
                width='35px'/>
            </HStack>
          </VStack>
        </LinkOverlay>
      ),
      meta: {
        tdProps: {
          width: {
            lg: '35%',
          },
        },
      },
    }),
    columnHelper.accessor('data.tolede', {
      header: t('commande.produits'),
      cell: (ctx) => (
        <HStack>
          <Text>{t('global.produit', {count: ctx.getValue().nombre_de_lignes})}</Text>
          <Text>•</Text>
          <Text color="black.text"
            fontWeight="700"
            minWidth="80px"
            textAlign="right">{ctx.getValue().total_ttc} €</Text>
        </HStack>
      ),
      meta: {
        tdProps: {
          width: {
            lg: '25%',
          },
        },
      },
    }),
    // columnHelper.accessor('data.tolede', {
    //   header: t('commande.type'),
    //   cell: (ctx) => (
    //     <HStack>
    //       <Text color="black.text" minWidth="80px" textAlign="right">{t(`commande.${ctx.getValue().code_type_commande}`)}</Text>
    //     </HStack>
    //   ),
    //   meta: {
    //     tdProps: {
    //       width: {
    //         lg: '25%',
    //       },
    //     },
    //   },
    // }),
    columnHelper.accessor('data.tolede.statut', {
      header: () => <HeaderWithTooltip
        text={t('commande.statut')}
        tooltip={t('commande.statut_tooltip')}
      />,
      cell: (ctx) => (
        <Box w='100%'>
          <StatutBadge variant={ctx.getValue() ?? ''} />
        </Box>
      ),
      meta: {
        tdProps: {
          width: {
            lg: '20%',
          },
        },
      },
    }),
    columnHelper.accessor('data', {
      id: 'actions',
      header: t('commande.actions'),
      cell: (ctx) => (
        <Box display="flex"
          width="100%"
          height="100%">
          <Button
            leftIcon={<VoirIcon/>}
            variant="ghost"
            borderWidth="1px"
            borderRadius="8px"
            color="black.text"
            fontSize="xs"
            width={{base:'100%', lg:'140px'}}
            cursor='pointer'
            as={Link}
            to={buildAssistanceRoute(routes.client.commande_detail, {cle_commande: ctx.getValue().tolede.cle_commande, client_id: ctx.getValue().tolede.code_client})}
          >
            {t('commande.voir_le_detail')}
          </Button>
        </Box>
      ),
      meta: {
        tdProps: {
          width: {
            lg: '20%',
          },
        },
      },
    }),
  ], [t, columnHelper, nombreCommandes, col.loading, col.refreshing, buildAssistanceRoute]);

  const handlePeriodeChange = (value: string) => {
    filters.change('periode', value as tolede.PeriodeCommande);
  };

  const isLoading = col.loading || col.refreshing;

  return (
    <Box p={5}
      bg="gray.bg"
      width="100%">
      <Heading size={{base: 'md', md: 'lg'}}
        mb={6}
        mt="10px"
        ml={{base:'30px', lg:0}}
        color="black.text">
        {t('menu.commandes')}
      </Heading>
      <Box bg='white'
        p={{base: 4, md: 6}}
        borderRadius="8px"
        boxShadow="sm"
        w="100%">
        <HStack width="100%"
          justifyContent="space-between"
          mb={4}>
          <SelectionPeriode
            value={filters.params.periode}
            onChange={handlePeriodeChange}
            loading={isLoading}
          />
        </HStack>

        {!isLoading && col.items && col.items.length > 0 && (
          <Text fontSize={{base: 'sm', md: 'md'}}
            textAlign='left'
            fontWeight="400"
            color="gray.dark"
            my={4}
            w='100%'>
            {t('commande.nombre_commandes', {count: col.items.length})}
          </Text>
        )}

        <DataTable
          columns={columns}
          data={col.items ?? []}
          isLoading={isLoading}
          empty={
            <Text fontSize="md"
              textAlign='center'
              fontWeight="400"
              color="gray.dark"
              my={7}
              mt="50px">
              {t('commande.aucune_commande')}
            </Text>
          }
        />

      </Box>
    </Box>
  );
};

export default CommandesView;

interface SelectionPeriodeProps {
  value: tolede.PeriodeCommande;
  onChange: (value: tolede.PeriodeCommande) => void;
  loading: boolean;
}

export const SelectionPeriode: React.FC<SelectionPeriodeProps> = ({value, onChange, loading}) => {
  const {t} = useTranslation();

  const periodes: {value: tolede.PeriodeCommande; label: string}[] = [
    {value: 'en_cours', label: t('commande.en_cours')},
    {value: '2_derniers_mois', label: t('commande.2_derniers_mois')},
    {value: '3_6_mois', label: t('commande.3_6_mois')}
  ];

  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'periode',
    defaultValue: value,
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group}
      spacing={4}>
      {periodes.map((periode) => {
        const radio = getRadioProps({value: periode.value});
        return (
          <RadioCard key={periode.value}
            {...radio}
            loading={loading}>
            {periode.label}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
