export const fr = {
  authentification:{
    'bienvenu': 'Bienvenue',
    'commande_en_ligne': 'Commande en ligne',
    'compte_france_frais': 'Vous avez un compte ?',
    'connectez_vous': 'Connectez-vous',
    'premiere_connexion': 'Première connexion ?',
    'utiliser_email': 'Utilisez votre adresse email de contact pour vous enregistrer et accéder à votre compte.',
    'activer_compte': 'Connectez-vous',
    'devenir_client': 'Devenir client France Frais',
    'contactez_nous': 'Contactez-nous',
    'tout_proche': 'France Frais, tout proche',
    'de_vous': 'de vous !',
    error: {
      'erreur_rencontree': 'Une erreur a été rencontrée',
      'valider_email': "Vous devez valider que vous êtes propriétaire de l'adresse email en cliquant sur l'email qui vous a été envoyé.",
      'email_envoye': 'Email envoyé, veuillez vérifier votre boîte email.',
      're_envoyer': 'Ré-envoyer',
      'erreur': 'Erreur',
      'contacter_commercial': 'veuillez contacter votre commercial.',
      'email_correspond_plus': 'Votre email ne correspond plus à un contact de notre base client. Veuillez contacter votre commercial.',
      'no_sandbox': 'Vous ne pouvez pas vous connecter à la sandbox.<br /><br />Pour accéder à France Frais Connect veuillez utiliser le lien suivant :<br /><br /><Link>https://ffconnect.france-frais.fr</Link>.',
      'switched': 'Vous avez changé de compte client dans un autre onglet. <br /><Link>Recharger</Link>',
    },
  },
  global:{
    'produit': '{{count}} produit',
    'produit_one': '{{count}} produit',
    'produit_other': '{{count}} produits',
    'produit_many': '{{count}} produits',
    'produit_plural': '{{count}} produits',
    'panier': '{{count}} panier',
    'panier_one': '{{count}} panier',
    'panier_other': '{{count}} paniers',
    'panier_many': '{{count}} paniers',
    'panier_plural': '{{count}} paniers',
    'colis': '{{count}} colis',
    'colis_one': '{{count}} colis',
    'colis_other': '{{count}} colis',
    'colis_many': '{{count}} colis',
    'colis_plural': '{{count}} colis',
    
    'annuler': 'Annuler', 
    'valider': 'Valider', 
    'retour': 'Retour', 
    'voir': 'Voir', 
    'remplacer': 'Remplacer', 
    'consulter': 'Consulter', 
    'choisir':'Choisir',
    'creer': 'Créer la liste',
    'client': '{{count}} client',
    'client_one': '{{count}} client',
    'client_other': '{{count}} clients',
    'client_many': '{{count}} clients',
    'client_plural': '{{count}} clients',
    'a_valider': 'A valider',
    'promotion': 'Promotion',
    'produit_marche': 'Produit marché',
    'accueil': 'Accueil',
    'catalogue': 'Catalogue',
    'cadencier': 'Cadencier',
    'mon_cadencier': 'Mon cadencier',
    'listes': 'Listes',
    'mes_listes': 'Mes listes',
    'profil': 'Profil',
    'recherche': 'Recherche',
    'chargement': 'Chargement',
    'revenir': 'Revenir',
    'connecter': 'Se connecter',

    'voir_mon_panier': 'Voir mon panier',
    'choisir_livraison':'Choisir une livraison',
    'logo': 'logo',
    'raison_sociale': 'raison sociale',
    'code': 'code',
    'attention': 'Attention',
    'modifier': 'Modifier',
    'compris': 'Compris',
    'jai_compris': 'J’ai compris',

    'alt_logo_france_frais': 'Logo France Frais',

    'jour': 'jour',
    'mois': 'mois',
    'mois_precedent': 'Mois précédent',
    'mois_suivant': 'Mois suivant',
  },
  header_band:{
    'temps_restant': 'Pensez à commander au plus tôt pour votre prochaine livraison du <Text>{{date_de_livraison}}</Text> !',
    'contactez_nous': 'Contactez-nous'
  },
  cadencier:{
    'cta_panier': 'Aller au panier',
    'mon_cadencier': 'Mon Cadencier',
    'quitter_edition': 'Quitter le mode édition',
    'chargement_cadencier': 'Chargement de votre cadencier',
  },
  temperature_livraison: {
    'la_livraison_du': 'La livraison du',
    'surgeles_uniquement': 'Surgelés uniquement',
    'non_surgeles_uniquement': 'Non surgelés uniquement',
    'livraison_surgelee': 'Livraison surgelée',
    'pas_de_surgele': 'Pas de surgelé',
    'livraison_non_surgelee': 'Livraison non surgelée',
    'livraison_surgeles_uniquement': 'Livraison surgelées uniquement',
    'livraison_non_surgeles_uniquement': 'Livraison non surgelés uniquement',
    'est_livraison_surgeles_uniquement': 'est une livraison surgelés uniquement.',
    'est_livraison_non_surgeles_uniquement': 'est une livraison non surgelés uniquement.',
    'warning_temperature_ignoree': 'Attention : les produits affichés peuvent ne pas être disponibles pour cette température de livraison'
  },
  toast:{
    'ajoute_panier_date': 'Produit <strong>ajouté au panier du {{date, datetime}}</strong>',
    'modifie_panier_date': 'Quantité du produit modifiée <strong>dans votre panier du {{date, datetime}}</strong>',
    'supprime_panier':'Produit supprimé du panier',
    'supprime_cadencier': 'Le produit n°{{code_article}} a été supprimé du cadencier',
    'supprime_cadencier_simulation':"Le produit n°{{code_article}} n'a pas réellement été supprimé du cadencier",
    'supprime_cadencier_safelive':"Le produit n°{{code_article}} a réellement été supprimé du cadencier.<br />Pour qu'il soit pris en compte, ajoutez-le du cadencier tout de suite",
    'supprime_liste': 'Le produit n°{{code_article}} a été supprimé de la liste',
    'warning_minimum': 'La quantité minimum est de {{minimum}}',
    'warning_arrondis': 'La quantité a été ajustée au multiple supérieur le plus proche (multiple:{{multiple}})',
    'ajout_preco': 'Le produit a été ajouté au panier du {{date, datetime}}',
    'unite_switchee': 'La quantité a changé pour faire <strong>{{quantite}} colis</strong>',
    'produit_introuvable_supprime':'Le produit est introuvable et ne peut être supprimé.',
    'ajout_nouvelle_liste':'La liste {{newListName}} a été créée',
    'ajout_liste':'L’article a été ajouté à la liste',
    'ajout_cadencier':'Le produit a été ajouté au cadencier',
    'ajout_cadencier_simulation':"Le produit n'a pas réellement été ajouté au cadencier",
    'ajout_cadencier_safelive':"Le produit a réellement été ajouté au cadencier.<br />Pour qu'il ne soit pas pris en compte, retirez-le du cadencier tout de suite",
    'panier_deplace':'Panier déplacé au <strong>{{date, datetime}}</strong>',
    'panier_vidé':'Le panier du {{date}} a été vidé',
    'produit_differe':'Produit <strong>déplacé au panier du {{date}}.</strong>',
    'produit_differe_cta':'N’oubliez pas de le valider au plus tôt.',
    'produit_remplace':'Produit remplacé par {{libelle}}',
    'voir_panier':'Voir le panier',
    'voir_liste': 'Voir la liste',
    'produit_precommande': 'Attention produit en précommande',
    'quantite_ajustee': 'La quantite a été ajustée à {{quantite}} {{unite}}',
    'information_rafraichit': 'Les informations du client ont été mises à jour avec succès.',
    'catalogue_rafraichit': 'Le catalogue client a été mis à jour avec succès.',
    'panier_valide_simulation': "Votre panier a été controlé mais n'a pas réellement été transformé en commande dans Tolede.",
    'panier_valide_safelive': "Votre panier a réellement été transformé en commande dans Tolede.<br />Pour ne pas qu'elle soit préparée, il faut l'annuler tout de suite.",
    'prolonge_session': 'Votre session a été prolongée avec succès.',
    'prolongement_session_impossible': 'Impossible de prolonger votre session.',
  },
  panier:{
    'panier': 'Panier',
    'panier_vide_cta': 'Commandez maintenant !',
    'panier_vide_cta_top': 'N’oubliez pas de valider vos paniers ouverts au plus tôt !',
    'depuis_cadencier': 'Depuis mon cadencier',
    'depuis_catalogue': 'Depuis le catalogue', 
    'depuis_listes': 'Depuis mes listes', 
    'remplacement_de': 'Remplacement de {{produit}}',
    'pour_la_livraison_du': 'Pour la livraison du <strong>{{date, datetime}}</strong>',
    'pour_la_livraison_du_mobile': 'Livraison du <strong>{{date, datetime}}</strong>',
    'nous_validons':'Nous validons votre panier',
    'menu_tooltip': 'Afficher le panier du {{date}}',
    'changer_date': 'Changer la date',
    'x_panier_a_valider': '{{count}} panier à valider',
    'x_panier_a_valider_one': '{{count}} panier à valider',
    'x_panier_a_valider_other': '{{count}} paniers à valider',
    'x_panier_a_valider_plural': '{{count}} paniers à valider',
    'liste_livraisons_prompt_mobile': 'Commander pour une autre date',
    'supprimer_panier_confirm':'Voulez-vous vraiment vider le panier ?',
    'supprimer_panier_action':'Vider le panier',
    'panier_en_cours': 'Panier en cours',
    'commande_existant': 'Commande existante pour ce jour de livraison',
    'listes': '{{count}} liste',
    'listes_one': '{{count}} liste',
    'listes_other': '{{count}} listes',
    'listes_plural': '{{count}} listes',
    vide:{
      'votre_panier_est_vide': 'Votre panier est vide',
      'merci': 'Merci',
      'pour_votre_commande': 'pour votre commande !',
      'vous_allez_recevoir_email': 'Vous allez recevoir un email de confirmation dans quelques instants.',
      'consulter_detail_commande': 'Vous pouvez consulter le détail de votre commande à tout moment dans la section "Mes commandes".',
      'pour_toute_question': 'Pour toute question concernant votre commande, nous vous invitons à contacter votre conseiller.', 
      'vous_avez_x_panier': 'Vous avez {{count}} panier à valider.',
      'vous_avez_x_panier_one': 'Vous avez {{count}} panier à valider.',
      'vous_avez_x_panier_other': 'Vous avez {{count}} paniers à valider.',
      'vous_avez_x_panier_plural': 'Vous avez {{count}} paniers à valider.',
      'validez_pour_reserver': 'Validez vos paniers en cours pour réserver vos stocks.',
      'n_oubliez_pas_de_valider_restant': 'N’oubliez pas de valider<br/>votre panier restant',
      'n_oubliez_pas_de_valider_restant_one': 'N’oubliez pas de valider<br/>votre panier restant',
      'n_oubliez_pas_de_valider_restant_other': 'N’oubliez pas de valider<br/>vos paniers restants',
      'n_oubliez_pas_de_valider_restant_many': 'N’oubliez pas de valider<br/>vos paniers restants',
      'n_oubliez_pas_de_valider_restant_plural': 'N’oubliez pas de valider<br/>vos paniers restants',
      'prenez_de_lavance': 'Prenez de l’avance !',
      'preparez_prochaine_livraison':'Préparez dès maintenant votre prochaine livraison',
      'alt_carton_vide': 'Carton de commande vide',
    },
    recap:{
      'recapitulatif': 'Récapitulatif',
      'valider_le_panier': 'Valider le panier',
      'total_ht': 'Total HT',
      'total_tva': 'Total TVA',
      'total_ttc': 'Total TTC',
      'tooltip_total_ht':'Montant estimé sur la base des quantités commandés et sera ajusté lors de la livraison',
      'accepter_conditions':"J'accepte <Link>les conditions générales de vente</Link>",
      mode_de_rendu:{
        'enlevement':'Retrait à l’entrepôt',
        'livraison':'Livraison'
      },
      modale_adresse:{
        'titre_enlevement': 'Récupérez votre commande à votre entrepôt',
        'titre_livraison': 'Livraison'
      },
      'reference_commande': 'Référence commande',
    },
    validez: {
      plus_de_2_jours: 'Validez votre panier pour bloquer les quantités.',
      moins_de_2_jours: 'Plus que <strong>{{date}}</strong> pour être livré à la date choisie.'
    }
  },
  bouton_achat:{
    'buy':'Ajouter au panier',
    'preco':'Précommander'
  },
  ligne_produit:{
    'soit_prix': 'soit {{prix}}€',
    'retirer_le_produit':'Retirer',
    'poids_variable': 'Poids variable',
    'poids_variable_label': 'Le produit sera facturé au poids réel',
    'tva_percent': 'TVA {{tauxTVA}}%',
    'HT':'HT',
    'encart_dlc':'DLC estimée : <strong>{{date}}</strong>',
    'encart_dlc_tooltip':'DLC du jour pour une préparation du jour',
    indisponible: {
      rupture_partielle: 'Seulement <strong>{{quantite_max}}</strong> est disponible <i>(quantité demandée: {{quantite_demande}})</i>',
      rupture_partielle_one: 'Seulement <strong>{{quantite_max}}</strong> est disponible <i>(quantité demandée: {{quantite_demande}})</i>',
      rupture_partielle_other: 'Seulement <strong>{{quantite_max}}</strong> sont disponibles <i>(quantité demandée: {{quantite_demande}})</i>',
      rupture_partielle_plural: 'Seulement <strong>{{quantite_max}}</strong> sont disponibles <i>(quantité demandée: {{quantite_demande}})</i>',
      rupture: 'Rupture ({{quantite}} {{unite}} au panier)',
      generique: 'Un problème empêche la commande de cet article.',
      date_dispo: 'Disponible à partir du <strong>{{date}}</strong>',
      remplacer: 'Remplacer',
      differer: 'Différer',
      ajuster_quantite: 'Ajuster la quantité à {{quantite}} {{unite}}',
      tooltip_ajuster_quantite: 'Ajuster à {{quantite}} {{unite}} au panier',
      tooltip_rupture_totale: 'Retirer du panier',
      entete: '<strong>{{count}} produit</strong> est indisponible pour le <strong>{{dateDeLivraison}}</strong>',
      entete_one: '<strong>{{count}} produit</strong> est indisponible pour le <strong>{{dateDeLivraison}}</strong>',
      entete_other: '<strong>{{count}} produits</strong> sont indisponibles pour le <strong>{{dateDeLivraison}}</strong>',
      entete_plural: '<strong>{{count}} produits</strong> sont indisponibles pour le <strong>{{dateDeLivraison}}</strong>',
      'produit_indisponible': 'Produit indisponible pour le {{dateDeLivraison}}',
    }  
  },
  disponibilite:{
    'precommande': 'Précommande',
    'a_partir': 'A partir du {{date}}',
  },
  modale_livraison:{
    header:{
      preco: 'Choisissez votre date de livraison',
      changement_panier: 'Choisir une autre date de livraison',
      default: 'Commander pour une autre date',
    },
    preco:{
      title: 'Produit en précommande',
      info: 'Ce produit ne sera disponible qu’à partir des dates ci-dessous.',
      date: 'Dates disponibles après le {{dateDebut, datetime}} :'
    },
    valider_date: 'Changer la date de livraison',
    afficher_plus: 'Afficher plus de date',
    'nous-requetons-les-disponibilites-de-larticle': 'Nous requêtons les disponibilités de l’article'
  },
  erreur404:{
    alt_img_carton: 'carton vide au fond duquel est marqué "Oups!"',
    c_est_bien_vide_ici: 'Humm... C’est bien vide ici',
    pas_de_panique: 'Pas de panique, par ici pour le remplir :',
    voir_cadencier: 'Voir mon cadencier',
    voir_catalogue: 'Voir le catalogue',
    retour_accueil: 'Retour à l’accueil'
  },
  error:{
    introuvable_en_db:'L’objet est introuvable en base de données.',
    cgv_requise:'L’acceptation des conditions générales de vente est requise.',
    echec_call_api:'Une erreur est survenue.',
    item_catalogue_introuvable:'Article non trouvé dans le catalogue.',
    quantite_invalide:'La quantité n’est pas un multiple de commande client.',
    code_adresse_de_livraison_inconnu:'Cette adresse de livraison n’existe pas pour ce client.',
    livraison_introuvable:'Aucune livraison pour cette date et pour ce client.',
    quantite_en_colis_indisponible:'La quantité en colis n’est pas disponible pour cet article.',
    prix_a_zero:'Le prix catalogue de cet article n’est pas renseigné.',
    label_asset_introuvable:'Le chargement d’une image a échoué.',
    probleme_bloquant_sur_commande:'L’enregistrement de la commande a échoué, vérifiez votre panier.',
    article_deja_present_dans_le_panier:'L’article est déjà présent dans le panier de la livraison cible.',
    changement_mode_de_rendu_impossible: 'Impossible de changer le mode de rendu pour cette livraions.',
    doublon_liste: 'Le produit est déjà présent dans cette liste.',
    quantite_max_depassee: 'Quantité en stock insuffisante pour le produit <strong>{{libelle}}</strong><br />Maximum commandable: <strong>{{max}} {{unite}}</strong>.',
    article_inconnu:"Informations de l'article introuvables.",
    medias_non_touves:"Problème lors de la récupération des médias associés à l'article.",
    panier_introuvable_en_db:'Ce panier est introuvable,<br /><strong><Reload>veuillez rafraîchir votre page.</Reload></strong>.',
    ligne_de_panier_introuvable_en_db:'Cette ligne de panier est introuvable,<br /><strong><Reload>veuillez rafraîchir votre page.</Reload></strong>.',
    panier_tolede_mode_desactive: "La transformation d'un panier en commande Tolede n'est pas autorisée.",
    cadencier_tolede_mode_desactive: 'Les actions sur le cadencier ne sont pas autorisées.',
    colis_ajuste: 'La quantité a été ajustée au colis le plus proche.',
    //Validation panier
    controle_panier: "Une erreur s'est produite lors de la validation du panier.<br />Veuillez réessayer ultérieurement.",
    commande_panier: "Le panier n'a pas pu être validé, veuillez réessayer ultérieurement.<br /><Contact>Si le problème persiste, merci de nous contacter</Contact>.",
    controle_dispo: "Nous n'avons pas pu vérifier la disponibilité de l'article.<br />Veuillez réessayer ultérieurement.",
    client_bloque : 'Vous ne pouvez pas effectuer de commande depuis ce compte, <Contact>veuillez nous contacter.</Contact>.',
    // franco_poids_non_atteint: 'Poids minimum de franco non atteint',
    // franco_prix_non_atteint : 'Prix minimum de franco non atteint',
    generique : 'Une erreur dans la commande empêche son traitement.',
    quantite_trop_importante : 'Quantité trop importante pour un ou plusieurs produits.',
    rupture : 'Le produit {{libelle}} est en rupture de stock.',
    erreur_date_livraison : 'La date de livraison est antérieure à la date actuelle. Modifiez la date de votre commande.',

    // Cadencier
    deja_au_cadencier: "L'article est déjà présent dans le cadencier.",
    
    //Erreurs externes qu'on traduit en front
    "la valeur d'une clé dupliquée rompt la contrainte unique « liste_selection_pkey »":'$t(error.doublon_liste)',
    'duplicate key value violates unique constraint "liste_selection_pkey"':'$t(error.doublon_liste)',
    dispo:{
      'erreur_generique': 'Une erreur liée à la disponibilité de l’article est survenue. Vérifiez votre panier.',
      'erreur_quantite_insuffisante': 'Stock insuffisant pour cet article. Vérifiez votre panier.'
    },

    //Différer la livraison
    'date_differer_manquante': 'Erreur: La date de la prochaine livraison de cet article n’a pas été communiquée',

    //Modale sur la rupture à l'ajout au panier
    'rupture_article': 'La quantité souhaitée pour votre article est indisponible.',

    // formulaire ajout société commerciale
    no_client: 'Aucun client pour cette société commerciale.',
    no_tournee: 'Aucune tournée de livraison programmée pour cette société commerciale.'
  },
  unite:{
    'B': 'bouteille',
    'B_one': 'bouteille',
    'B_other': 'bouteilles',
    'B_many': '$t(unite.B_other)',
    'B_plural': '$t(unite.B_other)',
    'BA': 'barquette',
    'BA_one': 'barquette',
    'BA_other': 'barquettes',
    'BA_many': '$t(unite.BA_other)',
    'BA_plural': '$t(unite.BA_other)',
    'BO': 'bocal',
    'BO_one': 'bocal',
    'BO_other': 'bocaux',
    'BO_many': '$t(unite.BO_other)',
    'BO_plural': '$t(unite.BO_other)',
    'BT': 'boite',
    'BT_one': 'boite',
    'BT_other': 'boites',
    'BT_many': '$t(unite.BT_other)',
    'BT_plural': '$t(unite.BT_other)',
    'BX': 'box',
    'BX_one': 'box',
    'BX_other': 'boxes',
    'BX_many': '$t(unite.BX_other)',
    'BX_plural': '$t(unite.BX_other)',
    'CO': 'colis',
    'CO_one': 'colis',
    'CO_other': 'colis',
    'CO_many': '$t(unite.CO_other)',
    'CO_plural': '$t(unite.CO_other)',
    'CT': 'Cent',
    'CT_one': 'Cent',
    'CT_other': 'Cent',
    'CT_many': '$t(unite.CT_other)',
    'CT_plural': '$t(unite.CT_other)',
    'FU': 'fut',
    'FU_one': 'fut',
    'FU_other': 'futs',
    'FU_many': '$t(unite.FU_other)',
    'FU_plural': '$t(unite.FU_other)',
    'KG': 'kg',
    'KG_one': 'kg',
    'KG_other': 'kg',
    'KG_many': '$t(unite.KG_other)',
    'KG_plural': '$t(unite.KG_other)',
    'L': 'litre',
    'L_one': 'litre',
    'L_other': 'litres',
    'L_many': '$t(unite.L_other)',
    'L_plural': '$t(unite.L_other)',
    'LO': 'lot',
    'LO_one': 'lot',
    'LO_other': 'lots',
    'LO_many': '$t(unite.LO_other)',
    'LO_plural': '$t(unite.LO_other)',
    'MI': 'Mille',
    'MI_one': 'Mille',
    'MI_other': 'Milles',
    'MI_many': '$t(unite.MI_other)',
    'MI_plural': '$t(unite.MI_other)',
    'PI': 'pièce',
    'PI_one': 'pièce',
    'PI_other': 'pièces',
    'PI_many': '$t(unite.PI_other)',
    'PI_plural': '$t(unite.PI_other)',
    'PO': 'portion', //Pot/Portion
    'PO_one': 'portion',
    'PO_other': 'portions',
    'PO_many': '$t(unite.PO_other)',
    'PO_plural': '$t(unite.PO_other)',
    'PT': 'plateau',
    'PT_one': 'plateau',
    'PT_other': 'plateaux',
    'PT_many': '$t(unite.PT_other)',
    'PT_plural': '$t(unite.PT_other)',
    'SA': 'sac',
    'SA_one': 'sac',
    'SA_other': 'sacs',
    'SA_many': '$t(unite.SA_other)',
    'SA_plural': '$t(unite.SA_other)',
    'SO': 'seau',
    'SO_one': 'seau',
    'SO_other': 'seaux',
    'SO_many': '$t(unite.SO_other)',
    'SO_plural': '$t(unite.SO_other)',
    'colis': 'colis',
    'colis_one': 'colis',
    'colis_other': 'colis',
    'colis_many': '$t(unite.colis_other)',
    'colis_plural': '$t(unite.colis_other)'
  },
  month: {
    1: 'janvier',
    2: 'février',
    3: 'mars',
    4: 'avril',
    5: 'mai',
    6: 'juin',
    7: 'juillet',
    8: 'août',
    9: 'septembre',
    10: 'octobre',
    11: 'novembre',
    12: 'décembre',
  },
  countries: {
    'FR': 'France',
    'ES': 'Espagne',
    'IT': 'Italie',
    'DE': 'Allemagne',
    'GB': 'Royaume-Uni',
    'US': 'États-Unis',
    'CN': 'Chine',
    'IN': 'Inde',
    'JP': 'Japon',
    'BR': 'Brésil',
    'RU': 'Russie',
    'CA': 'Canada',
    'AU': 'Australie',
    'KR': 'Corée du Sud',
    'MX': 'Mexique',
    'ID': 'Indonésie',
    'TR': 'Turquie',
    'NL': 'Pays-Bas',
    'SA': 'Arabie saoudite',
    'CH': 'Suisse',
    'PL': 'Pologne',
    'SE': 'Suède',
    'BE': 'Belgique',
    'AR': 'Argentine',
    'TH': 'Thaïlande',
    'AT': 'Autriche',
    'NO': 'Norvège',
    'IR': 'Iran',
    'ZA': 'Afrique du Sud',
    'DK': 'Danemark',
    'SG': 'Singapour',
    'MY': 'Malaisie',
    'CL': 'Chili',
    'UA': 'Ukraine',
    'SK': 'Slovaquie',
    'BG': 'Bulgarie',
    'EE': 'Estonie',
    'LT': 'Lituanie',
    'NZ': 'Nouvelle-Zélande',
    'LV': 'Lettonie',
    'CZ': 'République tchèque',
    'IS': 'Islande',
    'RO': 'Roumanie',
    'HR': 'Croatie',
    'MT': 'Malte',
    'HU': 'Hongrie',
    'CY': 'Chypre',
    'SI': 'Slovénie',
    'PT': 'Portugal',
    'IE': 'Irlande',
    'GR': 'Grèce',
    'FI': 'Finlande',
    'LU': 'Luxembourg',
    'MA': 'Maroc',
    'BQ': 'Pays-Bas caribéens',
    'IO': 'Territoire britannique de l\'océan Indien',
    'EG': 'Égypte',
    'BD': 'Bangladesh',
    'MG': 'Madagascar',
    'BA': 'Bosnie-Herzégovine',
    'VN': 'Vietnam',
    'PE': 'Pérou',
    'CI': 'Côte d\'Ivoire',
    'GL': 'Groenland',
    'LI': 'Liechtenstein',
    'AM': 'Arménie',
    'MN': 'Mongolie',
    'MD': 'Moldavie',
    'UY': 'Uruguay',
    'RS': 'Serbie',
    'GE': 'Géorgie',
    'FO': 'Îles Féroé',
    'GP': 'Guadeloupe',
    'PH': 'Philippines',
    'BO': 'Bolivie',
    'TN': 'Tunisie',
    'MU': 'Maurice'
  },
  menu: {
    'compte': 'Mon compte',
    'informations': 'Mes informations',
    'commandes': 'Mes commandes',
    'factures_et_avoirs': 'Mes factures',
    'bons_de_livraison': 'Mes bons de livraison',
    'preferences': 'Mes préférences',
    'changer_de_client': 'Changer de client',
    'deconnecter': 'Me déconnecter',
    'contacts': 'Mes contacts',
  },
  menu_livraison: {
    tooltip: 'Cliquez pour commander pour une autre date.',
    livraison: 'Livraison du <strong>{{date}}</strong>'
  },
  bon_de_livraison: {
    'numero_de_commande': 'Numéro de commande',
    'date_de_commande': 'Date de commande',
    'total_de_commande': 'Total de commande',
    'bon_de_livraison_titre': 'Bon de livraison',
    'commande': 'Commande',
    'action': 'Action',
    'telecharger': 'Télécharger',
    'telecharger_tout': 'Télécharger tout',
    'bon_de_livraison': '{{count}} BON DE LIVRAISON',
    'bon_de_livraison_one': '{{count}} BON DE LIVRAISON',
    'bon_de_livraison_other': '{{count}} BONS DE LIVRAISON',
    'bon_de_livraison_many': '{{count}} BONS DE LIVRAISON',
    'bon_de_livraison_plural': '{{count}} BONS DE LIVRAISON',
    '2_derniers_mois': 'Les 2 derniers mois',
    '3_6_mois': 'De 3 à 6 mois',
    'N°': 'N°',
    'aucun_bon_de_livraison': 'Vous n’avez aucun bon de livraison sur cette période.',
  },
  liste: {
    'listes': 'Mes listes',
    'creer_liste': 'Créer une nouvelle liste',
    'veuillez_entrer_nom_liste': 'Veuillez entrer le nom de la nouvelle liste',
    'veuillez_entrer_nouveau_nom_liste': 'Veuillez entrer le nouveau nom de la liste',
    'nom_liste_placeholder':'Nom de la nouvelle liste',
    'liste_supprimee': 'La liste {{nom}} a été supprimée',
    'liste_renommee': 'La Liste a été renommée',
    'syntaxe_nom_liste': 'Seuls les lettres, les chiffres et les espaces sont autorisés',
    'ajouter_a_une_liste':'Ajouter à une liste',
    'ajouter_au_cadencier':'Ajouter au Cadencier',
    'aria_label_modifier': 'Modifier le nom de la liste',
    'aria_label_enregistrer': 'Enregistrer le nouveau nom de la liste',
    'aria_label_renommer': 'Renommer la liste',
    'chargement_listes': 'Chargement de vos listes',
    'ajouter_article': 'Ajouter un article',
    'veuillez-selectionner-ou-ajouter-larticle': 'Veuillez sélectionner où ajouter l’article',
    'editer_nom_liste': 'Editer le nom de la liste',
    'supprimer_liste_confirm':'Voulez-vous vraiment supprimer cette liste ?',
    'supprimer_liste_action':'Supprimer la liste',
  },
  back_office: {
    'unauthorized': 'Vous devez être connecté avec votre email France Frais.',
    'clients_bloques': 'Bloqué',
    'client_actif': 'Ouvert',
    'clients_ouverts': 'Statut Ouvert',
    'chercher_client': 'Chercher un client',
    'metier': 'Métier',
    'liste_clients': 'Liste des clients',
    'clients_avec_panier': 'Avec panier ouvert',
    'derniere_connexion': 'Dernières connexions',
    'adresse_depot': "Adresse du dépôt d'enlèvement",
    'adresse_magasin': 'Adresse du magasin',
    'liste_societes_commerciales': 'Liste des sociétés commerciales',
    'nom_etablissement': `Nom de l'établissement`,
    'filiale': 'filiale',
    'paniers': 'Paniers',
    'panier_en_cours': 'Panier en cours',
    'statut': 'statut',
    'nombre_commandes': 'Nombre de commandes',
    'statut_tooltip': "Règle métier Tolede pour savoir si le client peut commander dans Tolede ou non (indépendamment d'un aspect digital)",
    'derniere_connexion_tooltip': 'Dernières connexions à France Frais Connect',
    'paniers_tooltip': 'Nombre de paniers ouverts',
    'cgv': 'CGV',
    'pas_de_client': 'Pas de client',
    'prochaine_date_livraison': 'Prochaine livraison le {{date_de_livraison}}',
    'cutoff_non_disponible': 'Cutt off non disponible',
    'selection_commercial': 'Par Commercial',
    multicanal: 'Actif multi-canal',
    activation: 'Activation',
    activation_tooltip: "Statut d'Activation",
    'jamais_validé': 'Jamais validé',
    details_client: {
      'assistance_client': 'Assistance client',
      'informations_livraison': 'Informations de livraison',
      'informations_client': 'Informations client',
      'contacts': 'Contacts',
      'code_client': 'Compte client',
      'paniers': 'Paniers',
      'panier_en_cours': 'Panier en cours',
      'pas_de_panier_en_cours': 'Pas de panier en cours',
      'information_a_renseigner': 'Les informations sont à renseigner dans Tolede.',
      'rafraichissement': 'Rafraîchissement ...',
      'rafraichir_information': 'Rafraichir les informations',
      'rafraichir_catalogue': 'Rafraichir le catalogue',
      'affiche_dlc_modifie': "L'affichage des DLC a été modifié.",
      'affichage_dlc': 'Afficher les DLC',
      'affiche_produit_marche_modifie': "L'affichage du produit marché a été modifié.",
      'affichage_produit_marche': 'Afficher les Produits marché',
      'denomination_sociale': 'Dénomination sociale',
      'pas_de_contact': 'Pas de contact renseigné',
      'cgv_tooltip': "Date d'acceptation des CGV",
      'message': 'Message',
      'date': "Date de l'event",
      'telecharger': 'Payload',
      'identifiant_panier': 'Identifiant Panier',
      'statut_panier': 'Statut du panier',
      'quinze_derniers_jours': 'Sur les 15 derniers jours.',
      'validé': 'Validé',
      'nombre_produits': 'Nombre de produits',
      'no_events': "Pas de validation ou d'échec de validation de panier sur les 30 derniers jours.",
    }
  },
  metier: {
    'A1': 'RESTAURATION SOCIALE',
    'A2': 'RESTAURATION COMMERCIALE',
    A3: 'A REAFFECTER B1 B2 B3 A2',
    A4: 'MAGASIN ET GM',
    A5: 'FILIALES / PERSONNEL',
    A6: 'GROSSISTES INDUSTRIELS',
    B1: 'BVP',
    B2: 'CREMIERS',
    B3: 'BOUCHER CHARCUTIER TRAITEUR',
    B4: 'AUTRES ARTISANS COMMERCANTS',
    E1: 'EXPORT A RECLASSER',
    P1: 'PARTICULIERS'
  },
  activation: {
    aucun_email: 'Aucun email',
    aucun_email_color: 'red.fail',
    non_connecte: 'Pas connecté',
    non_connecte_color: 'red.fail',
    aucun_panier: 'Pas de panier',    
    aucun_panier_color: 'orange.warning',
    aucune_commande: 'Pas de commande web',
    aucune_commande_color: 'orange.warning',
    actif: 'Actif',
    actif_color: 'green.success'
  },
  mes_informations: {
    'modification_alerte': 'Vous souhaitez faire une modification ?',
    'contacter_commercial': 'Contacter votre commercial',
    'votre_commercial': 'Votre commercial',
    'mes_adresses': 'Mes adresses',
    'adresses_de_facturation': 'Adresses de facturation',
    'adresses_de_livraison': 'Adresses de livraison',
    'adresse_de_retrait': 'Adresse de retrait',
    'mon_identite': 'Mon identité',
    'mon_entreprise': 'Mon entreprise',
    'mes_coordonnees': 'Mes coordonnées',
    'numero_de_compte': 'Numéro de compte : ',
    'siret': 'SIRET : ',
    'tva': 'TVA : ',
    'par_mail' : 'Par mail',
    'adresses_enlevement': 'Adresses d’enlèvement',
    'date_de_livraison': 'Date de livraison : ',
    'cut_off_de_preparation': 'Cut off de préparation : ',
    'les_tournees': 'Les tournées',
    'tournee_enlevement': 'Tournée d\'enlèvement n°{{numero}}',
    'tournee_livrante': 'Tournée livrante n°{{numero}}',
  },
  contact: {
    'societe_commerciale': 'Société commerciale',
    'televente': 'Télévente',
    'commercial': 'Commercial',
    'copier_email': 'Copier l\'email',
    'non_renseigne': 'Non\u00A0renseigné',
  },
  commande: {
    'nombre_commandes': '{{count}} COMMANDE',
    'nombre_commandes_one': '{{count}} COMMANDE',
    'nombre_commandes_other': '{{count}} COMMANDES',
    'nombre_commandes_many': '{{count}} COMMANDES',
    'nombre_commandes_plural': '{{count}} COMMANDES',
    'commande': 'COMMANDE',
    'commande_one': 'COMMANDE',
    'commande_other': 'COMMANDES',
    'commande_plural': 'COMMANDES',
    '2_derniers_mois': 'Les 2 derniers mois',
    '3_6_mois': 'De 3 à 6 mois',
    'en_cours': 'En cours',
    'livrees': 'Livrées',
    'livree': 'Livrée',
    'annulees': 'Annulées',
    'en_cours_de_traitement': 'En cours de traitement',
    'voir_le_detail': 'Voir le détail',
    'non_definie': 'Non définie',
    'saisie': 'Saisie',
    'annulee': 'Annulée',
    'ferme': 'Fermée',
    'mise_en_prepa': 'Mise en préparation',
    'prepa_en_cours': 'Préparation en cours',
    'prepa_terminee': 'Préparation terminée',
    'facturee': 'Facturée',
    'total': 'Total',
    'total_ttc': 'Total TTC',
    'total_ht': 'Total HT',
    'total_tva': 'Total TVA',
    'livraison_du': 'Livraison du',
    'date_de_commande': 'Date de commande',
    'passee_le': 'Commande passée le',
    'adresse_facturation': 'Adresse de facturation',
    'adresse_livraison': 'Adresse de livraison',
    'reference_de_commande': 'Référence de commande',
    'ajouter_produit': 'Pour ajouter un produit',
    'supprimer_produit_changer_quantite': 'Pour supprimer un produit, changer des quantités',
    'passer_nouvelle_commande': 'Passer une nouvelle commande',
    'contacter_conseiller': 'Contacter mon conseiller',
    'vous_etes_trompe': 'Vous vous êtes trompé ?',
    'on_vous_accompagne': 'On vous accompagne.',
    'bon_de_commande': 'Bon de commande',
    'details_de_ma_commande': 'Détails de ma commande',
    'frais_de_livraison': 'Frais de livraison',
    'n°': 'n°',
    'documents_non_disponibles': 'Documents non disponibles',
    'modale_changer_adresse_header': 'Selectionnez votre service',
    'digital': 'France Frais Connect',
    'ancien_web': 'Ancien Web',
    'televente': 'Télévente',
    'commercial': 'Commercial',
    'article': '{{count}} article',
    'article_one': '{{count}} article',
    'article_other': '{{count}} articles',
    'article_many': '{{count}} articles',
    'article_plural': '{{count}} articles',
    'remplacer_article' : 'Remplacer <strong>{{nom}}</strong>',
    'chercher_tout_catalogue' : 'Rechercher dans tout le catalogue',
    'remplacement_aucun_article': 'Désolé, aucun article de remplacement n’a été trouvé',
    'par_multiple': 'par {{quantite}} {{unite}}',
    'par_colis': 'par colis de {{quantite}} {{unite}}',
    'de_multiple_facturation': 'de {{multiple_facturation}} {{unite_facturation}}',

    'a_payer': 'À payer',
    'payee': 'Payée',
    // 'lettre': 'Lettré',
    'aucune_commande': 'Vous n\'avez aucune commande sur cette période',
    'tooltip_tva': 'Montant de la TVA',
    'produits': 'Produits',
    'statut': 'Statut',
    'statut_tooltip': 'Statut de la commande',
    'actions': 'Actions',
    'deja_commande': 'Déjà commandé :',
    'pour_livraison_du': 'Pour votre livraison du {{date}}',
    'type': 'Type de commande',
    AF: 'AVOIR',
    AV: 'AVOIR',
    CA: 'COMMANDE',
    CO: 'COMMANDE',
    FC: 'COMMANDE',
    FF: 'REGUL',
    FQ: 'REGUL',
    MD: 'MULTI DESTINATAIRE',
    PD: 'COMMANDE',
    PH: 'PREVISIONNELLE HISTORIQUE',
    PR: 'PREVISONNELLE'

  },
  factures_avoirs: {
    'facture': 'Facture',
    'avoir': 'Avoir',
    'factures_et_avoirs': '{{count}} FACTURE ET AVOIR',
    'factures_et_avoirs_one': '{{count}} FACTURE ET AVOIR',
    'factures_et_avoirs_other': '{{count}} FACTURES ET AVOIRS',
    'factures_et_avoirs_many': '{{count}} FACTURES ET AVOIRS',
    'factures_et_avoirs_plural': '{{count}} FACTURES ET AVOIRS',
    'facture_n°': 'Facture n°',
    'date_emission': 'Date d’émission',
    'echeance': 'Échéance',
    'total_ttc': 'Total TTC',
    'total_ht': 'Total HT',
    'statut': 'Statut',
    'action': 'Action',
    'telecharger': 'Télécharger',
    'telecharger_tout': 'Télécharger tout',
    'n°': 'n°',
    'numero_de_facture': 'Numéro de facture',
    'factures': 'Factures',
    'avoirs' : 'Avoirs',
    'alerte': 'Attention ! Le statut de la facture n\'est mis à jour qu\'une fois par jour.',
    'aucune_facture_ou_avoir': 'Aucune facture ou avoir disponible pour cette période.',
    '2_derniers_mois': 'Les 2 derniers mois',
    '6_derniers_mois': 'Les 6 derniers mois',
    '12_derniers_mois': 'Les 12 derniers mois',
  },
  recherche: {
    'resultat_pour_mobile': 'Les résultats pour',
    'resultat_pour_desktop' : 'Les résultats de recherche pour'
  },
  fiche_produit: {
    table:{
      'ingredient_nutrition': 'Ingrédients et nutrition',
      'informations_nutritionelles': 'Informations nutritionnelles',
      'pour100gml':'Pour 100 g/ml',
      'ajr': 'Apports journaliers recommandés (%)',
      'ingredients_manquants':'La liste des ingrédients n’a pas encore été communiquée.',
      'allergenes':'Allergènes :',
    },
    fiche_produit_pdf:{
      'telecharger_fiche_produit': 'Télécharger la fiche produit.',
      'fiche_produit_non_disponible': 'Fiche produit non disponible',
    },
  },
  select_client:{
    'greeting': 'Content de vous revoir !',
    'selectionnez_compte':'Sélectionnez un compte client pour continuer'
  },
  catalogue:{
    'chargement':'Chargement de votre catalogue',
    'aucun_produit':'Aucun produit dans ce rayon !',
    'les_promotions':'Les Promotions',
    'les_indispensables':'Les Indispensables',
    'indispensables':'Indispensables',
    'promotions':'Promotions',
    'promotion_du_moment':'Les promotions du moment',
    'voir_tout_promotion':'Voir toutes les promotions',
    'voir_tout':'Voir tout',
    'plus_de_produit': 'Plus de produits',
    'commander_maintenant': 'Commander maintenant',
    'commander_maintenant_pour': 'Commander maintenant pour',
    'produits_marque_proprietaire': 'Les indispensables',
    'chercher_produit': `Chercher un produit : {{min_lettres}} lettres minimum`,
    'fin_liste': 'Fin de votre catalogue',
    filtre: {
      'promotions': 'Promos',
      'produit_marche': 'Produits marché',
      'promo': 'Promo',
      'labels': 'Labels',
      'origine': 'Origine',
      'a-z': 'A - Z',
      'pertinence': 'Pertinence',
      'tri': 'Tri : ',
      'filtrer' : 'Filtrer',
      'trier' : 'Trier',
      'afficher' : 'Afficher',
      'tout_effacer': 'Tout effacer',
      'non_surgele_surgele': 'Non surgelé/Surgelé',
      'non_surgele': 'Non surgelé',
      'surgele': 'Surgelé',
      'effacer': 'Effacer tous les filtres',
    }
  },
  accueil:{
    'bonjour': 'Bonjour {{nom}} !',
    'bienvenue': 'Bonjour {{nom}}, bienvenue chez {{nom_societe}} !',
    'chargement_catalogue': 'Nous chargeons votre catalogue. Lors de votre première connexion, cela peut prendre 2 minutes, merci pour votre patience.',
    'chargement_tournees': 'Chargement de vos tournées de livraison',
    'compte_bloque': "Votre compte n'est pas configuré pour prendre des commandes, veuillez contacter votre représentant.",
    'mes_paniers_en_cours': 'Mes paniers en cours ({{nombre_paniers}})',
    'paniers_en_cours': 'Paniers en cours ({{nombre_paniers}})',
    'afficher_paniers': 'Afficher tous mes paniers',
    'voir_paniers': 'Voir',
    'pour_livraison': 'Pour livraison du ',
  },
  footer:{
    'conseiller_ecoute': 'Votre conseiller à votre écoute',
    'mail': 'Par mail',
    'telephone': 'Par téléphone',
    'consulter_rayons': 'Consultez nos rayons',
    'nos_rayons': 'Nos rayons',
    'a_propos': 'A propos',
    'contactez_nous': 'Contactez-nous',
    'tel': 'Tel.',
    'e_mail': 'E-mail.',
    'votre_conseiller': 'Votre conseiller :',
    'mentions_legales': 'Mentions légales',
    'engagements': 'Nos engagements',
    'politique_confidentialite': 'Politique de condifentialité',
    'gestion_cookies': 'Gestion des cookies',
    'cgv': 'CGV',
    'cgu': 'CGU',
  },
  modale_nomenclature:{
    'chargement': 'Chargement de votre menu',
  },
  modale_enlevement: {
    contacter_representant: "Afin d'organiser le retrait de vos marchandises, n'oubliez pas de contacter votre représentant"
  },
  modale_differer: {
    deplacement: 'Votre produit va être <strong>déplacé au panier du {{date_de_livraison}}</strong>'
  },
  preferences: {
    alertes: 'Mes alertes commandes',
    activer: 'Tout activer',
    email: 'Email',
    mobile: 'App mobile',
    livraison: 'Rappel de prochaine livraison (la veille)',
    livraison_mobile: 'Rappel de prochaine livraison<br />(la veille)',
    paniers: 'Rappel de paniers à valider'
  },
  session: {
    'session_bientot_expiree': 'Session bientôt expirée',
    'prolonger_session': 'Prolonger la session',
    'prolongation': 'Prolongation ...',
    'delai_expiration': 'Votre session expirera dans {{minutes}} minutes.',
  },
  form: {
    societe_commerciale: {
      titleAjouter: 'Ajouter une société commerciale',
      titleModifier: 'Modifier une société commerciale',
      dropzone: 'Déposez votre fichier ou cliquez sur la zone',
      ajouter: 'Ajouter',
      modifier: 'Modifier',
      ajoutee: 'La société commerciale a été enregistrée.',
      modifiee: 'La société commerciale a été modifiée.',
      supprimer: 'Supprimer',
      ajouter_lien: 'Ajouter un lien',
      annuler: 'Annuler',
      valider: 'Valider',

      general: 'Informations générales',
      id_base_label: 'ID Base',
      id_base_placeholder: "Sélectionnez l'ID base",
      code_societe_commerciale_label: 'Code société commerciale',
      code_societe_commerciale_placeholder: 'Sélectionnez le code de la société',
      nom_commercial_label: 'Nom commercial',
      nom_commercial_placeholder: 'Nom commercial de la société',

      ffconnect: 'Informations FFConnect',
      permettre_commande_retrait_label: 'Permettre le retrait de commande',
      message_validation_livraison_label: 'Message validation livraison',
      message_validation_livraison_placeholder: "Message à la validation d'une livraison",
      message_validation_enlevement_label: 'Message validation enlèvement',
      message_validation_enlevement_placeholder: "Message à la validation d'un retrait au dépôt",

      contact: 'Informations contact',
      adresse_label: 'Adresse',
      adresse_placeholder: 'Adresse de la société',
      telephone_label: 'Téléphone',
      telephone_placeholder: 'Téléphone de la société',
      email_label: 'Email',
      email_placeholder: 'Email de la société',

      visuel: 'Informations visuelles',
      couleur_label: 'Couleur principale de la société',
      logo_label: 'Logo de la société (jpg ou png)',
      cgv_label: 'CGV de la société (pdf)',
      cgu_label: 'CGU de la société (pdf)',

      footer: 'Informations footer',
      url_lien: 'URL pour le lien',
      label_lien: 'Libellé du lien',
    },
  },
  assistance: {
    'banniere': 'Vous agissez sur le compte {{raison_sociale}}'
  },
  kpis: {
    voir_les: 'Voir les',
    erreur: 'Erreur lors du chargement des KPIs',
    total_clients: 'Total clients',
    contacts_connectes: 'Clients connectés',
    contacts_connectes_tooltip: 'Clients qui se sont connectés à FFConnect au cours des 30 derniers jours.',
    non_connecte: 'pas connectés',
    non_connecte_tooltip: 'Clients qui ne se sont pas connectés sur FFConnect au cours des 30 derniers jours.',
    avec_panier: 'Qui ont fait un panier',
    avec_panier_tooltip: 'Clients qui ont fait un panier non validé au cours des 30 derniers jours.',
    sans_panier: 'pas de panier',
    sans_panier_tooltip: "Clients qui n'ont pas fait de panier sur FFConnect au cours des 30 derniers jours.",
    panier_valide: 'Qui ont validé un panier',
    panier_valide_tooltip: 'Clients qui ont passé une commande au cours des 30 derniers jours.',
    sans_commande: 'pas de commande web',
    sans_commande_tooltip: "Clients qui n'ont pas commandé sur FFConnect au cours des 30 derniers jours.",
    tendance_tooltip: "Tendance des 30 derniers jours par rapport aux 30 jours d'avant."
  }
};
