import {UserIcon} from '@/components/svg/UserIcon';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {Box, Button, MenuButton, Text} from '@chakra-ui/react';

export const ButtonUser:React.FC<{nom:string}> = ({nom}) => {
  return (
    <MenuButton as={Button}
      rightIcon={<ChevronDownIcon />}
      variant='unstyle'
      backgroundColor='transparent'
      color='white'
      _hover={{bg:'transparent'}}
      fontWeight='500'
    >
      <Box display="flex"
        alignItems="center">
        <Box marginRight='5px'>
          <UserIcon />
        </Box>
        <Text fontSize='sm'>{nom}</Text>
      </Box>
    </MenuButton>
  );
};
